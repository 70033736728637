import React, { useState } from "react";
import { Row, Badge, Space, Table, Dropdown, Card, Col, Modal, Typography, List, Image } from "antd";
import { Container, Icon, Text, Button, WidthColumn } from "./styles";
import { MdArchive, FcMinus, FaCheck, FiMoreVertical } from "react-icons/all";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  ResearchObjects,
} from "../../redux/actions";
import firebase from "firebase";

const RENDER_FOLLOWUP_TYPE = {
  MORNING: "MORNING",
  AFTERNOON: "AFTERNOON",
  EVENING: "EVENING",
  DAY: "DAY",
};

const { Link } = Typography;
const getNameFromUrl = (url) => {
  try {
    return firebase.storage().refFromURL(url).name;
  } catch (e) {
    return url;
  }
};
export default function TableParticipant({
  t,
  type,
  data,
  setData,
  loading,
  history,
  userPerm,
  rowKey = (item) => item.id,
}) {

  const researchObject = useSelector((state) => state.researchObject);
  const [filterData, setFilterData] = useState({});
  const [sortData, setSortData] = useState({
    order: "descend",
    field: "filtering",
  });
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [visibleInitialSymptom, setVisibleInitialSymptom] = useState(false);
  const onFilterChangeHandler = (pagination, filters, sorter) => {
    setSortData(sorter);
    setPagination(pagination);
  };

  const checkFollowUpFormRender = (obj, type) => {
    const currentDate =
      moment().diff(moment(obj.filtering?.createdAt).startOf("day"), "days") +
      1;
    const followUpForm = Array.isArray(obj.followUpForm)
      ? obj.followUpForm
      : [];
    if (obj.status === "active") {
      let submittedItem = [];
      switch (type) {
        case "MORNING":
        case "AFTERNOON":
        case "EVENING":
          submittedItem = followUpForm.filter(
            (item) =>
              +item.day === currentDate &&
              item.period === type &&
              item.submitted
          );
          return !!submittedItem.length;
        case "DAY":
          submittedItem = followUpForm.filter(
            (item) => +item.day === currentDate
          );
          return !!submittedItem.length ? `${t("day")} ${currentDate}` : "-";
      }
    } else {
      return type === "DAY" ? "-" : false;
    }
  };

  const COLUMNS_OR = [
    {
      title: t("registered_date"),
      dataIndex: "filtering",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.filtering?.createdAt).unix() -
        moment(b.filtering?.createdAt).unix(),
      sortOrder: sortData.field === "filtering" ? sortData.order : null,
      render: (data) => (
        <Space style={{ minWidth: 100 }} direction="vertical" align="center">
          <Text>{moment(data?.createdAt).format("DD/MM/YYYY")}</Text>
          <Text type="secondary">
            {moment(data?.createdAt).format("hh:mm A")}
          </Text>
        </Space>
      ),
    },
    {
      title: t("subject_id"),
      dataIndex: "filtering",
      align: "center",
      render: (data) => (
        <WidthColumn minWidth={100}>{data?.subjectId}</WidthColumn>
      ),
    },
    {
      title: t("eligibility"),
      align: "center",
      dataIndex: "status",
      shouldCellUpdate: () => false,
      render: (status) => (
        <Badge
          count={status}
          style={{
            borderColor:
              status === "active"
                ? "#52c41a"
                : status === "withdrawn"
                  ? "#FF9800"
                  : status === "completed"
                    ? "#FFF"
                    : "#000000",
            textTransform: "capitalize",
            backgroundColor:
              status === "active"
                ? "#52c41a"
                : status === "completed"
                  ? "#FF9800"
                  : status === "withdrawn"
                    ? "#FFF5E5"
                    : "#F9FAFA",
            color:
              status === "active" || status === "completed"
                ? "#ffffff"
                : status === "withdrawn"
                  ? "#FF9800"
                  : "#000000",
          }}
        />
      ),
    },
    {
      title: t("gender"),
      dataIndex: "filtering",
      align: "center",
      width: "100px",
      render: (data) => (
        <WidthColumn minWidth={100}>{t(data?.gender)}</WidthColumn>
      ),
    },
    {
      title: t("patient_group"),
      dataIndex: "filtering",
      align: "center",
      render: (data) => (
        < WidthColumn minWidth={100} > {t(data?.participantGroup)}</WidthColumn>
      )
      ,
    },
    {
      title: t("status_of_medical_data"),
      dataIndex: "filtering",
      align: "center",
      render: (data) => <WidthColumn minWidth={150}>-</WidthColumn>,
    },
    {
      title: "",
      shouldCellUpdate: () => false,
      render: (record) => {
        return (
          <Row justify="center">
            <Button
              className="mr-2 mb-2"
              shape="round"
              onClick={() =>
                history.push(`/clinic-trial/research-object/${record.id}`)

              }
            >
              View
            </Button>
            <Button shape="round" onClick={() => { }}>
              Send
            </Button>
          </Row>
        );
      },
    },
  ];

  const COLUMNS_NCI = [
    {
      title: t("registered_date"),
      dataIndex: "filtering",
      width: 140,
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.filtering?.createdAt).unix() -
        moment(b.filtering?.createdAt).unix(),
      sortOrder: sortData.field === "filtering" ? sortData.order : null,
      render: (data) => (
        <Space direction="vertical" align="center">
          <Text>{moment(data?.createdAt).format("DD/MM/YYYY")}</Text>
          <Text type="secondary">
            {moment(data?.createdAt).format("hh:mm A")}
          </Text>
        </Space>
      ),
    },
    {
      title: t("subject_id"),
      dataIndex: "filtering",
      align: "center",
      render: (data) => (
        <WidthColumn minWidth={100}>{data?.subjectId}</WidthColumn>
      ),
    },
    {
      title: t("eligibility"),
      align: "center",
      dataIndex: "status",
      shouldCellUpdate: () => false,
      render: (status) => (
        <Badge
          count={status}
          style={{
            borderColor:
              status === "active"
                ? "#52c41a"
                : status === "withdrawn"
                  ? "#FF9800"
                  : status === "completed"
                    ? "#FFF"
                    : "#000000",
            textTransform: "capitalize",
            backgroundColor:
              status === "active"
                ? "#52c41a"
                : status === "completed"
                  ? "#FF9800"
                  : status === "withdrawn"
                    ? "#FFF5E5"
                    : "#F9FAFA",
            color:
              status === "active" || status === "completed"
                ? "#ffffff"
                : status === "withdrawn"
                  ? "#FF9800"
                  : "#000000",
          }}
        />
      ),
    },

    {
      title: t("gender"),
      dataIndex: "filtering",
      align: "center",
      width: "100px",
      render: (data) => (

        <WidthColumn minWidth={100}>{t(data?.gender)}</WidthColumn>
      ),
    },
    {
      title: t("patient_group"),
      dataIndex: "inExclusionCriteria",
      align: "center",
      width: "100px",
      render: (data) => (
        <WidthColumn minWidth={100}>{t(data.participantGroup)}</WidthColumn>
      ),
    },
    // {
    //   title: t("product_id"),
    //   dataIndex: "filtering",
    //   align: "center",
    //   render: (data) => (
    //     <WidthColumn minWidth={150}>{data?.productId}</WidthColumn>
    //   ),
    // },
    // {
    //   title: t("nasal_swap"),
    //   dataIndex: "filtering",
    //   align: "center",
    //   render: (data) => (
    //     <WidthColumn minWidth={120}>{data?.nasalSwab}</WidthColumn>
    //   ),
    // },
    // {
    //   title: t("follow_up_status"),
    //   children: [
    //     {
    //       title: t("morning"),
    //       key: "morning",
    //       shouldCellUpdate: () => false,
    //       width: 60,
    //       align: "center",
    //       render: (record) => {
    //         const morning = checkFollowUpFormRender(
    //           record,
    //           RENDER_FOLLOWUP_TYPE.MORNING
    //         );
    //         return morning ? (
    //           <Icon component={FaCheck} color="#27A06D" />
    //         ) : (
    //           <Icon component={FcMinus} />
    //         );
    //       },
    //     },
    //     {
    //       title: t("afternoon"),
    //       key: "afternoon",
    //       shouldCellUpdate: () => false,
    //       width: 90,
    //       align: "center",
    //       render: (record) => {
    //         const afternoon = checkFollowUpFormRender(
    //           record,
    //           RENDER_FOLLOWUP_TYPE.AFTERNOON
    //         );
    //         return afternoon ? (
    //           <Icon component={FaCheck} color="#27A06D" />
    //         ) : (
    //           <Icon component={FcMinus} />
    //         );
    //       },
    //     },
    //     {
    //       title: t("evening"),
    //       key: "evening",
    //       shouldCellUpdate: () => false,
    //       width: 60,
    //       align: "center",
    //       render: (record) => {
    //         const evening = checkFollowUpFormRender(
    //           record,
    //           RENDER_FOLLOWUP_TYPE.EVENING
    //         );
    //         return evening ? (
    //           <Icon component={FaCheck} color="#27A06D" />
    //         ) : (
    //           <Icon component={FcMinus} />
    //         );
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: t("status"),
    //   align: "center",
    //   shouldCellUpdate: () => false,
    //   render: (record) => {
    //     const day = checkFollowUpFormRender(record, RENDER_FOLLOWUP_TYPE.DAY);
    //     return day;
    //   },
    // },
    {
      title: "",
      width: 160,
      align: "center",
      shouldCellUpdate: () => false,
      render: (record) => {
        const [visible, setVisible] = useState(false);
        return (
          <Row gutter={[16, 16]} align="middle" className="mx-2">
            <Button
              shape="round"
              onClick={() => {
                // history.push(`/clinic-trial/research-object/${record.id}`)
                dispatch(ResearchObjects.getResearchObjFromRecruitmentObj(record.id))
                setVisibleInitialSymptom(true)
              }
              }
            >

              View
            </Button>

            {
              userPerm.STUDY_AND_WITHDRAWN && record.status === "active" && (
                <Dropdown
                  trigger={["click"]}
                  visible={visible}
                  onVisibleChange={setVisible}
                  overlay={
                    <Card>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <Button
                          type="text"
                          onClick={(e) => {
                            e.preventDefault();
                            setVisible(false);
                            setSelectedItem(record);
                            setWithdrawnModal(true);
                          }}
                        >
                          <Row align="middle" className="pr-5">
                            <Icon
                              onClick={(e) => {
                                e.preventDefault();
                                setVisible(true);
                              }}
                              component={MdArchive}
                              size="24px"
                              color="#ADADAD"
                            />
                            <Text className="ml-2">{t("withdrawn")}</Text>
                          </Row>
                        </Button>
                      </div>
                    </Card>
                  }
                  placement="bottomCenter"
                  arrow
                >
                  <Icon component={FiMoreVertical} className="ml-3" />
                </Dropdown>
              )
            }

          </Row >
        );
      },
    },
  ];

  const RenderTable = () => {
    // * NOTE role PI can manage STUDY column
    if (userPerm.STUDY_AND_WITHDRAWN) {
      COLUMNS_NCI.splice(5, 0, {
        title: t("study"),
        dataIndex: "filtering",
        align: "center",
        render: (data) => {
          return (
            data?.study && (
              <Badge
                count={data?.study}
                style={{
                  backgroundColor:
                    data?.study === "Treatment" ? "#E9F4FE" : "#E9F5F0",
                  color: data?.study === "Treatment" ? "#64B5F7" : "#4CAF50",
                  borderColor:
                    data?.study === "Treatment" ? "#64B5F7" : "#4CAF50",
                }}
              />
            )
          );
        },
      });
    }
    return (
      <Table
        rowKey={rowKey}
        columns={COLUMNS_NCI}
        loading={loading}
        dataSource={data}
        onChange={onFilterChangeHandler}
        size="middle"
        scroll={{ x: true }}
        pagination={pagination}
      />
    );
  };


  return (
    <Container>
      <RenderTable />
      <Modal
        title={t("initial_symptom")}
        visible={visibleInitialSymptom}
        footer={null}
        width={450}
        onOk={() => setVisibleInitialSymptom(false)}
        onCancel={() => setVisibleInitialSymptom(false)}
      >
        {researchObject?.data?.consentForm?.fileUrls?.map((item) => (
          <>
            <Image
              height={200}
              src={item}
            />
            {/* <Link href={item} target="_blank">
              {`\u2022 ${getNameFromUrl(item)}`}
            </Link> */}
          </>
        ))}
      </Modal>
    </Container>
  );
}
