import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { range, findIndex } from "lodash";
import moment from "moment";
import EligibilityAssessment from "../../ClinicTrialParticipantRegister/widgets/EligibilityAssessment";

const wrapTwoDecimal = (value) => {
  return +(Math.floor(value * 100) / 100).toFixed(2);
};

const EligibilityAssessmentModal = ({
  t,
  customRequest,
  getNameFromUrl,
  clinicTrial,
  isEligibilityModalVisible,
  setIsEligibilityModalVisible,
  addNewAssessmentByIdHandler,
  researchObjectData,
}) => {
  const [dateList, setDateList] = useState([]);
  useEffect(() => {
    if (clinicTrial.listAssessment.isSuccess) {
      const dateList = range(1, 15).flatMap((item) => {
        if (
          findIndex(clinicTrial.listAssessment.data, (o) => {
            return o.day === item;
          }) !== -1
        ) {
          return [];
        }
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      setDateList(dateList);
    }
  }, [isEligibilityModalVisible]);

  const eligibilityAssessmentForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      day: "",
      height: "",
      weight: "",
      bmi: 0,
      bloodPressure: 0,
      bloodPressureRange: "",
      heartRate: 0,
      rr: 0,
      pulseOx: 0,
      medicationHistories: [
        {
          medicationName: "",
          date: "",
        },
      ],
      underlingDiseases: ["", ""],
      currentMedications: [""],
      vaccinneHistory: [
        {
          vaccineName: "",
          date: "",
          site: "",
        },
      ],
      covidInfectionHistory: {
        closeContact: "",
        closeContactComment: "",
        closeContactDate: null,
        covidInfectionHistoryUrl: "",
        covidInfected: "",
        covidInfectedComment: "",
        covidInfectedDate: null,
      },
      clinicalTrialCheck: "",
      clinicalTrialStartDate: moment().toISOString(),
      clinicalTrialEndDate: "",
      clinicalTrialDescription: "",
      physicalExamination: "",
      nasopharyngealStatus: "not_detected",
      nasopharyngealDateOfSampleCollection: "",
      nasopharyngealFileUrl: "",
      sinuscopyPolypsRight: 0,
      sinuscopyOedemaRight: 0,
      sinuscopyDischargeRight: 0,
      sinuscopyDischargeImageUrlRight: "",
      sinuscopyPolypsLeft: 0,
      sinuscopyOedemaLeft: 0,
      sinuscopyDischargeLeft: 0,
      sinuscopyDischargeImageUrlLeft: "",
      cPassWuhanBefore: 0,
      cPassWuhanAfter: 0,
      cPassOmicronBefore: 0,
      cPassOmicronAfter: 0,
      cPassDeltaBefore: 0,
      cPassDeltaAfter: 0,
    },
    validationSchema: Yup.object().shape({
      day: Yup.number().required("Please select date"),
    }),
    onSubmit: async (values) => {
      addNewAssessmentByIdHandler(researchObjectData.id, {
        ...values,
        covidInfectionHistory: {
          vaccinneHistory: values.vaccinneHistory,
          ...values.covidInfectionHistory,
        },
        nasopharyngealDateOfSampleCollection:
          values.nasopharyngealDateOfSampleCollection || null,
        clinicalTrialEndDate: values.clinicalTrialEndDate || null,
        cPassWuhanBefore: wrapTwoDecimal(values.cPassWuhanBefore),
        cPassWuhanAfter: wrapTwoDecimal(values.cPassWuhanAfter),
        cPassOmicronBefore: wrapTwoDecimal(values.cPassOmicronBefore),
        cPassOmicronAfter: wrapTwoDecimal(values.cPassOmicronAfter),
        cPassDeltaBefore: wrapTwoDecimal(values.cPassDeltaBefore),
        cPassDeltaAfter: wrapTwoDecimal(values.cPassDeltaAfter),
      });
    },
  });

  return (
    <Modal
      visible={isEligibilityModalVisible}
      width={"80vw"}
      onCancel={() => {
        setIsEligibilityModalVisible(false);
      }}
      okText="Submit"
      onOk={() => eligibilityAssessmentForm.handleSubmit()}
      confirmLoading={clinicTrial.listAssessment.loading}
    >
      <Spin spinning={clinicTrial.listAssessment.loading}>
        <EligibilityAssessment
          t={t}
          customRequest={customRequest}
          getNameFromUrl={getNameFromUrl}
          currentRecord={{
            researchId: researchObjectData?.id,
            ...researchObjectData,
          }}
          dateList={dateList}
          eligibilityAssessmentForm={eligibilityAssessmentForm}
          daySelection={true}
        />
      </Spin>
    </Modal>
  );
};
export default EligibilityAssessmentModal;
