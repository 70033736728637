import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/ClinicPackages";

async function getClinicPackagesByFilter(filter) {
  const filterString = JSON.stringify(filter);
  const encode = encodeURIComponent(filterString);
  const { data } = await http.get(`${apiEndpoint}?filter=${encode}`);
  return data;
}

async function getClinicPackageById(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}`);
  return data;
}

export { getClinicPackagesByFilter, getClinicPackageById };
