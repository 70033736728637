export const NOTIFICATION = (orgName) => {
  return {
    approve: (language = "th") => ({
      body:
        language === "en"
          ? `Congratulations! Your account has been approved by ${orgName}`
          : `ยินดีด้วย! บัญชีผู้ใช้ท่านได้รับการอนุมัติการใช้งานจาก ${orgName} แล้ว`,
      title: language === "en" ? "Alert" : "แจ้งเตือน",
      icon: "ic_notification",
      color: "#f1c40f",
      sound: "default",
    }),
    reject: (language = "th") => ({
      body:
        language === "en"
          ? `Sorry, your account has been rejected by ${orgName}. Please check your information before re-applying again.`
          : `ต้องขออภัย บัญชีของท่านถูกปฏิเสธจาก ${orgName} โปรดตรวจสอบข้อมูลในการสมัครอีกครั้ง`,
      title: language === "en" ? "Alert" : "แจ้งเตือน",
      icon: "ic_notification",
      color: "#f1c40f",
      sound: "default",
    }),
  };
};

export const NOTIFICATION_DATA = {
  approve: {
    service: true,
    page: "Profile",
  },
  reject: {
    service: false,
    page: "Profile",
  },
};

export const STATUS = {
  pending: { title: "pending_approval", color: "red" },
  rejected: { title: "rejected", color: "red" },
  approved: { title: "approved", color: "green" },
};
