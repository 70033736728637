import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Clinics";

async function getClinicListBySpecialty(filter) {
  const filterString = JSON.stringify(filter);
  const encode = encodeURIComponent(filterString);
  const { data } = await http.get(
    `${apiEndpoint}/filterwhere?filter=${encode}&includes%5B%5D=Procedures&includes%5B%5D=ClinicReviews`
  );
  return data;
}

async function getClinicById(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}`);
  return data;
}

export { getClinicListBySpecialty, getClinicById };
