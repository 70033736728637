import React from "react";
import { Row, Col, Typography, Steps } from "antd";
import { SUMMARY_STEPS } from "./constant";
import {
  StepsContainer,
  SummarySteps,
  StepsCard,
  SummaryContainer,
  SummaryContent,
  CardStyled,
  ButtonStyled,
  CardMap,
} from "./styles";
import GoogleMap from "../../containers/GoogleMap";
import SummaryNoteCard from "../Card/SummaryNote";
import SummaryPatientDetailCard from "../Card/SummaryPatientDetail";
import HistoryPatientDetailModal from "../Modal/HistoryPatientDetail";
import SummaryNoteModal from "../Modal/SummaryNote";
import PrescriptionSummary from "../PrescriptionSummary";
import { CloseCircleFilled } from "@ant-design/icons";
import DoctorReferral from "../Card/DoctorReferral";
import DoctorReferralModal from "../Modal/DoctorReferralModal";

const { Title } = Typography;
const { Step } = Steps;

const Summary = (props) => {
  const {
    t,
    roomType,
    booking,
    userOma,
    color,
    setColor,
    note,
    setNote,
    role,
    organization,
    drugUnits,
    prescription,
    drugTimeEvent,
    drugTimeFrequency,
    handleSelectMedicine,
    medicines,
    setMedicines,
    updatePrescriptionItem,
    visibleHistoryPatientDetailModal,
    setVisibleHistoryPatientDetailModal,
    visibleSummaryNoteModal,
    setVisibleSummaryNoteModal,
    visibleDoctorReferralModal,
    setVisibleDoctorReferralModal,
    handleUpdateBooking,
    handleRedirectToCommunityPharmacist,
    handleRedirectToDeliverDrug,
    handleRedirectToComplete,
    handleRedirectToDecline,
    visiblePrescriptionList,
    setVisiblePrescriptionList,
    deleteDrugFromPrescription,
    getDrugTimeFrequencyInfo,
    handleRedirectToDashboard,
    getDrugTimeEventInfo,
    getDrugRouteInfo,
    getDrugUnitInfo,
    symptomGroups,
    onSearch,
    medication,
    doctorReferralTypeList,
    doctorReferralType,
    practitionerSpecialties,
    practitioners,
    selectDoctorReferTypeHandler,
    selectDoctorReferSpecialtyHandler,
    doctorReferType,
    doctorReferSpecialty,
    clinic,
    createDoctorReferral,
    clinicPackages,
    doctorReferral,
    setDoctorReferSpecialtyId,
    deleteDoctorReferral,
  } = props;
  return (
    <SummaryContainer>
      <SummaryContent>
        <Row
          gutter={[24, 12]}
          justify="center"
          align="middle"
          className="p-5 pb-0"
        >
          <Col span={12} className="d-flex align-items-center">
            <Row>
              {role === "communityPharmacist" && (
                <Col className="mt-1 mr-1">
                  <CloseCircleFilled
                    style={{ cursor: "pointer" }}
                    onClick={handleRedirectToDashboard}
                  />
                </Col>
              )}
              <Col>
                <Title className="mb-0" level={3}>
                  {t("summary_note")}
                </Title>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <StepsCard>
              <StepsContainer>
                <SummarySteps
                  size="small"
                  current={2}
                  className="d-flex align-items-center mr-4"
                >
                  {SUMMARY_STEPS.map((step, index) => (
                    <Step
                      key={index}
                      icon={step.icon}
                      title={t(`${step.title}`)}
                    />
                  ))}
                </SummarySteps>
              </StepsContainer>
            </StepsCard>
          </Col>
          <Col span={24}>
            <Row type="flex" gutter={[24, 24]} justify="space-between">
              <Col xs={12} sm={12} xl={8} style={{ maxWidth: "435px" }}>
                <SummaryPatientDetailCard
                  t={t}
                  patient={booking.current.patient}
                  patientId={booking.current.patientId}
                  userId={booking.current.practitionerAppUserId}
                  setVisibleHistoryPatientDetailModal={
                    setVisibleHistoryPatientDetailModal
                  }
                />
              </Col>
              <Col xs={12} sm={12} xl={8} style={{ maxWidth: "435px" }}>
                <Row className="h-100">
                  <Col span={24}>
                    <SummaryNoteCard
                      t={t}
                      booking={booking}
                      setVisibleSummaryNoteModal={setVisibleSummaryNoteModal}
                    />
                  </Col>
                  {role === "communityPharmacist" && (
                    <Col span={24}>
                      <CardMap
                        title={t("map")}
                        extra={
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.google.com/maps/search/?api=1&query=${booking.current.locationAddress.latitude},${booking.current.locationAddress.longitude}`}
                          >
                            {t("link")}
                          </a>
                        }
                      >
                        <GoogleMap
                          bookings={[{ ...booking.current }]}
                          height="200px"
                          zoom={14}
                          radius="25px"
                          isShowUI
                        />
                      </CardMap>
                    </Col>
                  )}

                  {role === "doctor" && (
                    <Col span={24}>
                      <DoctorReferral
                        t={t}
                        doctorReferSpecialty={doctorReferSpecialty}
                        deleteDoctorReferral={deleteDoctorReferral}
                        practitionerSpecialties={practitionerSpecialties}
                        doctorReferralType={doctorReferralType}
                        doctorReferral={doctorReferral}
                        setVisibleDoctorReferralModal={
                          setVisibleDoctorReferralModal
                        }
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={12} sm={12} xl={8} style={{ maxWidth: "435px" }}>
                {["doctor", "communityPharmacist"].includes(role) &&
                  booking.current.bookingCategory !== "scheduling" &&
                  organization.location !== "CCS" && (
                    <PrescriptionSummary
                      t={t}
                      booking={booking}
                      symptomGroups={symptomGroups}
                      medicines={medicines}
                      setMedicines={setMedicines}
                      role={role}
                      prescription={prescription}
                      updatePrescriptionItem={updatePrescriptionItem}
                      getDrugRouteInfo={getDrugRouteInfo}
                      getDrugUnitInfo={getDrugUnitInfo}
                      drugUnits={drugUnits}
                      onSearch={onSearch}
                      medication={medication}
                      drugTimeEvent={drugTimeEvent}
                      drugTimeFrequency={drugTimeFrequency}
                      handleSelectMedicine={handleSelectMedicine}
                      visiblePrescriptionList={visiblePrescriptionList}
                      setVisiblePrescriptionList={setVisiblePrescriptionList}
                      deleteDrugFromPrescription={deleteDrugFromPrescription}
                      getDrugTimeFrequencyInfo={getDrugTimeFrequencyInfo}
                      getDrugTimeEventInfo={getDrugTimeEventInfo}
                    />
                  )}

                {["doctor"].includes(role) ? (
                  <CardStyled title={t("action")}>
                    <h1>{t("please_recheck_all_details_before_complete")}</h1>
                    <div>
                      {t("if_there_is_a_problem_with_the_consultation")}
                    </div>
                    <div className="text-right">
                      <ButtonStyled
                        type="danger"
                        onClick={handleRedirectToDecline}
                      >
                        {t("cancel")}
                      </ButtonStyled>
                      <ButtonStyled
                        type="primary"
                        onClick={handleRedirectToComplete}
                      >
                        {t("complete")}
                      </ButtonStyled>
                    </div>
                  </CardStyled>
                ) : ["communityPharmacist", "callCenter"].includes(role) ? (
                  <CardStyled title="Delivery">
                    <h1>{t("please_recheck_all_details_before_deliver")}</h1>
                    <div>{t("full_heath_record")}</div>
                    <div style={{ textAlign: "right" }}>
                      <ButtonStyled
                        type="danger"
                        style={{ marginRight: 20 }}
                        onClick={handleRedirectToDecline}
                      >
                        {t("cancel")}
                      </ButtonStyled>
                      <ButtonStyled
                        type="primary"
                        onClick={handleRedirectToDeliverDrug}
                      >
                        {t("go_to_deliver")}
                      </ButtonStyled>
                    </div>
                  </CardStyled>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <HistoryPatientDetailModal
          visible={visibleHistoryPatientDetailModal}
          setVisible={setVisibleHistoryPatientDetailModal}
          patient={userOma}
          userId={booking.current.practitionerAppUserId}
        />
        <SummaryNoteModal
          t={t}
          visible={visibleSummaryNoteModal}
          setVisible={setVisibleSummaryNoteModal}
          note={note}
          setNote={setNote}
          color={color}
          setColor={setColor}
          roomType={roomType}
          handleUpdateBooking={handleUpdateBooking}
        />
        <DoctorReferralModal
          t={t}
          patient={userOma}
          practitioners={practitioners}
          doctorReferralTypeList={doctorReferralTypeList}
          practitionerSpecialties={practitionerSpecialties}
          selectDoctorReferTypeHandler={selectDoctorReferTypeHandler}
          selectDoctorReferSpecialtyHandler={selectDoctorReferSpecialtyHandler}
          visible={visibleDoctorReferralModal}
          setVisible={setVisibleDoctorReferralModal}
          doctorReferType={doctorReferType}
          doctorReferSpecialty={doctorReferSpecialty}
          clinic={clinic}
          setDoctorReferSpecialtyId={setDoctorReferSpecialtyId}
          clinicPackages={clinicPackages}
          doctorReferralType={doctorReferralType}
          createDoctorReferral={createDoctorReferral}
          doctorReferral={doctorReferral}
        />
      </SummaryContent>
    </SummaryContainer>
  );
};
export default Summary;
