import { call, put, takeEvery } from "redux-saga/effects";
import { Payment } from "../../api";
import * as type from "../types";

function* confirmPayment(request) {
  try {
    yield call(Payment.confirmPayment, request);
    yield put({ type: type.CONFIRM_PAYMENT_SUCCESS });
  } catch (e) {
    yield put({ type: type.CONFIRM_PAYMENT_FAILED, error: e.message });
  }
}
function* rejectPayment(request) {
  try {
    yield call(Payment.rejectPayment, request);
    yield put({ type: type.REJECT_PAYMENT_SUCCESS });
  } catch (e) {
    yield put({ type: type.REJECT_PAYMENT_FAILED, error: e.message });
  }
}

function* paymentSaga() {
  yield takeEvery(type.CONFIRM_PAYMENT_REQUESTED, confirmPayment);
  yield takeEvery(type.REJECT_PAYMENT_REQUESTED, rejectPayment);
}

export default paymentSaga;
