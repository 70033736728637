import { all } from "redux-saga/effects";
import appUserOmaSaga from "./appUserOmaSaga";
import availableTimeSaga from "./availableTimeSaga";
import bookingSaga from "./bookingSaga";
import communicationSaga from "./communicationSaga";
import firebaseSaga from "./firebaseSaga";
import kycImageSaga from "./kycImageSaga";
import menuSaga from "./menuSaga";
import organizationSaga from "./organizationSaga";
import userFcmTokenSaga from "./userFcmTokenSaga";
import userOmaSaga from "./userOmaSaga";
import treatmentSaga from "./treatmentSaga";
import monitoringReportSaga from "./monitoringReportSaga";
import medicationSaga from "./medicationSaga";
import practitionerSpecialtiesSaga from "./practitionerSpecialtiesSaga";
import roleSaga from "./roleSaga";
import drugTimeEventSaga from "./drugTimeEventSaga";
import drugTimeFrequencySaga from "./drugTimeFrequencySaga";
import symptomGroupSaga from "./symptomGroupSaga";
import questionairesSaga from "./questionairesSaga";
import drugIngredientGroupSaga from "./drugIngredientGroupSaga";
import medicationForOrganizationSaga from "./medicationForOrganizationSaga";
import clinicTrialSaga from "./clinicTrialSaga";
import researchObjectSaga from "./researchObjectSaga";
import recruitmentObjectSaga from "./recruitmentObjectSaga";
import drugUnitSaga from "./drugUnitSaga";
import paymentSaga from "./paymentSaga";
import practitionerSaga from "./practitionerSaga";
import clinicSaga from "./clinicSaga";
import clinicPackageSaga from "./clinicPackageSaga";
import doctorReferralSaga from "./doctorReferralSaga";

export function* rootSaga() {
  yield all([
    treatmentSaga(),
    appUserOmaSaga(),
    userFcmTokenSaga(),
    organizationSaga(),
    kycImageSaga(),
    userOmaSaga(),
    bookingSaga(),
    menuSaga(),
    firebaseSaga(),
    communicationSaga(),
    availableTimeSaga(),
    monitoringReportSaga(),
    medicationSaga(),
    practitionerSpecialtiesSaga(),
    roleSaga(),
    drugTimeEventSaga(),
    drugTimeFrequencySaga(),
    symptomGroupSaga(),
    questionairesSaga(),
    drugIngredientGroupSaga(),
    medicationForOrganizationSaga(),
    clinicTrialSaga(),
    researchObjectSaga(),
    recruitmentObjectSaga(),
    drugUnitSaga(),
    paymentSaga(),
    practitionerSaga(),
    clinicSaga(),
    clinicPackageSaga(),
    doctorReferralSaga(),
  ]);
}
