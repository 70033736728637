import React from "react";
import EverLogo from "./ever-logo.png";
import { BiArrowBack } from "react-icons/bi";

export default function EverHeader({ history, canGoBack }) {
  const goback = () => history.goBack();
  return (
    <div
      style={{
        height: 70,
        background: "white",
        flexDirection: "row",
        display: "flex",
        paddingLeft: canGoBack ? 0 : 50,
        paddingRight: 50,
        alignItems: "center",
        paddingTop: 10,
      }}
    >
      {canGoBack && (
        <div style={{ width: 50 }}>
          <BiArrowBack
            className="ml-3"
            color="#888888"
            onClick={goback}
            size={20}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      <img src={EverLogo} style={{ height: 50, width: 50 }} />
      <div style={{ fontWeight: "bold", fontSize: 26, paddingLeft: 8 }}>
        Ever{" "}
      </div>{" "}
      <div style={{ fontSize: 26, paddingLeft: 5 }}>Partner</div>
    </div>
  );
}
