import { Container, Text, VitalContainer, InputBoderBottom } from "../styles";
import { Row, Col, Space, Descriptions } from "antd";

const Labresult = ({ t, form, disabled }) => {
  const { handleChange = () => {}, values } = form;

  return (
    <Container>
      <Row gutter={[8, 8]}>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("diopter_value")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.diopter || t("nothing")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.diopter || ""}
                onChange={handleChange("diopter")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("intraocular_pressure")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.intraocularPressure || t("nothing")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.intraocularPressure || ""}
                onChange={handleChange("intraocularPressure")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("egfr_value")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.eGFR || t("nothing")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.eGFR || ""}
                onChange={handleChange("eGFR")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
        <Col>
          <VitalContainer>
            <Text size="12px" bold>
              {t("albumin")}
            </Text>
            {disabled ? (
              <Text style={{ height: 35, minWidth: 150 }}>
                {values.acr || t("nothing")}
              </Text>
            ) : (
              <InputBoderBottom
                value={values.acr || ""}
                onChange={handleChange("acr")}
                placeHolder="Value"
              />
            )}
          </VitalContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default Labresult;
