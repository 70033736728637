import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/DrugUnits";

async function getDrugUnits() {
  const { data } = await http.get(`${apiEndpoint}`);
  return data;
}

export { getDrugUnits };
