/* eslint-disable react/no-access-state-in-setstate */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserListTable from "../../components/UserListTable";
import {
  UserOma,
  UserFcmToken,
  AppUserOma,
  KycImage,
  Organization,
} from "../../redux/actions";
import { message } from "antd";
import jwtDecode from "jwt-decode";
import Layout from "../Home";
import Loading from "../../components/Loading";
import { NOTIFICATION, NOTIFICATION_DATA } from "./constant";
import { useTranslation } from "react-i18next";

const UserList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userOma = useSelector((state) => state.userOma);
  const userFcmToken = useSelector((state) => state.userFcmToken);
  const appUserOma = useSelector((state) => state.appUserOma);
  const kycImage = useSelector((state) => state.kycImage);
  const organization = useSelector((state) => state.organization);

  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [patientId, setPatientId] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [userId, setUserId] = useState(0);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (appUserOma.organization.data === null) {
      const id = jwtDecode(localStorage.getItem("token")).id;
      dispatch(AppUserOma.getOrganizationsUser(id));
    }
  }, []);

  useEffect(() => {
    if (appUserOma.organization.isSuccess) {
      dispatch(Organization.getUsersByOrganization(appUserOma.information?.id));
    }
  }, [appUserOma]);

  useEffect(() => {
    if (organization.user.isSuccess) {
      setUsers(organization.user.data);
      setIsFirstLoad(true);
    }
  }, [organization]);

  useEffect(() => {
    if (
      appUserOma.organization.isSuccess &&
      organization.user.isSuccess &&
      isFirstLoad
    ) {
      setIsFinishInitialLoad(true);
    }
  }, [appUserOma, organization, isFirstLoad]);

  useEffect(() => {
    if (
      (appUserOma.isSuccess || (userOma.isSuccess && userFcmToken.isSuccess)) &&
      isUpdate
    ) {
      initialAfterUpdate();
    }
  }, [appUserOma, userOma, isUpdate, userFcmToken]);

  const initialAfterUpdate = () => {
    setIsUpdate(false);
    message.success("อัปเดตข้อมูลเรียบร้อยแล้ว");
    dispatch(UserOma.getUserOma());
  };

  const handleCancelVerify = (id, userId) => {
    dispatch(UserOma.updateUserOma(id, { verifyId: false }));
    sendUserNotification(userId, false);
    setIsUpdate(true);
  };

  const handleVerify = () => {
    setVisible(false);
    dispatch(UserOma.updateUserOma(patientId, { verifyId: true }));
    sendUserNotification(userId, true);
    setIsUpdate(true);
  };

  const handleVerifyModal = (id, practitionerId) => {
    setPatientId(id);
    setUserId(practitionerId);
    dispatch(KycImage.getKycImages(practitionerId));
    setVisible(true);
  };

  const handleRemoveUser = (id) => {
    dispatch(AppUserOma.deleteUser(id));
  };

  const sendUserNotification = async (userId, verifyStatus) => {
    const notificationKey = verifyStatus === true ? "success" : "failed";
    dispatch(
      UserFcmToken.sendNotification(
        userId,
        NOTIFICATION[notificationKey],
        NOTIFICATION_DATA[notificationKey]
      )
    );
  };

  const onSearch = (text) => {
    const filter = organization.user.data.filter((data) => {
      const { cId, firstname, lastname } = data;
      const filterData =
        (cId && cId.includes(text)) ||
        (firstname && firstname.includes(text)) ||
        (lastname && lastname.includes(text));
      return filterData;
    });
    setUsers(filter);
  };

  const checkAddress = (address, addressTypeId) => {
    if (!address) return "-";
    const addressInfo = address.find(
      (item) => item.addressTypeId == addressTypeId
    );
    return addressInfo;
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitialLoad ? (
          <UserListTable
            t={t}
            kycImage={kycImage}
            users={users}
            patientId={patientId}
            userId={userId}
            user={organization.user}
            handleCancelVerify={handleCancelVerify}
            handleVerify={handleVerify}
            handleVerifyModal={handleVerifyModal}
            visible={visible}
            setVisible={setVisible}
            handleRemoveUser={handleRemoveUser}
            onSearch={onSearch}
            checkAddress={checkAddress}
            {...props}
            style={{ width: "100%" }}
          />
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default UserList;
