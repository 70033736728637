import { call, put, takeEvery } from "redux-saga/effects";
import { Booking } from "../../api";
import * as type from "../types";

function* fetchBookings({
  id,
  orgList,
  page,
  order,
  pageSize,
  orderByTab,
  status,
  filters,
}) {
  try {
    const bookings = yield call(
      Booking.getBookings,
      id,
      orgList,
      page,
      order,
      pageSize,
      orderByTab,
      status,
      filters
    );
    yield put({ type: type.GET_BOOKINGS_SUCCESS, data: bookings.data });
    yield put({
      type: type.GET_BOOKINGS_META_DATA_SUCCESS,
      data: bookings.meta,
    });
  } catch (e) {
    yield put({ type: type.GET_BOOKINGS_FAILED, message: e.message });
  }
}

function* getBookingPayments({ id }) {
  try {
    const payment = yield call(Booking.getBookingPayments, id);
    yield put({ type: type.GET_BOOKING_PAYMENTS_SUCCESS, data: payment });
  } catch (e) {
    yield put({ type: type.GET_BOOKING_PAYMENTS_FAILED, message: e.message });
  }
}

function* fetchBookingHistory(data) {
  try {
    const booking = yield call(Booking.getBookingHistory, {
      userId: data.userId,
      patientId: data.patientId,
      status: data.status,
    });
    yield put({ type: type.GET_BOOKING_HISTORY_SUCCESS, data: booking });
  } catch (e) {
    yield put({ type: type.GET_BOOKING_HISTORY_FAILED, message: e.message });
  }
}

function* fetchBookingHistoryWithEarning(data) {
  try {
    const booking = yield call(Booking.getBookingHistoryWithEarning, data);
    yield put({
      type: type.GET_BOOKING_HISTORY_WITH_EARNING_SUCCESS,
      data: booking.data,
      earning: booking.earning,
    });
  } catch (e) {
    yield put({
      type: type.GET_BOOKING_HISTORY_WITH_EARNING_FAILED,
      message: e.message,
    });
  }
}

function* fetchBooking(data) {
  try {
    const booking = yield call(Booking.getBooking, data.id);
    yield put({ type: type.GET_BOOKING_SUCCESS, data: booking });
  } catch (e) {
    yield put({ type: type.GET_BOOKING_FAILED, message: e.message });
  }
}

function* getHistoryBookingByDateTime(data) {
  try {
    const booking = yield call(Booking.getHistoryBookingByDateTime, data);
    yield put({
      type: type.GET_HISTORY_BOOKING_BY_DATE_TIME_SUCCESS,
      data: booking,
    });
  } catch (e) {
    yield put({
      type: type.GET_HISTORY_BOOKING_BY_DATE_TIME_FAILED,
      message: e.message,
    });
  }
}

function* updateBooking(data) {
  try {
    const booking = yield call(Booking.updateBooking, data);
    if (data.isUpdate) {
      yield put({ type: type.UPDATE_BOOKING_SUCCESS, data: booking });
    } else {
      yield put({ type: type.DELETE_BOOKING_SUCCESS, data: booking });
    }
  } catch (e) {
    yield put({ type: type.UPDATE_BOOKING_FAILED, message: e.message });
  }
}

function* fetchMedicationBookings(data) {
  try {
    const medication = yield call(Booking.getBookingMedication, data);
    yield put({ type: type.GET_MEDICATION_BOOKING_SUCCESS, data: medication });
  } catch (e) {
    yield put({ type: type.GET_MEDICATION_BOOKING_FAILED, message: e.message });
  }
}
function* fetchPrescriptionItems({ id }) {
  try {
    const prescriptions = yield call(Booking.getPrescriptionItems, id);
    yield put({
      type: type.GET_BOOKING_PRESCRIPTION_ITEMS_SUCCESS,
      data: prescriptions,
    });
  } catch (e) {
    yield put({
      type: type.GET_BOOKING_PRESCRIPTION_ITEMS_FAILED,
      message: e.message,
    });
  }
}

function* deletePrescriptionItem(request) {
  try {
    const prescriptionItemId = yield call(
      Booking.deletePrescriptionItem,
      request
    );
    yield put({
      type: type.DELETE_BOOKING_PRESCRIPTION_ITEM_SUCCESS,
      data: prescriptionItemId,
    });
  } catch (e) {
    yield put({
      type: type.DELETE_BOOKING_PRESCRIPTION_ITEM_FAILED,
      message: e.message,
    });
  }
}

function* addPrescriptionItem(data) {
  try {
    const medication = yield call(Booking.addPrescriptionItem, data);
    yield put({
      type: type.ADD_BOOKING_PRESCRIPTION_ITEMS_SUCCESS,
      data: medication,
    });
  } catch (e) {
    yield put({
      type: type.ADD_BOOKING_PRESCRIPTION_ITEMS_FAILED,
      message: e.message,
    });
  }
}

function* updatePrescriptionItem(data) {
  try {
    const medication = yield call(Booking.updatePrescriptionItem, data);
    yield put({
      type: type.UPDATE_BOOKING_PRESCRIPTION_ITEM_SUCCESS,
      data: medication,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_BOOKING_PRESCRIPTION_ITEM_FAILED,
      message: e.message,
    });
  }
}

function* deleteMedicationItem(data) {
  try {
    const medication = yield call(Booking.updateBookingPrescription, data);
    yield put({ type: type.DELETE_MEDICATION_ITEM_SUCCESS, data: medication });
  } catch (e) {
    yield put({ type: type.DELETE_MEDICATION_ITEM_FAILED, message: e.message });
  }
}

function* setCurrentBooking(booking) {
  try {
    yield put({ type: type.GET_CURRENT_BOOKING_SUCCESS, data: booking.data });
  } catch (e) {
    yield put({ type: type.GET_CURRENT_BOOKING_FAILED, message: e.message });
  }
}

function* searchBookings({
  organizationId,
  firstname,
  lastname,
  cId,
  admitTimeRangeType,
  status,
  page,
  pageSize,
}) {
  try {
    const bookings = yield call(
      Booking.searchBookings,
      organizationId,
      firstname,
      lastname,
      cId,
      admitTimeRangeType,
      status,
      page,
      pageSize
    );
    yield put({ type: type.SEARCH_BOOKING_SUCCESS, data: bookings.data });
    yield put({
      type: type.GET_BOOKINGS_META_DATA_SUCCESS,
      data: bookings.meta,
    });
  } catch (e) {
    yield put({ type: type.SEARCH_BOOKING_FAILED, message: e.message });
  }
}

function* bookingSaga() {
  yield takeEvery(type.GET_BOOKINGS_REQUESTED, fetchBookings);
  yield takeEvery(type.GET_BOOKING_PAYMENTS_REQUESTED, getBookingPayments);
  yield takeEvery(
    type.GET_HISTORY_BOOKING_BY_DATE_TIME_REQUESTED,
    getHistoryBookingByDateTime
  );
  yield takeEvery(type.GET_BOOKING_REQUESTED, fetchBooking);
  yield takeEvery(type.GET_BOOKING_HISTORY_REQUESTED, fetchBookingHistory);
  yield takeEvery(
    type.GET_BOOKING_HISTORY_WITH_EARNING_REQUESTED,
    fetchBookingHistoryWithEarning
  );
  yield takeEvery(type.UPDATE_BOOKING_REQUESTED, updateBooking);
  yield takeEvery(
    type.GET_MEDICATION_BOOKING_REQUESTED,
    fetchMedicationBookings
  );
  yield takeEvery(type.DELETE_MEDICATION_ITEM_REQUESTED, deleteMedicationItem);
  yield takeEvery(type.GET_CURRENT_BOOKING_REQUESTED, setCurrentBooking);
  yield takeEvery(type.SEARCH_BOOKING_REQUESTED, searchBookings);
  yield takeEvery(
    type.ADD_BOOKING_PRESCRIPTION_ITEMS_REQUESTED,
    addPrescriptionItem
  );
  yield takeEvery(
    type.UPDATE_BOOKING_PRESCRIPTION_ITEM_REQUESTED,
    updatePrescriptionItem
  );
  yield takeEvery(
    type.GET_BOOKING_PRESCRIPTION_ITEMS_REQUESTED,
    fetchPrescriptionItems
  );
  yield takeEvery(
    type.DELETE_BOOKING_PRESCRIPTION_ITEM_REQUESTED,
    deletePrescriptionItem
  );
}

export default bookingSaga;
