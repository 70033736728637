import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Payments";

async function confirmPayment(request) {
  const { id } = request;
  await http.patch(`${apiEndpoint}/${id}/confirm`, { paidAt: new Date() });
}

async function rejectPayment(request) {
  const { id, data } = request;
  await http.patch(`${apiEndpoint}/${id}/reject`, data);
}

export { confirmPayment, rejectPayment };
