import React from "react";
import {
  Button,
  Col,
  Image,
  Modal,
  Popconfirm,
  Row,
  Tag,
  Tabs,
  Input,
} from "antd";
import Table from "../Table";
import { Container, HeaderWrapper, Search, Text, ImageKYC } from "./styles";
import { FileOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const { TabPane } = Tabs;

function SubmissionListTable({
  sendUserNotification,
  updateSubmissionList,
  loading,
  onSearch,
  filterList,
  statusConstant,
  visibleModal,
  setVisibleModal,
  currentRecord,
  setCurrentRecord,
  kycImage,
  t,
  visibleRejectSubmissionDetail,
  rejectReason,
  setRejectReason,
  onRejectSubmissionDetail,
  onVisibleRejectSubmissionDetail,
  onPendingSubmissionDetail,
  onCancelRejectSubmissionDetail,
}) {
  const columns = [
    {
      title: t("order"),
      key: "id",
      dataIndex: "id",
      width: 100,
      align: "center",
    },
    {
      title: t("user_id"),
      key: "appUserId",
      dataIndex: "appUserId",
      width: 150,
      align: "center",
    },
    {
      title: t("full_name"),
      key: "name",
      width: 200,
      render: (record) => {
        const { firstName, lastName } = record;
        const fullName = `${firstName} ${lastName}`;
        return <Text>{fullName}</Text>;
      },
    },
    {
      title: t("c_id"),
      key: "cId",
      dataIndex: "cId",
      width: 150,
    },
    {
      title: t("mobile_number"),
      key: "mobileNumber",
      width: 150,
      dataIndex: "mobileNumber",
    },
    {
      title: t("address"),
      key: "address",
      width: 180,
      align: "center",
      render: (record) => {
        if (record.address) {
          return `เลขที่ ${record.address.no || "-"} หมู่บ้าน ${
            record.address.village || "-"
          } ซอย ${record.address.soi || "-"} ${record.address.road || "ถนน -"}`;
        }
        return "-";
      },
    },
    {
      title: t("sub_district"),
      key: "tambon",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.address) {
          return record.address.subDistrict;
        }
        return "-";
      },
    },
    {
      title: t("district"),
      key: "amphur",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.address) {
          return record.address.district;
        }
        return "-";
      },
    },
    {
      title: t("province"),
      key: "province",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.address) {
          return record.address.province;
        }
        return "-";
      },
    },
    {
      title: t("postal_code"),
      key: "postalCode",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.address) {
          return record.address.postalCode;
        }
        return "-";
      },
    },
    {
      title: t("status"),
      key: "status",
      width: 120,
      fixed: "right",
      align: "center",
      render: ({ status }) => {
        return (
          <Tag color={statusConstant[status]?.color ?? "green"}>
            {t(`${statusConstant[status]?.title}`) ?? "-"}
          </Tag>
        );
      },
    },
    {
      title: t("action"),
      key: "action",
      fixed: "right",
      align: "center",
      render: (record) => {
        const { id, appUserId, status } = record;
        return (
          status == "pending" && (
            <Row>
              <Col style={{ paddingRight: 10 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setCurrentRecord(record);
                    setVisibleModal(true);
                  }}
                >
                  {t("approve")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="danger"
                  onClick={() => {
                    setCurrentRecord(record);
                    onVisibleRejectSubmissionDetail(id, appUserId);
                  }}
                >
                  {t("reject")}
                </Button>
              </Col>
            </Row>
          ) ||
          status == "approved" && (
            <Row>
              <Col>
                <Button
                  type="danger"
                  onClick={() => {
                    setCurrentRecord(record);
                    onPendingSubmissionDetail(id, appUserId);
                  }}
                >
                  {t("Cancel")}
                </Button>
              </Col>
            </Row>
          ) ||
          status == "rejected" && (
            <Row>
              <Col>
                <Button
                  type="danger"
                  onClick={() => {
                    setCurrentRecord(record);
                    onPendingSubmissionDetail(id, appUserId);
                  }}
                >
                  {t("Cancel")}
                </Button>
              </Col>
            </Row>
          )
        );
      },
    },
  ];

  return (
    <Container>
      <HeaderWrapper>
        <FileOutlined />
        <Text bold style={{ margin: 0 }}>
          {t("submission_list")}
        </Text>
        <Search
          onSearch={onSearch}
          style={{ width: 200, marginLeft: "auto" }}
        />
      </HeaderWrapper>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("pending_approval")} key="1">
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={filterList.filter((data) => data.status === "pending")}
            loading={loading}
          />
        </TabPane>
        <TabPane tab={t("approved")} key="2">
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={filterList.filter((data) => data.status === "approved")}
            loading={loading}
          />
        </TabPane>
        <TabPane tab={t("rejected")} key="3">
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={filterList.filter((data) => data.status === "rejected")}
            loading={loading}
          />
        </TabPane>
      </Tabs>
      <Modal
        title={t("submission_detail")}
        visible={visibleModal}
        style={{ textAlign: "center" }}
        onCancel={() => {
          setCurrentRecord(null);
          setVisibleModal(false);
        }}
        onOk={() => {
          if (currentRecord.id) {
            updateSubmissionList(currentRecord.id, "approve");
          }
          if (currentRecord.userId) {
            sendUserNotification(currentRecord.userId, true);
          }
          setCurrentRecord(null);
          setVisibleModal(false);
        }}
      >
        {currentRecord &&
        currentRecord.kycPatientFaceImageUrl &&
        currentRecord.kycPatientCardImageUrl ? (
          <Row>
            <Image.PreviewGroup>
              <Col span={12}>
                <ImageKYC src={currentRecord.kycPatientFaceImageUrl} alt="" />
              </Col>
              <Col span={12}>
                <ImageKYC src={currentRecord.kycPatientCardImageUrl} alt="" />
              </Col>
            </Image.PreviewGroup>
          </Row>
        ) : (
          <Text>{t("not_found_image")}</Text>
        )}
      </Modal>
      <Modal
        title={t("reject_detail")}
        visible={visibleRejectSubmissionDetail}
        onCancel={onCancelRejectSubmissionDetail}
        onOk={onRejectSubmissionDetail}
      >
        <div>{t("reason_for_rejection")}</div>
        <TextArea
          rows={4}
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
    </Container>
  );
}

export default SubmissionListTable;
