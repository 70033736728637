import React from "react";
import { Row, Space, Col } from "antd";
import {
  Container,
  Text,
  Icon,
  DatePicker,
  Input,
  DropDownWidget,
  TextError,
} from "../styles";
import { MdAccountBox } from "react-icons/all";
import {
  EDUCATION,
  GENDER,
  MARITAL_STATUS,
  RELIGION,
  NATIONALITY,
} from "../constant";
import moment from "moment";

const GeneralInformation = (props) => {
  const { t, i18n, disabledDate, generalForm } = props;

  function onChangeBirthDate(date) {
    generalForm.setFieldValue(
      "birthDate",
      moment(date).startOf("date").toISOString()
    );
  }

  const renderMenu = (key, items) => (
    <Container
      style={{
        minWidth: 250,
        backgroundColor: "white",
        maxHeight: 400,
        overflowY: "scroll",
      }}
      className="shadow px-2 py-1"
    >
      {items.map((item) => {
        return (
          <a
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              generalForm.setFieldValue(key, item.label);
            }}
          >
            <Text className="py-1 my-1" key={item.key}>
              {t(item.label)}
            </Text>
          </a>
        );
      })}
    </Container>
  );

  return (
    <>
      <Container>
        <Space align="center">
          <Icon component={MdAccountBox} size="30px" color="#2BA796" />
          <Text primary className="ml-2" bold size="24px">
            {t("general_information")}
          </Text>
        </Space>
      </Container>
      <Container className="mt-4">
        <Row gutter={16}>
          <Col sm={24}>
            <Space direction="horizontal w-100">
              <Text size="12px" bold>
                {t("cid_passport")}
              </Text>
              <Text color="red">*</Text>
            </Space>
            <Input
              value={generalForm.values.identity}
              width="40%"
              minWidth="350px"
              onChange={generalForm.handleChange("identity")}
              placeHolder={t("select_cid_passport")}
            />
            {generalForm?.errors?.identity && (
              <TextError>{generalForm.errors.identity}</TextError>
            )}
          </Col>
        </Row>
        <Row align="bottom" gutter={[16, 16]} className="mt-4">
          <Col xs={12} lg={8} xl={6} xxl={4}>
            <DropDownWidget
              bold
              value={t(generalForm.values.gender)}
              width="100%"
              key={t("gender")}
              title={t("gender")}
              placeholder={t("select_gender")}
              required
              renderList={renderMenu("gender", GENDER)}
            />
            {generalForm?.errors?.gender && (
              <TextError>{generalForm.errors.gender}</TextError>
            )}
          </Col>
          <Col xs={12} lg={8} xl={6} xxl={4}>
            <Space direction="horizontal">
              <Text size="12px" bold>
                {t("date_of_birth_ctms")}
              </Text>
              <Text color="red">*</Text>
            </Space>
            <DatePicker
              format="DD MMMM YYYY"
              disabledDate={disabledDate}
              defaultValue={
                generalForm.values.birthDate
                  ? moment(generalForm.values.birthDate)
                  : null
              }
              width="100%"
              onChange={onChangeBirthDate}
            />
            {generalForm?.errors?.birthDate && (
              <TextError>{generalForm.errors.birthDate}</TextError>
            )}
          </Col>
          {/* <Col xs={12} lg={8} xl={4} xxl={4}>
            <DropDownWidget
              bold
              value={t(generalForm.values.status)}
              width="100%"
              key={t("status")}
              title={t("status")}
              placeholder={t("select_status")}
              required
              renderList={renderMenu("status", MARITAL_STATUS)}
            />
            {generalForm?.errors?.status && (
              <TextError>{generalForm.errors.status}</TextError>
            )}
          </Col> */}
        </Row>
        <Row align="bottom" gutter={[16, 16]} className="mt-4">
          <Col xs={12} lg={8} xl={6} xxl={4}>
            <DropDownWidget
              bold
              value={generalForm.values.nationality}
              width="100%"
              key={t("nationality")}
              title={t("nationality")}
              placeholder={t("select_nationality")}
              required
              renderList={renderMenu("nationality", NATIONALITY(i18n.language))}
            />
            {generalForm?.errors?.nationality && (
              <TextError>{generalForm.errors.nationality}</TextError>
            )}
          </Col>
          {/* <Col xs={12} lg={8} xl={6} xxl={4}>
            <DropDownWidget
              bold
              value={t(generalForm.values.religion)}
              width="100%"
              key={t("religion")}
              title={t("religion")}
              placeholder={t("enter_religion")}
              required
              renderList={renderMenu("religion", RELIGION)}
            />
          </Col> */}
        </Row>
        {/* <Row align="bottom" gutter={16} className="mt-4">
          <Col xs={12} lg={8} xl={6} xxl={4}>
            <DropDownWidget
              bold
              value={t(generalForm.values.education)}
              width="100%"
              key={t("educational")}
              title={t("educational")}
              placeholder={t("select_education")}
              required
              renderList={renderMenu("education", EDUCATION)}
            />
            {generalForm?.errors?.education && (
              <TextError>{generalForm.errors.education}</TextError>
            )}
          </Col>
          <Col xs={12} lg={8} xl={6} xxl={4}>
            <Text size="12px" bold>
              {t("occupation")}
            </Text>
            <Input
              value={generalForm.values.occupation}
              onChange={generalForm.handleChange("occupation")}
              width="100%"
              placeHolder={t("enter_occupation")}
            />
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default GeneralInformation;
