import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import Video from "twilio-video";
import { decryptHelper } from "../../common/hash";
import {
  Firebase,
  Treatment,
  Booking,
  Communication,
  AppUserOma,
  DrugTimeEvent,
  DrugUnit,
  DrugTimeFrequency,
  MedicationForOrganization,
} from "../../redux/actions";

import Room from "../../components/Room";
import { PENDING_NOTE_STATUS, COLORS } from "./constant";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import useExitPrompt from "../../hooks/useExitPrompt/useExitPrompt";
import { message } from "antd";

const RoomContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { match, history } = props;

  const appUserOma = useSelector((state) => state.appUserOma);
  const communication = useSelector((state) => state.communication);
  const booking = useSelector((state) => state.booking);
  const treatment = useSelector((state) => state.treatment);
  const medication = useSelector(
    (state) => state.medicationForOrganization.medication
  );
  const drugUnit = useSelector((state) => state.drugUnit);
  const drugTimeEvent = useSelector((state) => state.drugTimeEvent);
  const drugTimeFrequency = useSelector((state) => state.drugTimeFrequency);

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);

  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [roomType, setRoomType] = useState(null);
  const [date, setDate] = useState("");
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [note, setNote] = useState("");
  const [color, setColor] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [isChat, setIsChat] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [twilioToken, setTwilioToken] = useState(null);
  const [role, setRole] = useState(null);
  const [country, setCountry] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [isDisableVideo, setIsDisableVideo] = useState(false);
  const [collapsedNote, setCollapsedNote] = useState(true);
  const [collapsedChat, setCollapsedChat] = useState(false);
  const [collapsedTelemonitoring, setCollapsedTelemonitoring] = useState(true);
  const [collapsedInformation, setCollapsedInformation] = useState(false);
  const [visibleCovidModal, setVisibleCovidModal] = useState(false);
  const [visiblePrescriptionList, setVisiblePrescriptionList] = useState(false);
  const [visibleHistoryBookingModal, setVisibleHistoryBookingModal] =
    useState(false);
  const [visiblePrescriptionModal, setVisiblePrescriptionModal] =
    useState(false);
  const [search, setSearch] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    getDrugTime();
    initialData();
    checkToken();
  }, []);

  useEffect(() => {
    if (currentBookingId !== null) {
      dispatch(Booking.getPrescriptionItems(currentBookingId));
    }
  }, [currentBookingId]);

  useEffect(() => {
    if (
      twilioToken === null &&
      communication.isSuccess &&
      communication.token !== null
    ) {
      storeToken();
    }
  }, [communication]);

  useEffect(() => {
    dispatch(MedicationForOrganization.fetchMedications(search));
  }, [search]);

  useEffect(() => {
    if (appUserOma.isSuccess && appUserOma.information !== null) {
      setRole(appUserOma.information.roles[0].name);
    }
  }, [appUserOma]);

  useEffect(() => {
    if (booking.isSuccess) {
      if (booking.prescription.isSuccess) {
        setMedicines(booking.prescription.data);
      }
    }
  }, [booking]);

  useEffect(() => {
    updatePrescriptionListToFirebase();
  }, [medicines]);

  useEffect(() => {
    if (
      twilioToken !== null &&
      booking.isSuccess &&
      room === null &&
      isFirstLoad &&
      date !== ""
    ) {
      setIsFirstLoad(false);
      const participantConnected = (participant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
      };
      const participantDisconnected = (participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant)
        );
      };
      Video.connect(twilioToken, {
        name: booking.current.id + date,
        audio: true,
        video: {
          height: 1080,
          frameRate: 45,
          width: 1920,
          contentPreferencesMode: "manual",
        },
        bandwidthProfile: {
          video: {
            mode: "collaboration",
            dominantSpeakerPriority: "standard",
          },
        },
        dominantSpeaker: true,
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        networkQuality: { local: 1, remote: 1 },
      }).then((room) => {
        setRoom(room);
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
        setParticipants((prev) => [...prev]);
      });

      return () => {
        setRoom((prevRoom) => {
          if (prevRoom && prevRoom.localParticipant.state === "connected") {
            prevRoom.localParticipant.tracks.forEach((trackPublication) => {
              trackPublication.track.stop();
            });
            prevRoom.disconnect();
            return null;
          }
          return prevRoom;
        });
      };
    }
  }, [twilioToken, booking, date]);

  useEffect(() => {
    if (room !== null) {
      handleVideoButton(true);
    }
  }, [room]);

  useEffect(() => {
    if (
      (twilioToken !== null || communication.isSuccess) &&
      booking.isSuccess &&
      appUserOma.isSuccess &&
      drugUnit.isSuccess &&
      drugTimeEvent.isSuccess &&
      drugTimeFrequency.isSuccess &&
      appUserOma.organization.isSuccess &&
      room !== null &&
      !isFirstLoad
    ) {
      setIsFinishInitialLoad(true);
    }
  }, [
    twilioToken,
    communication,
    booking,
    appUserOma,
    room,
    drugUnit,
    drugTimeEvent,
    drugTimeFrequency,
  ]);

  const checkToken = () => {
    const token = localStorage.getItem("tokenTwilio");
    if (token === null) {
      dispatch(Communication.getToken());
    } else {
      setTwilioToken(token);
    }
  };

  const storeToken = () => {
    localStorage.setItem("tokenTwilio", communication.token);
    setTwilioToken(communication.token);
  };

  const initialData = () => {
    const userId = jwtDecode(localStorage.getItem("token")).id;
    const unHash = JSON.parse(decryptHelper(match.params.id));
    const { bookingId, date, roomType, country, specialty } = unHash;
    setRoomType(roomType);
    setDate(date);
    setCountry(country);
    setSpecialty(specialty);
    dispatch(DrugUnit.getDrugUnits());
    dispatch(Booking.getBooking(bookingId));
    setCurrentBookingId(bookingId);
    dispatch(AppUserOma.getOrganizationsUser(userId));
    dispatch(AppUserOma.getUser(userId));
    dispatch(AppUserOma.getProfile(userId));
  };

  const updatePrescriptionListToFirebase = () => {
    const newMedicines = medicines.map((medicine) => ({
      name: medicine._medicationForOrg.tradeName,
      amount: medicine.amount,
    }));
    if (role === "communityPharmacist") {
      dispatch(
        Firebase.updateRoom(
          "COMMUNITY_PHARMACIST",
          appUserOma.information.id,
          booking.current.patient.id,
          {
            prescriptions: newMedicines,
          },
          "communityPharmacist"
        )
      );
    } else {
      dispatch(
        Firebase.updateRoomByCountryAndSpecialty(
          country,
          specialty,
          { prescriptions: newMedicines },
          booking.current.patientId
        )
      );
    }
  };

  const handleCloseSider = () => {
    setCollapsedNote(false);
    setCollapsedChat(false);
    setCollapsedTelemonitoring(false);
  };

  const handleVisibleCovidModal = () => {
    setVisibleCovidModal(true);
  };

  const handleVisibleHistoryBookingModal = () => {
    dispatch(Treatment.getBookingsByTreatmentId(booking.current.treatmentId));
    setVisibleHistoryBookingModal(true);
  };

  const handleAudioButton = (mute) => {
    toggle(mute, "audioTracks");
    setIsMute(mute);
  };

  const handleVideoButton = (disabled) => {
    toggle(disabled, "videoTracks");
    setIsDisableVideo(disabled);
  };

  const handleVisibleNote = () => {
    setCollapsedChat(true);
    setCollapsedNote(!collapsedNote);
    setCollapsedTelemonitoring(true);
    setIsChat(false);
  };

  const handleVisibleChat = () => {
    setCollapsedChat(!collapsedChat);
    setCollapsedNote(true);
    setCollapsedTelemonitoring(true);
    if (booking.current.patient.userInformation) {
      setIsChat(true);
    }
  };

  const handleVisibleTelemonitoring = () => {
    setCollapsedChat(true);
    setCollapsedNote(true);
    setCollapsedTelemonitoring(!collapsedTelemonitoring);
    setIsChat(false);
  };

  const handleVisibleInformation = () => {
    setCollapsedInformation(!collapsedInformation);
  };

  const handleDisconnect = () => {
    setIsFinishInitialLoad(false);
    localStorage.removeItem("rooms");
    const firebaseData = { status: PENDING_NOTE_STATUS[role] };
    let bookingData = {
      notes: note,
      status: PENDING_NOTE_STATUS[role],
    };
    if (roomType === "COVID") {
      bookingData = {
        ...bookingData,
        covidForm: {
          ...booking.current.covidForm,
          color: color,
        },
      };
    }
    disconnect();
    if (booking.current.bookingCategory === "scheduling") {
      dispatch(
        Firebase.updateRoom(
          "SCHEDULING",
          appUserOma.information.id,
          booking.current.patient.id,
          {
            ...firebaseData,
          },
          role
        )
      );
    } else {
      if (role === "communityPharmacist") {
        dispatch(
          Firebase.updateRoom(
            "COMMUNITY_PHARMACIST",
            appUserOma.information.id,
            booking.current.patient.id,
            {
              status: "COMMUNITY_PHARMACIST_PENDING_NOTE",
            },
            "communityPharmacist"
          )
        );
      } else {
        dispatch(
          Firebase.updateRoomByCountryAndSpecialty(
            country,
            specialty,
            { ...firebaseData },
            booking.current.patientId
          )
        );
      }
    }

    dispatch(
      Booking.updateBooking(booking.current.id, true, {
        ...bookingData,
      })
    );
    dispatch(Communication.deleteToken());
    history.push(`/summary/${match.params.id}`);
  };

  const disconnect = () => {
    if (room && room.localParticipant.state === "connected") {
      room.localParticipant.tracks.forEach((trackPublication) => {
        trackPublication.track.stop();
      });
      room.disconnect();
      return null;
    }
  };

  const toggle = (mute, key) => {
    room.localParticipant[key].forEach((track) => {
      if (mute) {
        track.track.disable();
      } else {
        track.track.enable();
      }
    });
  };

  const handleVisiblePrescription = () => {
    setVisiblePrescriptionModal(!visiblePrescriptionModal);
  };

  const getDrugTime = () => {
    dispatch(DrugTimeEvent.getDrugTimeEvent());
    dispatch(DrugTimeFrequency.getDrugTimeFrequency());
  };

  const getDrugTimeEventInfo = (code) => {
    const drugTimeInfo = drugTimeEvent.data.find((drug) => drug.code === code);
    return {
      code: drugTimeInfo.code,
      createdAt: drugTimeInfo.createdAt,
      nameEn: drugTimeInfo.nameEn,
      nameTh: drugTimeInfo.nameTh,
      _id: drugTimeInfo.id,
    };
  };

  const getDrugTimeFrequencyInfo = (code) => {
    const drugTimeInfo = drugTimeFrequency.data.find(
      (drug) => drug.code === code
    );
    return {
      code: drugTimeInfo.code,
      createdAt: drugTimeInfo.createdAt,
      nameEn: drugTimeInfo.nameEn,
      nameTh: drugTimeInfo.nameTh,
      _id: drugTimeInfo.id,
    };
  };

  const getDrugRouteInfo = (id, drugRoutes) => {
    const drugTimeInfo = drugRoutes.find((route) => route.id === id);
    return {
      createdAt: drugTimeInfo.createdAt,
      _id: drugTimeInfo.id,
      routeEn: drugTimeInfo.routeEn,
      routeTh: drugTimeInfo.routeTh,
      topicalRoute: drugTimeInfo.topicalRoute,
    };
  };

  const getDrugUnitInfo = (id) => {
    const drugUnitInfo = drugUnit.data.find((unit) => unit.id === id);
    return {
      _id: drugUnitInfo.id,
      name: drugUnitInfo.name,
      nameTh: drugUnitInfo.nameTh,
      createdAt: drugUnitInfo.createdAt,
    };
  };

  const deleteDrugFromPrescription = (id) => {
    setMedicines((prev) => prev.filter((item) => item.id !== id));
    dispatch(Booking.deletePrescriptionItem(booking.current.id, id));
  };

  const handleSelectMedicine = (id) => {
    const checkDuplicatedMedicine = medicines.filter(
      (medicine) => medicine._medicationForOrg._id === id
    );
    if (checkDuplicatedMedicine && checkDuplicatedMedicine.length > 0) {
      message.error(t("medicine_cannot_be_repeated"));
    } else {
      const findMedication = medication.data.find((item) => item.id === id);
      const drugTimeEventNew = drugTimeEvent.data[0];
      const drugRoute = findMedication.medication.drugRoutes[0];
      const newDrugUnit = drugUnit.data[0];
      const drugTimeFrequencyNew = drugTimeFrequency.data[0];
      const medicine = {
        dose: 1,
        amount: 1,
        note: "",
        _medicationForOrg: {
          _id: findMedication.id,
          activeIngredient: findMedication.activeIngredient,
          tradeName: findMedication.tradeName,
          priceCents: parseInt(findMedication.priceCents),
          strength: findMedication.strength,
          dosageForm: findMedication.dosageForm,
          unitType: findMedication.unitType,
          medicationId: findMedication.medicationId,
          organizationId: findMedication.organizationId,
        },
        _drugUnit: {
          _id: newDrugUnit.id,
          name: newDrugUnit.name,
          nameTh: newDrugUnit.nameTh,
        },
        _drugRoute: {
          _id: drugRoute.id,
          topicalRoute: drugRoute.topicalRoute,
          routeEn: drugRoute.routeEn,
          routeTh: drugRoute.routeTh,
        },
        _drugTimeEvent: {
          _id: drugTimeEventNew.id,
          code: drugTimeEventNew.code,
          nameEn: drugTimeEventNew.nameEn,
          nameTh: drugTimeEventNew.nameTh,
        },
        _drugFrequency: {
          _id: drugTimeFrequencyNew.id,
          code: drugTimeFrequencyNew.code,
          nameEn: drugTimeFrequencyNew.nameEn,
          nameTh: drugTimeFrequencyNew.nameTh,
        },
      };
      dispatch(Booking.addPrescriptionItem(booking.current.id, medicine));
      setMedicines((prev) => [...prev, medicine]);
    }
  };

  const updatePrescriptionItem = (prescriptionItemId, medicine) => {
    dispatch(
      Booking.updatePrescriptionItem(
        booking.current.id,
        prescriptionItemId,
        medicine
      )
    );
  };

  return isFinishInitialLoad ? (
    <Room
      t={t}
      room={room}
      participants={participants}
      note={note}
      setNote={setNote}
      color={color}
      setColor={setColor}
      isChat={isChat}
      isMute={isMute}
      roomType={roomType}
      booking={booking}
      treatment={treatment}
      practitioner={appUserOma.information}
      organization={appUserOma?.organization?.data[0]}
      role={role}
      isDisableVideo={isDisableVideo}
      visibleCovidModal={visibleCovidModal}
      visibleHistoryBookingModal={visibleHistoryBookingModal}
      setVisibleCovidModal={setVisibleCovidModal}
      setVisibleHistoryBookingModal={setVisibleHistoryBookingModal}
      handleCloseSider={handleCloseSider}
      handleVisibleCovidModal={handleVisibleCovidModal}
      handleVisibleHistoryBookingModal={handleVisibleHistoryBookingModal}
      handleAudioButton={handleAudioButton}
      handleVideoButton={handleVideoButton}
      handleDisconnect={handleDisconnect}
      collapsedNote={collapsedNote}
      collapsedChat={collapsedChat}
      collapsedTelemonitoring={collapsedTelemonitoring}
      collapsedInformation={collapsedInformation}
      handleVisibleNote={handleVisibleNote}
      handleVisibleChat={handleVisibleChat}
      handleVisibleTelemonitoring={handleVisibleTelemonitoring}
      handleVisibleInformation={handleVisibleInformation}
      handleVisiblePrescription={handleVisiblePrescription}
      visiblePrescriptionModal={visiblePrescriptionModal}
      getDrugTimeEventInfo={getDrugTimeEventInfo}
      getDrugTimeFrequencyInfo={getDrugTimeFrequencyInfo}
      getDrugRouteInfo={getDrugRouteInfo}
      getDrugUnitInfo={getDrugUnitInfo}
      deleteDrugFromPrescription={deleteDrugFromPrescription}
      visiblePrescriptionList={visiblePrescriptionList}
      setVisiblePrescriptionList={setVisiblePrescriptionList}
      handleSelectMedicine={handleSelectMedicine}
      medication={medication}
      updatePrescriptionItem={updatePrescriptionItem}
      medicines={medicines}
      setMedicines={setMedicines}
      drugUnits={drugUnit.data}
      drugTimeEvent={drugTimeEvent.data}
      drugTimeFrequency={drugTimeFrequency.data}
      prescription={booking.prescription}
    />
  ) : (
    <Loading />
  );
};
export default RoomContainer;
