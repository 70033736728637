import {
  DailyAssessmentPreview,
  ProductfeasibilityPreview,
  AdverseLogPreview,
  NasalAndSinusExamPreview,
  EndOfSutdyExamPreview,
  DemographicsPreview,
  VitalSignMeasurementsPreview,
  EligibilityCriteriaPreview,
  InformedConsentPreview,
  SeriousAdverseEventPreview,
  SubjectDeviationPreview,
} from "../../components/ClinicTrialFormTemplate";

export const EXPORT_DOCUMENT = ["principleInvestigation", "doctor", "nurse"];

export const PREVIEW_COMPONENT = {
  daily_assessment: DailyAssessmentPreview,
  product_feasibility: ProductfeasibilityPreview,
  adverse_log: AdverseLogPreview,
  crf_nasal_sinus_exam: NasalAndSinusExamPreview,
  crf_follow_nasal_sinus_exam: NasalAndSinusExamPreview,
  end_of_study_exam: EndOfSutdyExamPreview,
  crf_demographics: DemographicsPreview,
  crf_vital_sign: VitalSignMeasurementsPreview,
  crf_eligibility_criteria: EligibilityCriteriaPreview,
  crf_informed_consent: InformedConsentPreview,
  crf_serious_adverse_event: SeriousAdverseEventPreview,
  withdrawn_title: SubjectDeviationPreview,
};

export const CRF_FORM_LIST = [
  {
    key: "crf_demographics",
  },
  {
    key: "crf_vital_sign",
  },
  {
    key: "crf_nasal_sinus_exam",
  },
  {
    key: "crf_eligibility_criteria",
  },
  { key: "daily_assessment" },
  {
    key: "adverse_log",
  },
  {
    key: "crf_follow_nasal_sinus_exam",
  },
  {
    key: "product_feasibility",
  },
  {
    key: "end_of_study_exam",
  },
  {
    key: "crf_informed_consent",
  },
];

export const fileNameHandler = (formData) => {
  switch (formData?.type) {
    case "daily_assessment":
      return `daily_assessment_${formData.subjectId}_day${formData.day}.pdf`;
    case "product_feasibility":
      return `product_feasibility_${formData.subjectId}.pdf`;
    case "adverse_log":
      return `adverse_event_trackinglog_${formData.subjectId}.pdf`;
    case "crf_nasal_sinus_exam":
      return `nasal_and_sinus_examination_${formData.subjectId}.pdf`;
    case "crf_follow_nasal_sinus_exam":
      return `nasal_and_sinus_examination_${formData.subjectId}_day8.pdf`;
    case "end_of_study_exam":
      return `end_of_study_exam_${formData.subjectId}.pdf`;
    case "crf_demographics":
      return `demographics_${formData.subjectId}.pdf`;
    case "crf_vital_sign":
      return `vital_sign_${formData.subjectId}.pdf`;
    case "crf_eligibility_criteria":
      return `eligibility_criteria_${formData.subjectId}.pdf`;
    case "crf_informed_consent":
      return `informed_consent_${formData.subjectId}.pdf`;
    case "crf_serious_adverse_event":
      return `serious_adverse_event_traking_log_${formData.subjectId}.pdf`;
    case "withdrawn_title":
      return `withdrawn_${formData.subjectId}.pdf`;
    default:
      return `subjectId_${formData.subjectId}.pdf`;
  }
};
