import React from "react";
import { Modal, Space, Divider, Row, Col, Button } from "antd";
import { Container, Text, Input, DatePicker, Radio } from "./styles";
import moment from "moment";

const CtmsPatientDetailModal = ({
  t,
  visible,
  setVisible,
  detailForm,
  isEditMode,
  setIsEditMode,
  canEdit,
}) => {
  const transFormDate = (value) => {
    return value && moment(value).isValid() ? moment(value) : null;
  };
  const inputColor = (value) => {
    return value ? "#000" : "#aaa";
  };

  const onChangeDateHandler = (date, key) => {
    detailForm.setFieldValue(key, moment(date).toISOString());

  };
  return (
    <Modal
      title={t("general_information")}
      width={800}
      footer={
        !isEditMode
          ? [
            ...(canEdit
              ? [
                <Button type="primary" onClick={() => setIsEditMode(true)}>
                  Edit
                </Button>,
              ]
              : []),
            <Button onClick={() => setVisible(false)}>Close</Button>,
          ]
          : [
            <Button type="primary" onClick={detailForm.handleSubmit}>
              Accept
            </Button>,
            <Button onClick={() => setVisible(false)}>Close</Button>,
          ]
      }
      visible={visible}
      onCancel={() => setVisible(false)}
    >

      <Container
        className="p-3 w-100"
        style={{ maxHeight: "85vh", overflowY: "scroll" }}
      >
        <Text size="24px" bold>
          {t("general_information")}
        </Text>
        <Space key="identificationNumber" className="w-100 mt-3" direction="vertical">
          <Text className="pl-1">1. {t("identificationNumber")}</Text>
          <Input
            maxLength={10}
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("identificationNumber")}
            color={inputColor(detailForm.values.identificationNumber)}
            value={detailForm.values.identificationNumber ?? t("nothing")}
            disabled={!isEditMode}

          />
        </Space>
        <Space key="fullName" className="w-100 mt-3" direction="vertical">
          <Text className="pl-1">2. {t("fullName")}</Text>
          <Input
            maxLength={10}
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("fullName")}
            color={inputColor(detailForm.values.fullName)}
            value={detailForm.values.fullName ?? t("nothing")}
            disabled={!isEditMode}
          />
        </Space>

        <Space key="phoneno" className="mt-3 w-100" direction="vertical">
          <Text className="pl-1">3. {t("contact_number")}</Text>
          <Input
            maxLength={10}
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("phoneno")}
            color={inputColor(detailForm.values.phoneno)}
            value={detailForm.values.phoneno ?? t("nothing")}
            disabled={!isEditMode}
          />
        </Space>
        <Space key="birthsex" className="mt-3 w-100" direction="vertical">
          <Text className="pl-1">4. {t("birth_sex")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("gender")}
            value={detailForm.values.gender}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="male">{t("MALE")}</Radio>
              <Radio className="mt-1" value="female">
                {t("FEMALE")}
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
        <Space key="age" className="mt-3 w-100" direction="vertical">
          <Text className="pl-1">5. {t("age")}</Text>
          <Input
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("age")}
            color={inputColor(detailForm.values.age)}
            value={detailForm.values.age ?? t("nothing")}
            disabled={!isEditMode}
          />
        </Space>
        <Space key="height" className="mt-3 w-100" direction="vertical">
          <Text className="pl-1">6. {t("height")}</Text>
          <Input
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("height")}
            color={inputColor(detailForm.values.height)}
            value={detailForm.values.height ?? t("nothing")}
            disabled={!isEditMode}
          />
        </Space>
        <Space key="weight" className="mt-3 w-100" direction="vertical">
          <Text className="pl-1">7. {t("weight")}</Text>
          <Input
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("weight")}
            color={inputColor(detailForm.values.weight)}
            value={detailForm.values.weight ?? t("nothing")}
            disabled={!isEditMode}
          />
        </Space>
        <Space key="bmi" className="mt-3 w-100" direction="vertical">
          <Text className="pl-1">8. {t("bmi")}</Text>
          <Input
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("bmi")}
            color={inputColor(detailForm.values.bmi)}
            value={detailForm.values.bmi ?? t("nothing")}
            disabled={!isEditMode}
          />
        </Space>
        {/* {detailForm.values.smoke && (
          <Space key="smoke" className="mt-3 w-100" direction="vertical">
            <Text>7. {t("ctms_smoke")}</Text>
            <Radio.Group
              disabled={!isEditMode}
              onChange={detailForm.handleChange("smoke")}
              value={detailForm.values.smoke}
            >
              <Space className="mt-1" direction="vertical">
                <Radio value="yes">{t("radio_yes")}</Radio>
                <Radio className="mt-1" value="no">
                  {t("radio_no")}
                </Radio>
              </Space>
            </Radio.Group>
          </Space>
        )} */}
        {/* {Array.isArray(detailForm.values.diagnosedHistory) &&
          detailForm.values.diagnosedHistory.length > 0 && (
            <Space
              key="diagnosed_history"
              className="mt-3 w-100"
              direction="vertical"
            >
              <Text>7. {t("diagnosed_history")}</Text>
              <Space className="mt-1" direction="vertical">
                <Radio
                  disabled
                  checked={detailForm.values.diagnosedHistory.includes(
                    "asthma"
                  )}
                  value="asthma"
                >
                  {t("asthma")}
                </Radio>
                <Radio
                  disabled
                  checked={detailForm.values.diagnosedHistory.includes(
                    "bronchitis"
                  )}
                  value="bronchitis"
                >
                  {t("bronchitis")}
                </Radio>
                <Radio
                  disabled
                  checked={detailForm.values.diagnosedHistory.includes(
                    "emphysema"
                  )}
                  value="emphysema"
                >
                  {t("emphysema")}
                </Radio>
                <Radio
                  disabled
                  checked={detailForm.values.diagnosedHistory.includes(
                    "pneumonia"
                  )}
                  value="pneumonia"
                >
                  {t("pneumonia")}
                </Radio>
                <Radio
                  disabled
                  checked={detailForm.values.diagnosedHistory.includes(
                    "pulmonary_tuberculosis"
                  )}
                  value="pulmonary_tuberculosis"
                >
                  {t("pulmonary_tuberculosis")}
                </Radio>
                <Radio
                  disabled
                  checked={detailForm.values.diagnosedHistory.includes(
                    "not_applicable"
                  )}
                  value="not_applicable"
                >
                  {t("not_applicable")}
                </Radio>
              </Space>
            </Space>
          )} */}
        {/* <Space key="pregnancy" className="mt-3 w-100" direction="vertical">
          <Text>7. {t("are_you_currently_pregnant")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("pregnancy")}
            value={detailForm.values.pregnancy}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
        <Space key="lungAbnormal" className="mt-3 w-100" direction="vertical">
          <Text style={{ whiteSpace: "pre-wrap" }}>
            8. {t("pneumonia_within_six_months")}
          </Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("lungAbnormal")}
            value={detailForm.values.lungAbnormal}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.lungAbnormal === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("lungAbnormalInfo")}
                  color={inputColor(detailForm.values.lungAbnormalInfo)}
                  value={detailForm.values.lungAbnormalInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
              <Col span={12}>
                <Text size="12px">{t("date")}</Text>
                <DatePicker
                  onChange={onChangeDateHandler.bind(this, "lungAbnormalDate")}
                  placeholder={t("pick_a_date")}
                  disabled={!isEditMode}
                  width="100%"
                  format="DD MMMM YYYY"
                  defaultValue={transFormDate(
                    detailForm.values.lungAbnormalDate
                  )}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Space
          key="examinationHistory"
          className="mt-3 w-100"
          direction="vertical"
        >
          <Text>9. {t("previous_clinically_desease_surgery")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("examinationHistory")}
            value={detailForm.values.examinationHistory}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.examinationHistory === "yes" && (
            <Row gutter={16}>
              <Col span={24}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("examinationHistoryInfo")}
                  color={inputColor(detailForm.values.examinationHistoryInfo)}
                  value={
                    detailForm.values.examinationHistoryInfo ?? t("nothing")
                  }
                  disabled={!isEditMode}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Space key="rhinits" className="mt-3 w-100" direction="vertical">
          <Text>10. {t("allerfic_rhinitis")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("rhinits")}
            value={detailForm.values.rhinits}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.rhinits === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("rhinitsInfo")}
                  color={inputColor(detailForm.values.rhinitsInfo)}
                  value={detailForm.values.rhinitsInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
              <Col span={12}>
                <Text size="12px">{t("date")}</Text>
                <DatePicker
                  onChange={onChangeDateHandler.bind(this, "rhinitsDate")}
                  placeholder={t("pick_a_date")}
                  disabled={!isEditMode}
                  width="100%"
                  format="DD MMMM YYYY"
                  defaultValue={transFormDate(detailForm.values.rhinitsDate)}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Space key="sinus" className="mt-3 w-100" direction="vertical">
          <Text>11. {t("surgery_or_radiotherapy")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("sinus")}
            value={detailForm.values.sinus}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.sinus === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("sinusInfo")}
                  color={inputColor(detailForm.values.sinusInfo)}
                  value={detailForm.values.sinusInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
              <Col span={12}>
                <Text size="12px">{t("date")}</Text>
                <DatePicker
                  onChange={onChangeDateHandler.bind(this, "sinusDate")}
                  placeholder={t("pick_a_date")}
                  disabled={!isEditMode}
                  width="100%"
                  format="DD MMMM YYYY"
                  defaultValue={transFormDate(detailForm.values.sinusDate)}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Divider />
        <Space key="vacinated2dose" className="w-100" direction="vertical">
          <Text>12. {t("vacinated_2_dose")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("vacinated2dose")}
            value={detailForm.values.vacinated2dose}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
        <Space key="covidInfected" className="mt-3 w-100" direction="vertical">
          <Text>13. {t("covid_infected")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("covidInfected")}
            value={detailForm.values.covidInfected}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
        <Space key="closeToPatient" className="mt-3 w-100" direction="vertical">
          <Text>14. {t("close_to_patient")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("closeToPatient")}
            value={detailForm.values.closeToPatient}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
        </Space> */}
        <Divider />
        <Space key="eyesurgery" className="w-100" direction="vertical">
          <Text>9. {t("eyesurgery")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("eyesurgery")}
            value={detailForm.values.eyesurgery}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.eyesurgery === "yes" && (
            <Row gutter={16}>
              <Col span={24}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("eyesurgeryInfo")}
                  color={inputColor(detailForm.values.eyesurgeryInfo)}
                  value={detailForm.values.eyesurgeryInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Space key="diabetes" className="mt-3 w-100" direction="vertical">
          <Text>10. {t("diabetes")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("diabetes")}
            value={detailForm.values.diabetes}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.diabetes === "yes" && (
            <Row gutter={16}>
              <Col span={24}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("diabetesInfo")}
                  color={inputColor(detailForm.values.diabetesInfo)}
                  value={detailForm.values.diabetesInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Space key="nephropathy" className="mt-3 w-100" direction="vertical">
          <Text>11. {t("nephropathy")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("nephropathy")}
            value={detailForm.values.nephropathy}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.nephropathy === "yes" && (
            <Row gutter={16}>
              <Col span={24}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("nephropathyInfo")}
                  color={inputColor(detailForm.values.nephropathyInfo)}
                  value={detailForm.values.nephropathyInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
            </Row>
          )}
        </Space>
        {/* <Space key="allergy" className="mt-3 w-100" direction="vertical">
          <Text>15. {t("drug_allergy")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("allergy")}
            value={detailForm.values.allergy}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.allergy === "yes" && (
            <Row gutter={16}>
              <Col span={24}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("allergyInfo")}
                  color={inputColor(detailForm.values.allergyInfo)}
                  value={detailForm.values.allergyInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Space key="clinicalTrial" className="mt-3 w-100" direction="vertical">
          <Text>16. {t("clinical_trial_history")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("clinicalTrial")}
            value={detailForm.values.clinicalTrial}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.clinicalTrial === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("clinicalTrialInfo")}
                  color={inputColor(detailForm.values.clinicalTrialInfo)}
                  value={detailForm.values.clinicalTrialInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
              <Col span={12}>
                <Text size="12px">{t("date")}</Text>
                <DatePicker
                  onChange={onChangeDateHandler.bind(this, "clinicalTrialDate")}
                  placeholder={t("pick_a_date")}
                  disabled={!isEditMode}
                  width="100%"
                  format="DD MMMM YYYY"
                  defaultValue={transFormDate(
                    detailForm.values.clinicalTrialDate
                  )}
                />
              </Col>
            </Row>
          )}
        </Space>
        <Space key="nasalSpray" className="mt-3 w-100" direction="vertical">
          <Text>17. {t("nasal_spray_history")}</Text>
          <Radio.Group
            disabled={!isEditMode}
            onChange={detailForm.handleChange("nasalSpray")}
            value={detailForm.values.nasalSpray}
          >
            <Space className="mt-1" direction="vertical">
              <Radio value="yes">{t("radio_yes")}</Radio>
              <Radio className="mt-1" value="no">
                {t("radio_no")}
              </Radio>
            </Space>
          </Radio.Group>
          {detailForm.values.nasalSpray === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
                <Text size="12px">{t("more_detail")}</Text>
                <Input
                  placeholder={t("nothing")}
                  onChange={detailForm.handleChange("nasalSprayInfo")}
                  color={inputColor(detailForm.values.nasalSprayInfo)}
                  value={detailForm.values.nasalSprayInfo ?? t("nothing")}
                  disabled={!isEditMode}
                />
              </Col>
              <Col span={12}>
                <Text size="12px">{t("date")}</Text>
                <DatePicker
                  onChange={onChangeDateHandler.bind(this, "nasalSprayDate")}
                  placeholder={t("pick_a_date")}
                  disabled={!isEditMode}
                  width="100%"
                  format="DD MMMM YYYY"
                  defaultValue={transFormDate(detailForm.values.nasalSprayDate)}
                />
              </Col>
            </Row>
          )}
        </Space> */}
        {/* <Space key="checkDate" className="mt-3 w-100" direction="vertical">
          <Text>18. {t("check_date")}</Text>
          <Input
            placeholder={t("nothing")}
            onChange={detailForm.handleChange("checkDate")}
            color={inputColor(detailForm.values.checkDate)}
            value={
              isEditMode
                ? detailForm.values.checkDate
                : detailForm.values.checkDate
                  ? t("group_by") + " " + detailForm.values.checkDate
                  : t("nothing")
            }
            disabled={!isEditMode}
          />
        </Space> */}
      </Container>
    </Modal>
  );
};

export default CtmsPatientDetailModal;
