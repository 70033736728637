import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Practitioners";

async function getPractitionersByFilter(filter) {
  const filterString = JSON.stringify(filter);
  const encode = encodeURIComponent(filterString);
  const { data } = await http.get(`${apiEndpoint}?filter=${encode}`);
  return data;
}

async function getPractitionerById(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}`);
  return data;
}

async function getTimeSlots(request) {
  const { id } = request;
  const { data } = await http.get(`${apiEndpoint}/${id}/timeSlots`);
  return data;
}

export { getPractitionersByFilter, getPractitionerById, getTimeSlots };
