import React from "react";
import { Space, Checkbox } from "antd";
import { Container, Text, Icon, CollapseCustom, DropDownWidget } from "../styles";
import { GiHealthNormal } from "react-icons/all";
import "../styles.css";
import {
  EDUCATION,
  GENDER,
  MARITAL_STATUS,
  RELIGION,
  NATIONALITY,
  GROUP,
} from "../constant";

const { Panel } = CollapseCustom;


const ExclusionInclusionCriteria = (props) => {

  const { t, inclusion, exclusion, inAndExCriteriaForm, inclusionCKD } = props;
  const renderMenu = (key, items) => (
    <Container
      style={{
        minWidth: 250,
        backgroundColor: "white",
        maxHeight: 400,
        overflowY: "scroll",
      }}
      className="shadow px-2 py-1"
    >
      {items.map((item) => {
        return (
          <a
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              inAndExCriteriaForm.setFieldValue("participantGroup", item.label);
            }}

          >
            <Text className="py-1 my-1" key={item.key}>
              {t(item.label)}
            </Text>
          </a>
        );
      })}
    </Container>

  );
  return (
    <>
      <Container>
        <Space align="center">
          <Icon component={GiHealthNormal} size="30px" color="#2BA796" />
          <Text primary className="ml-2" bold size="24px">
            {t("inclusion_exclusion_criteria")}
          </Text>
        </Space>
        <DropDownWidget
          bold
          className={`mt-2`}
          key={t("group")}
          placeholder={t("Group")}
          value={inAndExCriteriaForm.values.participantGroup}
          required
          title={("Group participant")}
          renderList={renderMenu("group", GROUP)}
        />

      </Container>

      <Container className="container-inclusion-exclusion mt-3">
        <CollapseCustom defaultActiveKey={"1"} onChange={() => { }}>
          {inAndExCriteriaForm.values.participantGroup == "Healthy" ? (
            <Panel
              key="1"
              header={inAndExCriteriaForm.values.participantGroup == "Healthy" ? (
                <Text primary bold size="16px">
                  Inclusion criteria (inclusion for healthy subjects)
                </Text>) : null
              }
            >
              <Container>
                {inclusion.map((item, index) => {
                  return (
                    <Container className="mb-3" key={index}>
                      <Checkbox
                        checked={inAndExCriteriaForm.values[item.key]}
                        onChange={inAndExCriteriaForm.handleChange(item.key)}
                      >
                        <Text style={{ whiteSpace: "pre-wrap" }}>
                          {t(item.title)}
                        </Text>
                      </Checkbox>
                    </Container>
                  );
                })}
              </Container>
            </Panel>
          ) : null}
          {inAndExCriteriaForm.values.participantGroup == "CKD" ? (
            <Panel
              key="2"
              header={
                <Text primary bold size="16px">
                  {/* Inclusion criteria (7) */}
                  Inclusion criteria (inclusion for CKD subjects)
                </Text>
              }
            >
              <Container>
                {inclusionCKD.map((item, index) => {
                  return (
                    <Container className="mb-3" key={index}>
                      <Checkbox
                        checked={inAndExCriteriaForm.values[item.key]}
                        onChange={inAndExCriteriaForm.handleChange(item.key)}
                      >
                        <Text style={{ whiteSpace: "pre-wrap" }}>
                          {t(item.title)}
                        </Text>
                      </Checkbox>
                    </Container>
                  );
                })}
              </Container>
            </Panel>
          ) : null}
          <Panel
            header={
              <Text primary bold size="16px">
                {/* Exclusion criteria (11) */}
                Exclusion criteria (inclusion for healthy AND CKD subjects)
              </Text>
            }
            // key="2"
            key="3"
          >
            {exclusion.map((item, index) => {
              return (
                <Container className="mb-3" key={index}>
                  <Checkbox
                    checked={inAndExCriteriaForm.values[item.key]}
                    onChange={inAndExCriteriaForm.handleChange(item.key)}
                  >
                    <Text style={{ whiteSpace: "pre-wrap" }}>
                      {t(item.title)}
                    </Text>
                  </Checkbox>
                </Container>
              );
            })}
          </Panel>
        </CollapseCustom>
      </Container>
    </>
  );
};

export default ExclusionInclusionCriteria;
