export const ROLE_TO_STATUS = {
  doctor: "DOCTOR_COMPLETED",
  communityPharmacist: "COMMUNITY_PHARMACIST_COMPLETED",
};

export const JOIN_STATUS = {
  doctor: "DOCTOR_JOIN",
  pharmacy: "PHARMACY_JOIN",
  communityPharmacist: "COMMUNITY_PHARMACIST_JOIN",
};

export const NOTIFICATION = {
  acceptCall: (language = "th") => ({
    body:
      language === "en"
        ? "Practitioner has accepted your call. Please wait for the room to be ready."
        : "แพทย์รับสายการโทรหาคุณแล้ว กรุณารออยู่ในห้อง",
    title: language === "en" ? "Alert" : "แจ้งเตือน",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
};
