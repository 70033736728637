import * as type from "../types";

export function getPractitionerSpecialties(roleId) {
  return {
    type: type.GET_PRACTITIONER_SPECIALTIES_REQUESTED,
    roleId,
  };
}

export function getAllPractitionerSpecialties() {
  return {
    type: type.GET_ALL_PRACTITIONER_SPECIALTIES_REQUESTED,
  };
}
