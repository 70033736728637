import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookingTable from "../../components/bookingTable";
import { Booking, Practitioner, UserFcmToken } from "../../redux/actions";
import Layout from "../Home";
import { useTranslation } from "react-i18next";
import {
  convertDateToRangeOfMinutes,
  convertMinuteToPeriod,
} from "../../common/date";
import moment from "moment";

const BookingFromPatient = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userOma = useSelector((state) => state.appUserOma);
  const practitioner = useSelector((state) => state.practitioners);

  const [appUserId, setAppUserId] = useState(false);
  const [tab, setTab] = useState("allBookings");
  const [searchBy, setSearchBy] = useState("name");
  const [searchText, setSearchText] = useState("");

  const [periods, setPeriods] = useState([]);
  const [times, setTimes] = useState([]);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [currentDateTimeAppointment, setCurrentDateTimeAppointment] =
    useState("");
  const [newDateTimeAppointment, setNewDateTimeAppointment] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [visibleModalAppointment, setVisibleModalAppointment] = useState(false);
  const [isInitialAppointmentLoading, setIsInitialAppointmentLoading] =
    useState(false);

  const [visibleModalConfirmAppointment, setVisibleModalConfirmAppointment] =
    useState(false);

  useEffect(() => {
    if (userOma && userOma.information) {
      const role = userOma.information.roles[0].name;
      const id =
        role === "callCenter" || role === "deliveryTeam"
          ? 0
          : userOma.information.id;
      if (searchText) {
        onSearch();
      } else {
        if (userOma.organization.isSuccess) {
          dispatch(
            Booking.getBookings(
              id,
              userOma.organization?.data,
              1,
              "descend",
              10,
              tab
            )
          );
        }
      }
      setAppUserId(id);
    }
  }, [userOma, tab]);

  useEffect(() => {
    if (practitioner.timeSlots.isSuccess) {
      calculateTimeSlot();
    }
  }, [practitioner]);

  useEffect(() => {
    if (selectedDate !== "" && visibleModalAppointment) {
      setIsInitialAppointmentLoading(false);
      dispatch(
        Practitioner.getTimeSlotsByPractitionerId({
          id: currentBooking.practitionerId,
        })
      );
    }
  }, [selectedDate]);

  const calculateTimeSlot = () => {
    const day =
      moment(selectedDate).days() === 0 ? 6 : moment(selectedDate).days() - 1;
    const rangeTimes = [];
    const tabs = [];
    const rangeMinutes = convertDateToRangeOfMinutes(selectedDate);
    const filterDateTime = practitioner.timeSlots.data.time
      .filter(
        (timeSlot) =>
          timeSlot[0] >= rangeMinutes[0] && timeSlot[1] <= rangeMinutes[1]
      )
      .sort();
    for (let i = 0; i < filterDateTime.length; i++) {
      const start = filterDateTime[i][0] - day * 1440;
      const end = filterDateTime[i][1] - day * 1440;
      let max = start;

      while (max <= end) {
        rangeTimes.push({
          minuteWithDay: max + day * 1440,
          minute: max,
          hours: moment(day)
            .startOf("isoWeek")
            .add(max, "minutes")
            .format("HH:mm"),
          period: convertMinuteToPeriod(max),
        });
        if (!tabs.includes(convertMinuteToPeriod(max))) {
          tabs.push(convertMinuteToPeriod(max));
        }
        max += practitioner.timeSlots.data.minute;
      }
    }
    setPeriods(tabs);
    setTimes(rangeTimes);
    setIsInitialAppointmentLoading(true);
  };

  const handleChangeNewAppointment = (time) => {
    const newDateTime = `${selectedDate.format("dddd DD MMMM YYYY")} ${
      time.hours
    }`;
    setNewDateTimeAppointment(newDateTime);
    setVisibleModalConfirmAppointment(true);
  };

  const handleConfirmNewAppointment = () => {
    dispatch(
      Booking.updateBooking(currentBooking.id, true, {
        admitTime: new Date(newDateTimeAppointment),
      })
    );
    dispatch(
      UserFcmToken.sendNotification(
        currentBooking.patientId,
        (language = "th") => ({
          body: "เวลานัดหมายของท่านได้รับการเปลี่ยนแปลงจากเจ้าหน้าที่",
          title: "แจ้งเตือน",
          icon: "ic_notification",
          color: "#f1c40f",
          sound: "default",
        })
      )
    );
    setVisibleModalAppointment(false);
    setVisibleModalConfirmAppointment(false);
  };

  const onSearch = (page = 1, pageSize = 10) => {
    if (searchText && searchText.length) {
      let firstname = "";
      let lastname = "";
      let cId = "";
      let tabName = "";
      let status = "";
      if (searchBy === "name") {
        firstname = searchText.split(" ")[0];
        lastname = searchText.split(" ")[1];
      } else if (searchBy === "cid") {
        cId = searchText;
      }
      switch (tab) {
        case "allBookings":
          tabName = "";
          break;
        case "lessThan":
          tabName = "past";
          break;
        case "greaterThan":
          tabName = "future";
          break;
        case "today":
          tabName = "today";
          break;
        case "problemReport":
          tabName = "";
          status = "DOCTOR_DECLINED,PHARMACY_DECLINED";
          break;
        default:
          tabName = "";
          break;
      }
      dispatch(
        Booking.searchBookings(
          null,
          firstname,
          lastname,
          cId,
          tabName,
          status,
          page,
          pageSize
        )
      );
    } else {
      const role = userOma.information.roles[0].name;
      const id =
        role === "callCenter" || role === "deliveryTeam"
          ? 0
          : userOma.information.id;
      dispatch(
        Booking.getBookings(
          id,
          userOma.organization?.data,
          1,
          "descend",
          10,
          tab
        )
      );
    }
  };

  const getBookings = (pagination, filters, sorter) => {
    if (searchText) {
      onSearch(pagination.current, pagination.pageSize);
    } else {
      dispatch(
        Booking.getBookings(
          appUserId,
          userOma.organization?.data,
          pagination ? pagination.current : 1,
          sorter ? sorter.order : "descend",
          pagination ? pagination.pageSize : 10,
          tab,
          "",
          filters
        )
      );
    }
  };

  return (
    <Layout
      {...props}
      render={(props) => (
        <BookingTable
          t={t}
          onSearch={onSearch}
          setTab={(tab) => setTab(tab.key)}
          style={{ width: "100%" }}
          handleTableChange={getBookings}
          setSearchBy={(value) => setSearchBy(value)}
          setSearchText={(value) => setSearchText(value)}
          visibleModalAppointment={visibleModalAppointment}
          setVisibleModalAppointment={setVisibleModalAppointment}
          currentDateTimeAppointment={currentDateTimeAppointment}
          setCurrentDateTimeAppointment={setCurrentDateTimeAppointment}
          periods={periods}
          times={times}
          isInitialAppointmentLoading={isInitialAppointmentLoading}
          handleChangeNewAppointment={handleChangeNewAppointment}
          handleConfirmNewAppointment={handleConfirmNewAppointment}
          visibleModalConfirmAppointment={visibleModalConfirmAppointment}
          setVisibleModalConfirmAppointment={setVisibleModalConfirmAppointment}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          newDateTimeAppointment={newDateTimeAppointment}
          setNewDateTimeAppointment={setNewDateTimeAppointment}
          setCurrentBooking={setCurrentBooking}
          {...props}
        />
      )}
    />
  );
};
export default BookingFromPatient;
