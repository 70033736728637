import React from "react";
import { Modal, Image, Button, Typography } from "antd";
import { FlexCenter } from "./styles";

const { Text } = Typography;

const Payment = (props) => {
  const { visible, setVisible, payment, t, handleConfirmPayment } = props;
  const isPaid = payment.data.length > 0 && payment.data[0].bankReceiptUrl;
  return (
    <Modal
      title="Payment"
      closable={false}
      visible={visible}
      footer={
        <Button type="primary" onClick={handleConfirmPayment}>
          {t("confirm")}
        </Button>
      }
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
    >
      <Text>{t("please_check_the_attached_image_before_confirm")}</Text>
      <FlexCenter>
        {isPaid ? (
          <Image src={payment.data[0].bankReceiptUrl} height={300} />
        ) : null}
      </FlexCenter>
    </Modal>
  );
};

export default Payment;
