import styled from "styled-components";
import TextBase from "../../Text";
import {
  Select as SelectAnt,
  Table as TableAnt,
  Button as ButtonAnt,
  Input as InputAnt,
} from "antd";
const { Option } = SelectAnt;

const Text = styled(TextBase)`
  color: ${(props) => (props.primary ? "#2BA796" : props.color)};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

const Table = styled(TableAnt)`
  .ant-table-content {
    border: 1px solid lightgrey;
    border-radius: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
`;

const Select = styled(SelectAnt)`
  &&.ant-select {
    .ant-select-selector {
      border-radius: 10px;
    }
  }
`;

const Input = styled(InputAnt)`
  && {
    border-radius: 10px;
  }
`;

const ButtonRefer = styled(ButtonAnt)`
  && {
    border-radius: 24px;
    background: white;
    padding: 5px 20px;
  }
`;

export { Text, Option, Select, Table, ButtonRefer, Input };
