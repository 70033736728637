import { call, put, takeEvery } from "redux-saga/effects";
import { ClinicPackages } from "../../api";
import * as type from "../types";

function* getClinicPackagesBySpecialties({ filter }) {
  try {
    const result = yield call(ClinicPackages.getClinicPackagesByFilter, filter);
    yield put({
      type: type.GET_CLINIC_PACKAGE_BY_SPECIALTIES_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_CLINIC_PACKAGE_BY_SPECIALTIES_FAILED,
      error: e.message,
    });
  }
}

function* clinicPackageSaga() {
  yield takeEvery(
    type.GET_CLINIC_PACKAGE_BY_SPECIALTIES_REQUESTED,
    getClinicPackagesBySpecialties
  );
}

export default clinicPackageSaga;
