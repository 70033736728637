import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/practitionerSpecialties";

async function getpractitonerSpecialty({ roleId }) {
  const { data } = await http.get(
    `${apiEndpoint}?filter[where][roleId]=${roleId}`
  );
  return data;
}

async function getAllpractitonerSpecialty() {
  const { data } = await http.get(apiEndpoint);
  return data;
}

export { getpractitonerSpecialty, getAllpractitonerSpecialty };
