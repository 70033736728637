import {
  Modal,
  Table,
  InputNumber,
  Input,
  Select,
  Button,
  Row,
  Col,
} from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { DRUG_ROUTES } from "./constant";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import Loading from "../../Loading";
import { formatter } from "../../../common/format";

const { Option } = Select;

const PrescriptionList = (props) => {
  const {
    visible,
    setVisible,
    handleSelectMedicine,
    onSearch,
    medication,
    prescription,
    medicines,
    t,
    updatePrescriptionItem,
    setMedicines,
    deleteDrugFromPrescription,
    drugUnits,
    drugTimeEvent,
    drugTimeFrequency,
    getDrugTimeEventInfo,
    getDrugRouteInfo,
    getDrugUnitInfo,
    getDrugTimeFrequencyInfo,
  } = props;
  const column_medication = [
    {
      render: (text, record, index) => (
        <DeleteFilled onClick={() => deleteDrugFromPrescription(record.id)} />
      ),
      width: "50px",
    },
    {
      title: t("generic_name"),
      dataIndex: ["_medicationForOrg", "activeIngredient"],
      width: "220px",
      align: "left",
    },
    {
      title: t("trade_name"),
      dataIndex: ["_medicationForOrg", "tradeName"],
      width: "220px",
      align: "left",
    },
    {
      title: t("dose"),
      dataIndex: ["dose"],
      width: "100px",
      align: "center",
      render: (text, record, index) => (
        <InputNumber
          min={1}
          defaultValue={text}
          onChange={(dose) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].dose = dose;
              updatePrescriptionItem(
                newMedicines[index].id,
                newMedicines[index]
              );
              return newMedicines;
            })
          }
          className="w-100"
        />
      ),
    },
    {
      title: t("unit"),
      key: "drugUnit",
      width: "150px",
      dataIndex: ["_drugUnit", "_id"],
      align: "center",
      render: (text, record, index) => (
        <Select
          showSearch
          clearIcon
          defaultValue={text ?? 1}
          optionFilterProp="children"
          onChange={(drugUnitId) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index]._drugUnit = getDrugUnitInfo(drugUnitId);
              updatePrescriptionItem(
                newMedicines[index].id,
                newMedicines[index]
              );
              return newMedicines;
            })
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className="w-100"
        >
          {drugUnits.map((item) => (
            <Option value={item.id} key={item.id}>
              <div>
                <p className="mb-0 pb-0">{item.name}</p>
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("drug_routes"),
      key: "drugRoute",
      dataIndex: ["_drugRoute", "_id"],
      align: "center",
      width: "200px",
      render: (text, record, index) => (
        <Select
          showSearch
          clearIcon
          defaultValue={text ?? 1}
          optionFilterProp="children"
          onChange={(drugRouteId) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              const drugRouteInfo = getDrugRouteInfo(drugRouteId, DRUG_ROUTES);
              newMedicines[index]._drugRoute = drugRouteInfo;
              updatePrescriptionItem(
                newMedicines[index].id,
                newMedicines[index]
              );
              return newMedicines;
            })
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className="w-100"
        >
          {DRUG_ROUTES.map((item) => (
            <Option value={item.id} key={item.id}>
              <div>
                <p className="mb-0 pb-0">{item.routeTh}</p>
                <small>{item.routeEn}</small>
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("drug_time_events"),
      key: "drugTimeEvents",
      dataIndex: ["_drugTimeEvent", "code"],
      align: "center",
      width: "250px",
      render: (text, record, index) => (
        <Select
          showSearch
          value={text ?? "AC"}
          clearIcon
          optionFilterProp="children"
          onChange={(drugTime) => {
            setMedicines((prev) => {
              const newMedicines = [...prev];
              const drugTimeInfo = getDrugTimeEventInfo(drugTime);
              newMedicines[index]._drugTimeEvent = drugTimeInfo;
              updatePrescriptionItem(
                newMedicines[index].id,
                newMedicines[index]
              );
              return newMedicines;
            });
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className="w-100"
        >
          {drugTimeEvent.map((item) => (
            <Option value={item.code} key={item.id}>
              <div>
                <p className="mb-0 pb-0">{item.nameTh}</p>
                <small>{item.code}</small>
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("drug_time_frequency"),
      dataIndex: ["_drugFrequency", "code"],
      index: "drugTimeFrequency",
      align: "center",
      width: "250px",
      render: (text, record, index) => (
        <Select
          showSearch
          value={text ?? "A"}
          clearIcon
          optionFilterProp="children"
          onChange={(drugTime) => {
            setMedicines((prev) => {
              const newMedicines = [...prev];
              const drugTimeInfo = getDrugTimeFrequencyInfo(drugTime);
              newMedicines[index]._drugFrequency = drugTimeInfo;
              updatePrescriptionItem(
                newMedicines[index].id,
                newMedicines[index]
              );
              return newMedicines;
            });
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          className="w-100"
        >
          {drugTimeFrequency.map((item) => (
            <Option value={item.code} key={item.id}>
              <div>
                <p className="mb-0 pb-0">{item.nameTh}</p>
                <small>{item.code}</small>
              </div>
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("total_amount"),
      dataIndex: ["amount"],
      align: "center",
      width: "150px",
      render: (text, record, index) => (
        <InputNumber
          min={1}
          value={text ?? 1}
          onChange={(amount) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].amount = amount;
              updatePrescriptionItem(
                newMedicines[index].id,
                newMedicines[index]
              );
              return newMedicines;
            })
          }
          className="w-100"
        />
      ),
    },
    {
      title: t("dispense_unit"),
      dataIndex: ["_medicationForOrg", "unitType"],
      align: "center",
      width: "150px",
    },
    {
      title: t("price_per_dispense_unit"),
      align: "center",
      dataIndex: ["_medicationForOrg", "priceCents"],
      width: "150px",
      render: (text) => formatter.format(parseInt(text) / 100),
    },
    {
      title: t("note"),
      dataIndex: ["note"],
      align: "center",
      width: "200px",
      render: (text, record, index) => (
        <Input
          placeholder="Note"
          value={text}
          onChange={(e) =>
            setMedicines((prev) => {
              const newMedicines = [...prev];
              newMedicines[index].note = e.target.value;
              updatePrescriptionItem(
                newMedicines[index].id,
                newMedicines[index]
              );
              return newMedicines;
            })
          }
        />
      ),
    },
    {
      title: t("total"),
      key: "total",
      align: "center",
      width: "200px",
      render: (text, record, index) =>
        formatter.format(
          (medicines[index].amount *
            parseInt(record._medicationForOrg.priceCents)) /
            100
        ),
    },
  ];
  return (
    <Modal
      visible={visible}
      title={t("prescription")}
      width={1200}
      footer={
        <Button type="primary" onClick={() => setVisible(false)}>
          {t("done")}
        </Button>
      }
      onCancel={() => setVisible(false)}
    >
      <Select
        showSearch
        style={{ width: 500 }}
        onChange={handleSelectMedicine}
        value=""
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        dropdownRender={(menu) =>
          medication.loading ? (
            <div style={{ padding: 5, textAlign: "center" }}>
              <Loading />
            </div>
          ) : (
            <>{menu}</>
          )
        }
      >
        {medication.data.map((item) => {
          const genericName = item.activeIngredient;
          const tradeName =
            item.medication && item.medication.tradeName
              ? item.medication.tradeName
              : "";
          const name = `${genericName} (${tradeName})`;
          return (
            <Option value={item.id} key={item.id}>
              {name}
            </Option>
          );
        })}
      </Select>
      <Table
        dataSource={medicines}
        columns={column_medication}
        loading={prescription.loading}
        footer={() => {
          const total = medicines.reduce(
            (acc, curr) =>
              acc + (curr._medicationForOrg.priceCents / 100) * curr.amount,
            0
          );
          return (
            <Row justify="space-between">
              <Col>{t("total_price")}</Col>
              <Col>{formatter.format(total)}</Col>
            </Row>
          );
        }}
        className="my-3"
        style={{
          maxHeight: "400px",
          border: "1px solid #ccc",
        }}
        scroll={{ x: "max-content", y: "300px" }}
        pagination={false}
        locale={{
          emptyText: (
            <div className="d-flex align-items-center justify-content-center flex-column text-center h-100 mt-5">
              <FaPrescriptionBottleAlt
                style={{ fontSize: "36px", color: "#757575" }}
              />
              <p className="mt-2 mb-5 text-dark">{t("no_prescription")}</p>
            </div>
          ),
        }}
      />
    </Modal>
  );
};

export default PrescriptionList;
