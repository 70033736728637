import { Tag, Button, Modal, Image, Select } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "../Table";
import Action from "./action";
import { UserOma, Booking } from "../../redux/actions";
import { STATUS, TELEMONITORING_REDIRECT, LOGISTIC_STATUSES } from "./constant";
import { LineChartOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { ADD_MEDICATION_BOOKING_SUCCESS } from "../../redux/types";

const { Option } = Select;

const table = ({
  dataSource,
  history,
  setVisibleHistory,
  isHistoryBooking,
  isActive,
  title,
  handleTableChange,
  t,
  handleOpenModalAppointment,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [filterDataSource, setFilterDataSource] = useState(false);
  const [visibleInitialSymptom, setVisibleInitialSymptom] = useState(false);
  const [symptomNote, setSymptomNote] = useState(false);
  const [visibleNote, setVisibleNote] = useState(false);
  const [note, setNote] = useState(false);
  const [medications, setMedications] = useState([]);
  const [visibleMedication, setVisibleMedication] = useState(false);
  const booking = useSelector((state) => state.booking);
  const userOma = useSelector((state) => state.appUserOma);
  const hieData = useSelector((state) => state.userOma);
  const role =
    userOma && userOma.information && userOma.information.roles[0].name;
  const userId = userOma.information && userOma.information.id;
  const { data, loading } = booking;

  useEffect(() => {
    clearOldUserOmaData();
  }, []);

  const clearOldUserOmaData = () => {
    dispatch(UserOma.clearUserOma());
  };
  const checkAddress = (address, addressTypeId) => {
    if (!address) return "-";
    const addressInfo = address.find(
      (item) => item.addressTypeId == addressTypeId
    );
    return addressInfo;
  };

  const handleChange = (id, value) => {
    dispatch(Booking.updateBooking(id, true, { logisticStatus: value }));
  };

  const setColor = (status) => {
    if (status.includes("PENDING")) {
      return "orange";
    }
    if (
      status.includes("CONFIRM") ||
      status.includes("PAID") ||
      status.includes("COMPLETED") ||
      status.includes("SUCCESS")
    ) {
      return "green";
    }
    if (
      status.includes("DECLINE") ||
      status.includes("CANCEL") ||
      status.includes("ALERT")
    ) {
      return "red";
    }
    return "blue";
  };

  const NOTE_COLUMN = {
    title: t("note"),
    key: "note",
    width: 100,
    dataIndex: ["notes"],
    render: (text) =>
      text && (
        <Button
          onClick={() => {
            setVisibleNote(true);
            setNote(text);
          }}
        >
          Note
        </Button>
      ),
  };

  const INITIAL_SYMPTOM_COLUMN = {
    title: t("initial_symtoms"),
    key: "initialSymtoms",
    width: 150,
    render: (text) =>
      text.symptom && (
        <Button
          onClick={() => {
            setVisibleInitialSymptom(true);
            setSymptomNote(text.symptom);
          }}
        >
          {t("initial_symtoms")}
        </Button>
      ),
  };

  const COLUMNS = [
    {
      title: t("date_time"),
      key: "dateTime",
      width: 150,
      align: "center",
      fixed: "left",
      render: (record) =>
        moment(record.admitTime).format("DD/MM/YYYY, HH:mm:ss"),
      sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
      defaultSortOrder: "descend",
    },
    {
      key: "dateTimeAction",
      width: 100,
      align: "center",
      render: (record) => {
        return record.bookingCategory === "scheduling" ? (
          <Button onClick={() => handleOpenModalAppointment(record)}>
            {t("change")}
          </Button>
        ) : null;
      },
    },
    {
      title: t("full_name"),
      width: 250,
      align: "center",
      render: (record) => {
        const userInfo =
          record.patient && record.patient.userInformation
            ? record.patient.userInformation
            : null;
        return userInfo ? `${userInfo.firstname} ${userInfo.lastname}` : "-";
      },
    },
    {
      title: t("mobile_number"),
      width: 150,
      align: "center",
      render: (record) => {
        const userInfo =
          record.patient && record.patient.userInformation
            ? record.patient.userInformation
            : null;
        return userInfo ? userInfo.mobileNumber : "-";
      },
      
      // dataIndex: "bookingCategory",
      // filters: [
      //   {
      //     text: "telemed",
      //     value: "telemed",
      //   },
      //   {
      //     text: "scheduling",
      //     value: "scheduling",
      //   },
      //   {
      //     text: "general",
      //     value: "general",
      //   },
      // ],
      // filterSearch: true,
    },
    
    {
      title: t("consult_with"),
      key: "consultWith",
  
      align: "center",
      render: (record) => {
        const practitionerInfo =
        record.practitionerAppUser && record.practitionerAppUser.practitioner
          ? record.practitionerAppUser.practitioner
          : null;
      return practitionerInfo ? `${practitionerInfo.firstName} ${practitionerInfo.lastName}` : "-";
      },
    },
    {
      title: t("Department"),
      width: 200,
      align: "center",
      render: (text, record) => {
        const departmentInfo =
          record.practitionerAppUser && record.practitionerAppUser.practitioner.practitionerSpecialties
            ? record.practitionerAppUser.practitioner.practitionerSpecialties
            : null;
  
        const departmentNames = departmentInfo
          ? departmentInfo.map((info) => info.nameTh)
          : ["-"];
  
        return (
          <div>
            {departmentNames.map((nameTh, index) => (
              <div key={index}>{nameTh}</div>
            ))}
          </div>
        );
      },
    },
    isHistoryBooking ? { ...NOTE_COLUMN } : { ...INITIAL_SYMPTOM_COLUMN },
  ];

  const COLUMNS_WITH_STATUS = [
    {
      title: t("status"),
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (text) => (
        <Tag color={setColor(text)}>{t(`${STATUS[role][text]}`)}</Tag>
      ),
    },
  ];

  const COLUMNS_WITH_ADDRESS = [
    {
      title: t("address"),
      key: "address",
      width: 300,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return `เลขที่ ${address.no || "-"} หมู่บ้าน ${
              address.village || "-"
            } ซอย ${address.soi || "-"} ${address.road || "ถนน -"}`;
          }
        }
        return "-";
      },
    },
    {
      title: t("sub_district"),
      key: "tambon",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.subDistrict;
          }
        }
        return "-";
      },
    },
    {
      title: t("district"),
      key: "amphur",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.district;
          }
        }
        return "-";
      },
    },
    {
      title: t("province"),
      key: "province",
      width: 120,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.province;
          }
        }
        return "-";
      },
    },
    {
      title: t("postal_code"),
      key: "zipcode",
      width: 100,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return address.postalCode;
          }
        }
        return "-";
      },
    },
  ];

  const COLUMNS_WITH_ACTION = [
    {
      title: t("action"),
      key: "action",
      width: 190,
      fixed: "right",
      align: "center",
      render: (record) => (
        <Action
          t={t}
          status={record.status}
          id={record.id}
          patientId={record.patientId}
          bookingAdmitTime={record.admitTime}
          title={title}
          booking={record}
          treatmentId={record.treatmentId}
          history={history}
        />
      ),
    },
  ];

  const COLUMNS_WITH_TELEMONITORING = [
    {
      title: t("health_record"),
      key: "telemonitoring",
      width: 150,
      align: "center",
      render: (text) =>
        text.patient && text.patient.id ? (
          <Button
            icon={<LineChartOutlined />}
            onClick={() => {
              history.push({
                pathname: TELEMONITORING_REDIRECT.pathName(text.patient.id),
              });
            }}
          >
            {t("health_record")}
          </Button>
        ) : (
          "-"
        ),
    },
  ];

  const COLUMNS_WITH_HISTORY_BOOKING = [
    {
      title: t("history_booking"),
      dataIndex: "patientId",
      key: "history",
      width: 200,
      align: "center",
      render: (text, data) => (
        <Button
          onClick={() => {
            dispatch(Booking.getBookingHistory(userId, text, data.status));
            setVisibleHistory(true);
          }}
        >
          {t("history")}
        </Button>
      ),
    },
  ];

  const COLUMNS_WITH_LOGISTIC_STATUS = [
    {
      title: t("logistic_status"),
      dataIndex: "logisticStatus",
      key: "logisticStatus",
      width: 200,
      align: "center",
      render: (text, record) => (
        <Select
          style={{ width: "100%" }}
          value={text || ""}
          onChange={(value) => handleChange(record.id, value)}
        >
          {LOGISTIC_STATUSES.map((status) => (
            <Option value={status.value}>{t(`${status.label}`)}</Option>
          ))}
        </Select>
      ),
    },
  ];

  const COLUMNS_WITH_GOOGLE_MAP = [
    {
      title: t("google_map"),
      dataIndex: "addressLocation",
      width: 120,
      align: "center",
      render: (text, record) =>
        text && (
          <Button
            onClick={() =>
              window.open(
                `http://maps.google.com/maps?q=${text.latitude},${text.longitude}`
              )
            }
          >
            {t("map")}
          </Button>
        ),
    },
  ];

  const COLUMNS_WITH_MEDICATION = [
    {
      title: t("prescription"),
      dataIndex: "prescription",
      key: "medications",
      width: 200,
      align: "center",
      render: (text) =>
        text &&
        text.length > 0 && (
          <Button
            onClick={() => {
              setMedications(text);
              setVisibleMedication(true);
            }}
          >
            {t("prescription")}
          </Button>
        ),
    },
  ];

  const FILTER_COLUMNS = ["doctor", "pharmacy"].includes(role)
    ? [
        ...COLUMNS,
        ...COLUMNS_WITH_ADDRESS,
        ...COLUMNS_WITH_HISTORY_BOOKING,
        ...COLUMNS_WITH_TELEMONITORING,
        ...COLUMNS_WITH_MEDICATION,
        ...COLUMNS_WITH_STATUS,
        ...COLUMNS_WITH_ACTION,
      ]
    : ["callCenter"].includes(role)
    ? [
        ...COLUMNS,
        ...COLUMNS_WITH_ADDRESS,
        ...COLUMNS_WITH_STATUS,
        ...COLUMNS_WITH_ACTION,
      ]
    : ["deliveryTeam"].includes(role)
    ? [
        ...COLUMNS,
        ...COLUMNS_WITH_GOOGLE_MAP,
        ...COLUMNS_WITH_ADDRESS,
        ...COLUMNS_WITH_STATUS,
        ...COLUMNS_WITH_LOGISTIC_STATUS,
      ]
    : COLUMNS;

  return (
    <div style={{ width: "100%" }}>
      <Table
        loading={loading}
        dataSource={filterDataSource || dataSource || data}
        columns={
          isActive
            ? FILTER_COLUMNS
            : [
                ...COLUMNS,
                ...COLUMNS_WITH_ADDRESS,
                ...COLUMNS_WITH_TELEMONITORING,
                ...COLUMNS_WITH_MEDICATION,
                ...COLUMNS_WITH_STATUS,
              ]
        }
        scroll={{ x: "max-content", y: "45vh" }}
        pagination={
          isHistoryBooking
            ? false
            : {
                current: booking && booking.meta ? booking.meta.current : 1,
                pageSize:
                  booking && booking.meta ? booking.meta.itemsPerPage : 10,
                total:
                  booking && booking.meta ? booking.meta.totalItemCount : 10,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30", "50", "100"],
              }
        }
        onChange={handleTableChange}
      />
      <Modal
        title={t("data_from_hie")}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        {hieData.loading ? (
          t("loading")
        ) : (
          <div>
            {hieData.hie && hieData.hie.drugAllergies.length ? (
              <div>
                {hieData.hie.drugAllergies.map((drug, index) => (
                  <div>
                    {index === 0 && <div>{t("drug_allergy")}</div>}
                    <div>
                      {index + 1}: {drug.genericname}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              t("no_data_from_prescription")
            )}
            <div>
              {t("congenital_disease")}:{" "}
              {hieData.hie &&
                hieData.hie.condition &&
                hieData.hie.condition.ccPersistDisease}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title={t("initial_symptom")}
        visible={visibleInitialSymptom}
        footer={null}
        onOk={() => setVisibleInitialSymptom(false)}
        onCancel={() => setVisibleInitialSymptom(false)}
      >
        <div>
          {symptomNote.imageUrl && <Image src={symptomNote.imageUrl} />}
        </div>
        <div>
          <h3 className="font-weight-bold">{t("description")}:</h3>
          {symptomNote.note && <div>{symptomNote.note}</div>}
        </div>
      </Modal>
      <Modal
        title={t("note")}
        visible={visibleNote}
        footer={null}
        onOk={() => setVisibleNote(false)}
        onCancel={() => setVisibleNote(false)}
      >
        <div>{note && <div>{note}</div>}</div>
      </Modal>
      <Modal
        title={t("prescription")}
        visible={visibleMedication}
        footer={null}
        onOk={() => setVisibleMedication(false)}
        onCancel={() => setVisibleMedication(false)}
      >
        <div>
          {medications.map((medication, index) => (
            <div>
              <div>
                {index + 1}. {t("ingredient")}: {medication.activeIngredient}
              </div>
              <div>
                {t("trade_name")}: {medication.tradeName}
              </div>
              <div>
                {t("dosage")}: {medication.dosageForm}
              </div>
              <div>
                {t("strength")}: {medication.strength}
              </div>
              <div>
                {t("amount")}: {medication.amount}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};
export default table;
