export const DRUG_ROUTES = [
  {
    topicalRoute: "54471007",
    routeEn: "Buccal use",
    routeTh: "กระพุ้งแก้ม",
    id: 1,
    createdAt: "2022-01-17 07:26:01.02+00",
    updatedAt: "2022-01-17 07:26:01.02+00",
  },
  {
    topicalRoute: "78421000",
    routeEn: "Intramuscular use",
    routeTh: "เข้ากล้ามเนื้อ",
    id: 2,
    createdAt: "2022-01-17 07:26:01.03+00",
    updatedAt: "2022-01-17 07:26:01.03+00",
  },
  {
    topicalRoute: "372464004",
    routeEn: "Intradermal use",
    routeTh: "เข้าชั้นผิวหนัง",
    id: 3,
    createdAt: "2022-01-17 07:26:01.034+00",
    updatedAt: "2022-01-17 07:26:01.034+00",
  },
  {
    topicalRoute: "448077001",
    routeEn: "Intraepidermal route",
    routeTh: "เข้าชั้นหนังกำพร้า",
    id: 4,
    createdAt: "2022-01-17 07:26:01.038+00",
    updatedAt: "2022-01-17 07:26:01.038+00",
  },
  {
    topicalRoute: "46713006",
    routeEn: "Nasal use",
    routeTh: "จมูก",
    id: 5,
    createdAt: "2022-01-17 07:26:01.046+00",
    updatedAt: "2022-01-17 07:26:01.046+00",
  },
  {
    topicalRoute: "372473007",
    routeEn: "Oromucosal use",
    routeTh: "ในปาก",
    id: 6,
    createdAt: "2022-01-17 07:26:01.051+00",
    updatedAt: "2022-01-17 07:26:01.051+00",
  },
  {
    topicalRoute: "54485002",
    routeEn: "Ophthalmic use",
    routeTh: "ตา",
    id: 7,
    createdAt: "2022-01-17 07:26:01.054+00",
    updatedAt: "2022-01-17 07:26:01.055+00",
  },
  {
    topicalRoute: "34206005",
    routeEn: "SC use",
    routeTh: "ใต้ผิวหนัง",
    id: 8,
    createdAt: "2022-01-17 07:26:01.058+00",
    updatedAt: "2022-01-17 07:26:01.058+00",
  },
  {
    topicalRoute: "90028008",
    routeEn: "Urethral use",
    routeTh: "ท่อปัสสาวะ",
    id: 9,
    createdAt: "2022-01-17 07:26:01.062+00",
    updatedAt: "2022-01-17 07:26:01.062+00",
  },
  {
    topicalRoute: "16857009",
    routeEn: "Vaginal use",
    routeTh: "ทางช่องคลอด",
    id: 10,
    createdAt: "2022-01-17 07:26:01.066+00",
    updatedAt: "2022-01-17 07:26:01.066+00",
  },
  {
    topicalRoute: "447694001",
    routeEn: "Respiratory tract route",
    routeTh: "ทางเดินหายใจ",
    id: 11,
    createdAt: "2022-01-17 07:26:01.069+00",
    updatedAt: "2022-01-17 07:26:01.069+00",
  },
  {
    topicalRoute: "447081004",
    routeEn: "Lower respiratory tract route",
    routeTh: "ทางเดินหายใจส่วนล่าง",
    id: 12,
    createdAt: "2022-01-17 07:26:01.073+00",
    updatedAt: "2022-01-17 07:26:01.073+00",
  },
  {
    topicalRoute: "447964005",
    routeEn: "Digestive tract route",
    routeTh: "ทางเดินอาหาร",
    id: 13,
    createdAt: "2022-01-17 07:26:01.077+00",
    updatedAt: "2022-01-17 07:26:01.077+00",
  },
  {
    topicalRoute: "37161004",
    routeEn: "Rectal use",
    routeTh: "ทางทวารหนัก",
    id: 14,
    createdAt: "2022-01-17 07:26:01.081+00",
    updatedAt: "2022-01-17 07:26:01.081+00",
  },
  {
    topicalRoute: "372452007",
    routeEn: "Endotracheopulmonary use",
    routeTh: "ทางท่อช่วยหายใจ",
    id: 15,
    createdAt: "2022-01-17 07:26:01.086+00",
    updatedAt: "2022-01-17 07:26:01.086+00",
  },
  {
    topicalRoute: "448598008",
    routeEn: "Cutaneous route",
    routeTh: "ทางผิวหนัง",
    id: 16,
    createdAt: "2022-01-17 07:26:01.09+00",
    updatedAt: "2022-01-17 07:26:01.09+00",
  },
  {
    topicalRoute: "418813001",
    routeEn: "Surgical drain route",
    routeTh: "ทางสายระบาย",
    id: 17,
    createdAt: "2022-01-17 07:26:01.094+00",
    updatedAt: "2022-01-17 07:26:01.094+00",
  },
  {
    topicalRoute: "127492001",
    routeEn: "Nasogastric use",
    routeTh: "ทางสายอาหาร",
    id: 18,
    createdAt: "2022-01-17 07:26:01.1+00",
    updatedAt: "2022-01-17 07:26:01.1+00",
  },
  {
    topicalRoute: "47625008",
    routeEn: "Intravenous use",
    routeTh: "ทางหลอดเลือดดำ",
    id: 19,
    createdAt: "2022-01-17 07:26:01.104+00",
    updatedAt: "2022-01-17 07:26:01.105+00",
  },
  {
    topicalRoute: "419993007",
    routeEn: "Intravenous peripheral route",
    routeTh: "ทางหลอดเลือดดำส่วนปลาย",
    id: 20,
    createdAt: "2022-01-17 07:26:01.108+00",
    updatedAt: "2022-01-17 07:26:01.108+00",
  },
  {
    topicalRoute: "372466002",
    routeEn: "Intralesional use",
    routeTh: "บริเวณที่เป็น",
    id: 21,
    createdAt: "2022-01-17 07:26:01.113+00",
    updatedAt: "2022-01-17 07:26:01.113+00",
  },
  {
    topicalRoute: "45890007",
    routeEn: "Transdermal use",
    routeTh: "แปะผิวหนัง",
    id: 22,
    createdAt: "2022-01-17 07:26:01.119+00",
    updatedAt: "2022-01-17 07:26:01.119+00",
  },
  {
    topicalRoute: "428191002",
    routeEn: "Percutaneous route",
    routeTh: "ผ่านชั้นผิวหนัง",
    id: 23,
    createdAt: "2022-01-17 07:26:01.124+00",
    updatedAt: "2022-01-17 07:26:01.124+00",
  },
  {
    topicalRoute: "372449004",
    routeEn: "Dental use",
    routeTh: "ฟัน",
    id: 24,
    createdAt: "2022-01-17 07:26:01.129+00",
    updatedAt: "2022-01-17 07:26:01.129+00",
  },
  {
    topicalRoute: "26643006",
    routeEn: "Oral use",
    routeTh: "รับประทาน",
    id: 25,
    createdAt: "2022-01-17 07:26:01.133+00",
    updatedAt: "2022-01-17 07:26:01.133+00",
  },
  {
    topicalRoute: "10547007",
    routeEn: "Auricular use",
    routeTh: "หู",
    id: 26,
    createdAt: "2022-01-17 07:26:01.137+00",
    updatedAt: "2022-01-17 07:26:01.137+00",
  },
  {
    topicalRoute: "372457001",
    routeEn: "Gingival use",
    routeTh: "เหงือก",
    id: 27,
    createdAt: "2022-01-17 07:26:01.141+00",
    updatedAt: "2022-01-17 07:26:01.141+00",
  },
  {
    topicalRoute: "37839007",
    routeEn: "Sublingual use",
    routeTh: "อมใต้ลิ้น",
    id: 28,
    createdAt: "2022-01-17 07:26:01.147+00",
    updatedAt: "2022-01-17 07:26:01.147+00",
  },
];
