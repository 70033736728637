import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  data: null,
  timeSlots: {
    data: {},
    isLoading: false,
    error: null,
    isSuccess: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PRACTITIONERS_BY_SPECIALTIES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_PRACTITIONERS_BY_SPECIALTIES_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case type.GET_PRACTITIONERS_BY_SPECIALTIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case type.GET_TIME_SLOTS_BY_PRACTITIONER_ID_REQUESTED:
      return {
        ...state,
        timeSlots: {
          isLoading: true,
          data: {},
          error: null,
          isSuccess: false,
        },
      };
    case type.GET_TIME_SLOTS_BY_PRACTITIONER_ID_SUCCESS:
      return {
        ...state,
        timeSlots: {
          isLoading: false,
          data: action.data,
          error: null,
          isSuccess: true,
        },
      };
    case type.GET_TIME_SLOTS_BY_PRACTITIONER_ID_FAILED:
      return {
        ...state,
        timeSlots: {
          isLoading: false,
          data: {},
          error: action.error,
          isSuccess: false,
        },
      };
    default:
      return state;
  }
};
