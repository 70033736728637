import { call, put, takeEvery } from "redux-saga/effects";
import { Clinic } from "../../api";
import * as type from "../types";

function* getClinicBySpecialties({ filter }) {
  try {
    const result = yield call(Clinic.getClinicListBySpecialty, filter);
    yield put({
      type: type.GET_CLINIC_BY_SPECIALTIES_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_CLINIC_BY_SPECIALTIES_FAILED,
      error: e.message,
    });
  }
}

function* clinicSaga() {
  yield takeEvery(
    type.GET_CLINIC_BY_SPECIALTIES_REQUESTED,
    getClinicBySpecialties
  );
}

export default clinicSaga;
