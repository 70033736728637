import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/organizations";

async function getOrganizations(filter) {
  const { data } = await http.get(`${apiEndpoint}${filter}`);
  return data;
}

async function getUsersByOrganization(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/users`);
  return data;
}

async function getCommunityPharmaciesByOrganization(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/communityPhamacies`);
  return data;
}

async function getOrganizationSubmissions(listOrgId = []) {
  const listReq = listOrgId.map(async (item) => {
    const { data } = await http.get(`${apiEndpoint}/${item.id}/submissions`);
    return data;
  });

  const result = await Promise.all(listReq);
  return result;
}

async function getResearchObjectsByOrganization(
  id,
  page,
  limit = 10,
  statusList = []
) {
  const filter = JSON.stringify({
    where: { status: { inq: statusList } },
  });
  const { data } = await http.get(
    `${apiEndpoint}/${id}/researchObjects?&filter=${filter}`
  );

  return data;
}

async function getResearchProgramsByOrganization(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/researchPrograms`);
  return data;
}

async function getRecruitmentObjectsByOrgAndProgram(
  id,
  programId,
  statusList = []
) {
  const filter = JSON.stringify({
    where: {
      and: [{ researchProgramId: programId }, { status: { inq: statusList } }],
    },
  });
  const { data } = await http.get(
    `${apiEndpoint}/${id}/recruitmentObjects?filter=${filter}`
  );
  return data;
}

async function getResearchObjectsByOrgAndProgram(
  id,
  programId,
  statusList = []
) {
  const filter = JSON.stringify({
    where: {
      and: [{ researchProgramId: programId }, { status: { inq: statusList } }],
    },
  });
  const { data } = await http.get(
    `${apiEndpoint}/${id}/researchObjects?filter=${filter}`
  );
  return data;
}

async function getQuestionnaireByOrganization() {}

async function getUsersByOrganizationList(listOrgId = []) {
  const listReq = listOrgId.map(async (item) => {
    const { data } = await http.get(`${apiEndpoint}/${item.id}/users`);
    return data;
  });

  const result = await Promise.all(listReq);
  return result;
}

export {
  getOrganizations,
  getOrganizationSubmissions,
  getUsersByOrganization,
  getUsersByOrganizationList,
  getCommunityPharmaciesByOrganization,
  getResearchObjectsByOrganization,
  getResearchProgramsByOrganization,
  getRecruitmentObjectsByOrgAndProgram,
  getResearchObjectsByOrgAndProgram,
};
