import {
  Container,
  Text,
  Input,
  Icon,
  SpaceItemFlex,
  ButtonRounded,
  DatePicker,
  DropDownWidget,
  Dragger,
} from "../styles";
import { Row, Col, Space, Radio, Descriptions, Typography } from "antd";
import { AiFillPlusCircle, FaTimes, IoMdCloudUpload } from "react-icons/all";
import { VACCINES_NAME } from "../constant";
import moment from "moment";

const disabledDate = (current) => {
  return current && current > moment().endOf("day");
};

const VaccinesInfectionHistory = ({
  t,
  form,
  currentRecord,
  customRequest,
  getNameFromUrl,
  disabled,
}) => {
  const { Link } = Typography;
  // NOTE disabled
  if (disabled) {
    const { vaccinneHistory = [], covidInfectionHistory = null } = form;
    const finalVaccines = vaccinneHistory.filter(
      (item) => item.vaccineName !== ""
    );
    return (
      <Container>
        {finalVaccines.map((item, index) => {
          return (
            <Descriptions
              bordered
              labelStyle={{ fontWeight: "bold" }}
              size="small"
              column={1}
            >
              <Descriptions.Item label={`${t("dose_at")} ${index + 1}`}>
                <Row>
                  <Text bold>{t("name")} : </Text>
                  <Text className="ml-1" style={{ fontWeight: 300 }}>
                    {item.vaccineName}
                  </Text>
                </Row>
                <Row>
                  <Text bold>{t("date_injected")} : </Text>
                  <Text className="ml-1" style={{ fontWeight: 300 }}>
                    {moment(item.date).format("DD/MM/YYYY")}
                  </Text>
                </Row>
                <Row>
                  <Text bold>{t("clinic_site_injected")} : </Text>
                  <Text className="ml-1" style={{ fontWeight: 300 }}>
                    {item.site}
                  </Text>
                </Row>
              </Descriptions.Item>
            </Descriptions>
          );
        })}
        {covidInfectionHistory.covidInfectionHistoryUrl && (
          <Descriptions
            bordered
            labelStyle={{ fontWeight: "bold" }}
            size="small"
            column={1}
            layout="vertical"
            style={{ marginTop: 20 }}
          >
            <Descriptions.Item label={t("document_list")}>
              <Link
                href={covidInfectionHistory.covidInfectionHistoryUrl}
                target="_blank"
              >
                {getNameFromUrl(covidInfectionHistory.covidInfectionHistoryUrl)}
              </Link>
            </Descriptions.Item>
          </Descriptions>
        )}

        {covidInfectionHistory && (
          <>
            <Descriptions
              bordered
              labelStyle={{ fontWeight: "bold" }}
              size="small"
              column={1}
              layout="vertical"
              style={{ marginTop: 20 }}
            >
              <Descriptions.Item label={t("close_contact_covid")}>
                <Row>
                  <Text bold>{t("status")} :</Text>
                  <Text className="ml-1">
                    {covidInfectionHistory.closeContact
                      ? t(covidInfectionHistory.closeContact)
                      : t("nothing")}
                  </Text>
                </Row>

                {covidInfectionHistory.closeContact === "YES" && (
                  <>
                    <Row>
                      <Text bold>{t("date")} :</Text>
                      <Text className="ml-1" style={{ fontWeight: 300 }}>
                        {moment(
                          covidInfectionHistory.closeContactDate
                        ).isValid()
                          ? moment(
                            covidInfectionHistory.closeContactDate
                          ).format("DD/MM/YYYY")
                          : t("nothing")}
                      </Text>
                    </Row>

                    <Descriptions
                      bordered
                      labelStyle={{ fontWeight: "bold" }}
                      size="small"
                      column={1}
                      style={{ marginTop: 20 }}
                      layout="vertical"
                    >
                      <Descriptions.Item label={t("comment")}>
                        {covidInfectionHistory.closeContactComment ||
                          t("nothing")}
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>

            <Descriptions
              bordered
              labelStyle={{ fontWeight: "bold" }}
              size="small"
              column={1}
              layout="vertical"
              style={{ marginTop: 20 }}
            >
              <Descriptions.Item label={t("history_of_previous_infection")}>
                <Row>
                  <Text bold>{t("status")} :</Text>
                  <Text className="ml-1">
                    {covidInfectionHistory.covidInfected
                      ? t(covidInfectionHistory.covidInfected)
                      : t("nothing")}
                  </Text>
                </Row>
                {covidInfectionHistory.covidInfected === "YES" && (
                  <>
                    <Row>
                      <Text bold>{t("date")} :</Text>
                      <Text className="ml-1" style={{ fontWeight: 300 }}>
                        {moment(
                          covidInfectionHistory.covidInfectedDate
                        ).isValid()
                          ? moment(
                            covidInfectionHistory.covidInfectedDate
                          ).format("DD/MM/YYYY")
                          : t("nothing")}
                      </Text>
                    </Row>

                    <Descriptions
                      bordered
                      labelStyle={{ fontWeight: "bold" }}
                      size="small"
                      column={1}
                      style={{ marginTop: 20 }}
                      layout="vertical"
                    >
                      <Descriptions.Item label={t("comment")}>
                        {covidInfectionHistory.covidInfectedComment}
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Container>
    );
  }

  // NOTE not disabled
  const onAddVaccineHistory = () => {
    form.setFieldValue("vaccinneHistory", [
      ...form.values.vaccinneHistory,
      {
        vaccineName: "",
        date: "",
        site: "",
      },
    ]);
  };

  const onChangeHistoryCovidValue = (type, value) => {
    form.setFieldValue("covidInfectionHistory", {
      ...form.values.covidInfectionHistory,
      [type]: value,
    });
  };

  const onChangeVaccineHistoryValue = (index, type, value) => {
    const vaccineArray = Array.isArray(form.values.vaccinneHistory)
      ? form.values.vaccinneHistory
      : [];
    if (vaccineArray[index][type] !== undefined) {
      vaccineArray[index][type] = value;
    }
    form.setFieldValue("vaccinneHistory", vaccineArray);
  };

  const onDelelteVaccineHistory = (index) => {
    form.setFieldValue(
      "vaccinneHistory",
      form.values.vaccinneHistory.filter((_, i) => i !== index)
    );
  };

  const renderMenu = (index, items) => (
    <Container
      style={{ minWidth: 250, backgroundColor: "white" }}
      className="shadow px-2 py-1"
    >
      {items.map((item) => {
        return (
          <a
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              onChangeVaccineHistoryValue(index, "vaccineName", item.label);
            }}
          >
            <Text className="py-1 my-1" key={item.key}>
              {item.label}
            </Text>
          </a>
        );
      })}
    </Container>
  );

  return (
    <>
      <Container>
        {form.values.vaccinneHistory.map((item, index) => {
          return (
            <Row key={index} className="mb-1" gutter={16}>
              <Col lg={8}>
                <DropDownWidget
                  value={item.vaccineName}
                  renderList={renderMenu(index, VACCINES_NAME)}
                  title={`${t("dose_at")} #${index + 1}`}
                  width="100%"
                  placeholder={`${t("dose_at")} #${index + 1}`}
                />
              </Col>
              <Col lg={6}>
                <Space>
                  <Text size="12px">{t("date_injected")}</Text>
                </Space>
                <DatePicker
                  format="DD MMMM YYYY"
                  width="100%"
                  disabledDate={disabledDate}
                  defaultValue={item.date ? moment(item.date) : null}
                  onChange={(date) => {
                    onChangeVaccineHistoryValue(index, "date", date);
                  }}
                />
              </Col>
              <Col lg={10}>
                <Space>
                  <Text size="12px">{t("clinic_site_injected")}</Text>
                </Space>
                <SpaceItemFlex className="w-100">
                  <Input
                    value={item.site}
                    onChange={(e) =>
                      onChangeVaccineHistoryValue(index, "site", e.target.value)
                    }
                    width="100%"
                    placeHolder="Enter something..."
                  />
                  <Icon
                    component={FaTimes}
                    size="20px"
                    color="#00000059"
                    onClick={() => onDelelteVaccineHistory(index)}
                  />
                </SpaceItemFlex>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Container className="mt-3">
        <ButtonRounded onClick={onAddVaccineHistory}>
          <Space className="py-1" direction="horizontal" align="center">
            <Icon component={AiFillPlusCircle} size="24px" color="#2BA796" />
            <Text>{t("add_more")}</Text>
          </Space>
        </ButtonRounded>
      </Container>
      <Container className="mt-3">
        <Dragger
          defaultFileList={
            form.values.covidInfectionHistory?.covidInfectionHistoryUrl
              ? [
                {
                  uid: "1",
                  name: getNameFromUrl(
                    form.values.covidInfectionHistory
                      ?.covidInfectionHistoryUrl
                  ),
                  status: "done",
                  response: {
                    url: form.values.covidInfectionHistory
                      ?.covidInfectionHistoryUrl,
                  },
                  url: form.values.covidInfectionHistory
                    ?.covidInfectionHistoryUrl,
                },
              ]
              : []
          }
          onRemove={(_) => {
            onChangeHistoryCovidValue("covidInfectionHistoryUrl", "");
          }}
          onChange={(info) => {
            const { status } = info.file;
            if (status === "done") {
              onChangeHistoryCovidValue(
                "covidInfectionHistoryUrl",
                info.file.response?.url
              );
            }
          }}
          customRequest={(option) =>
            customRequest({
              ...option,
              key: currentRecord.researchId,
              pathFb: "covidInfectionHistoryFile",
            })
          }
          multiple={false}
          className="mt-3 py-3"
          maxCount={1}
        >
          <Row justify="center" align="middle">
            <Icon component={IoMdCloudUpload} size="24px" color="#2BA796" />
            <Text className="mx-2" primary>
              {t("drop_file_to_upload_or_click")}
            </Text>
          </Row>
        </Dragger>
      </Container>
      <Container className="mt-3">
        <Row gutter={[16, 8]}>
          <Col>
            <Text size="16px">{t("close_contact_covid")}</Text>
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) =>
                onChangeHistoryCovidValue("closeContact", e.target.value)
              }
              value={form.values.covidInfectionHistory.closeContact}
            >
              <Radio value="YES">{t("yes")}</Radio>
              <Radio value="NO">{t("no")}</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row gutter={[16, 8]} className="mt-2" align="middle">
          <Col xs={3} sm={3} xxl={1}>
            <Text size="16px">{t("comment")}:</Text>
          </Col>
          <Col xs={24} lg={12}>
            <Input
              value={form.values.covidInfectionHistory.closeContactComment}
              onChange={(e) => {
                onChangeHistoryCovidValue(
                  "closeContactComment",
                  e.target.value
                );
              }}
              width="100%"
            />
          </Col>
          <Col xs={24} lg={8}>
            <DatePicker
              format="DD MMMM YYYY"
              width="100%"
              disabledDate={disabledDate}
              defaultValue={
                form.values.closeContactDate
                  ? moment(form.values.closeContactDate)
                  : null
              }
              onChange={(date) => {
                onChangeHistoryCovidValue(
                  "closeContactDate",
                  moment(date).toISOString()
                );
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2" gutter={[16, 8]}>
          <Col>
            <Text size="16px">{t("history_of_previous_infection")}</Text>
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) =>
                onChangeHistoryCovidValue("covidInfected", e.target.value)
              }
              value={form.values.covidInfectionHistory.covidInfected}
            >
              <Radio value="YES">{t("yes")}</Radio>
              <Radio value="NO">{t("no")}</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row gutter={[16, 8]} className="mt-2" align="middle">
          <Col xs={3} sm={3} xxl={1}>
            <Text size="16px">{t("comment")}:</Text>
          </Col>
          <Col xs={24} lg={12}>
            <Input
              value={form.values.covidInfectionHistory.covidInfectedComment}
              onChange={(e) => {
                onChangeHistoryCovidValue(
                  "covidInfectedComment",
                  e.target.value
                );
              }}
              width="100%"
            />
          </Col>
          <Col xs={24} lg={8}>
            <DatePicker
              format="DD MMMM YYYY"
              width="100%"
              disabledDate={disabledDate}
              defaultValue={
                form.values.covidInfectedDate
                  ? moment(form.values.covidInfectedDate)
                  : null
              }
              onChange={(date) => {
                onChangeHistoryCovidValue(
                  "covidInfectedDate",
                  moment(date).toISOString()
                );
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VaccinesInfectionHistory;
