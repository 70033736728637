import React from "react";
import { Row, Col, Divider } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
import {
  ParagraphStyle,
  ImageStyle,
  CustomIcon,
  Content,
  ButtonPrescription,
  CardContainer,
} from "./styles";

const PrescriptionCard = (props) => {
  const { medications, date, fullName, handleVisibleNote, id, image } = props;

  const price = (medication) => {
    const { totalPriceCents } = medication;
    return totalPriceCents / 100;
  };

  const totalPrice = () => {
    const total = medications.reduce((acc, medication) => {
      return acc + price(medication);
    }, 0);
    return parseFloat(total).toFixed(2);
  };

  return (
    <CardContainer>
      <Row justify="end">
        <Col>
          <ParagraphStyle color="#c0c0c0" size="12">
            {date}
          </ParagraphStyle>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
        <Col>
          <Row gutter={24} align="middle">
            <Col style={{ height: "40px" }}>
              <ImageStyle src={image} />
            </Col>
            <Col>
              <ParagraphStyle>{fullName}</ParagraphStyle>
            </Col>
          </Row>
        </Col>
        <Col>
          <VideoCameraOutlined style={{ cursor: "pointer" }} />
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
        <Col>
          <ParagraphStyle color="#000000" size="12">
            ID : {id}
          </ParagraphStyle>
        </Col>
        <Col>
          <ButtonPrescription onClick={() => handleVisibleNote(true)}>
            <ParagraphStyle size="12" color="#61D0F5" bold="true">
              See Note
            </ParagraphStyle>
            <CustomIcon color="#61D0F5" />
          </ButtonPrescription>
        </Col>
      </Row>

      {/* CONTENT PRESCRIPTION */}
      {medications && medications.length > 0 && (
        <Content>
          <ParagraphStyle size="18">Prescription</ParagraphStyle>
          <Divider />
          <Row>
            {medications.map((medication, index) => {
              const { _medicationForOrg, amount } = medication;
              const { tradeName, unitType } = _medicationForOrg;
              return (
                <Col key={index} span={24} style={{ marginTop: 15 }}>
                  <Row gutter={24}>
                    <Col span={3} align="center">
                      {index + 1}.
                    </Col>
                    <Col span={16}>
                      <ParagraphStyle>{tradeName}</ParagraphStyle>
                      <ParagraphStyle size="12" color="#767676">
                        Quantity: {amount} {unitType}
                      </ParagraphStyle>
                    </Col>
                    <Col span={4}>
                      <ParagraphStyle
                        bold="true"
                        style={{ textAlign: "right" }}
                      >
                        {parseFloat(price(medication)).toFixed(2)}฿
                      </ParagraphStyle>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          <Divider />
          <Row justify="end" gutter={12}>
            <Col>Cost:</Col>
            <Col>
              <ParagraphStyle color="#10B2BD" bold="true" size="18">
                {totalPrice()}฿
              </ParagraphStyle>
            </Col>
          </Row>
        </Content>
      )}
    </CardContainer>
  );
};

export default PrescriptionCard;
