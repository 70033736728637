import { Button, Col, Popconfirm, Row, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Booking,
  UserFcmToken,
  Treatment,
  Firebase,
} from "../../redux/actions";
import {
  ACTION_STATUS,
  NOTIFICATION,
  NOTIFICATION_DATA,
  PENDING_STATUS,
} from "./constant";
import PrescriptionSummary from "../PrescriptionSummary";
import { hashHelper } from "../../common/hash";
const action = ({
  t,
  status,
  id,
  patientId,
  setVisible,
  bookingAdmitTime,
  isSummaryPage,
  booking,
  history,
}) => {
  const [visiblePrescriptionModal, setVisiblePrescriptionModal] =
    useState(false);
  const dispatch = useDispatch();
  const userOma = useSelector((state) => state.appUserOma);
  const currentRoom = useSelector((state) => state.firebase.currentRoom);
  const medication = useSelector((state) => state.booking.medication);
  const role =
    userOma && userOma.information && userOma.information.roles[0].name;

  const handleConfirm = async (id, action) => {
    const { notificationKey, update, isUpdate, detail } = action;
    let dataUpdate = { status: update };
    if (detail) {
      dataUpdate = {
        ...dataUpdate,
        ...detail,
      };
    }
    if (PENDING_STATUS.includes(status)) {
      let bookingCategory = booking.bookingCategory.toUpperCase();
      if (booking.bookingCategory !== "fatCampaign") {
        if (
          booking.bookingCategory === "general" &&
          booking.bookingType === "Telepharmacy"
        ) {
          bookingCategory = "COMMUNITY_PHARMACIST";
        }
        dispatch(
          Firebase.updateRoom(
            bookingCategory,
            userOma.information.id,
            patientId,
            {
              status: update,
            },
            role
          )
        );
      } else if (booking.bookingCategory === "fatCampaign") {
        dispatch(
          Firebase.updateRoomByCountryAndSpecialty(
            "thailand",
            "fc-th",
            { status: update },
            patientId
          )
        );
      }
    }
    dispatch(Booking.updateBooking(id, isUpdate, dataUpdate));
    if (notificationKey) {
      sendNotification(
        patientId,
        NOTIFICATION[notificationKey],
        NOTIFICATION_DATA(notificationKey, bookingAdmitTime)
      );
    }
    if (setVisible) {
      setVisible(false);
    }
    deleteSummaryCallData();
  };

  const sendNotification = (patientId, notificationKey, notificationData) => {
    dispatch(
      UserFcmToken.sendNotification(
        patientId,
        notificationKey,
        notificationData
      )
    );
  };

  const deleteSummaryCallData = () => {
    localStorage.removeItem("summaryData");
    localStorage.removeItem("IsSummaryPage");
  };

  const updateFirebaseRoomStatus = (update) => {
    let bookingCategory = booking.bookingCategory.toUpperCase();
    if (booking.consultantRole === "community_pharmacist") {
      bookingCategory = "COMMUNITY_PHARMACIST";
    }
    dispatch(
      Firebase.updateRoom(
        bookingCategory,
        userOma.information.id,
        currentRoom.userId,
        {
          status: update,
        },
        role
      )
    );
  };

  const handleDischarge = (treatmentId) => {
    dispatch(Treatment.updateTreatment(treatmentId, { discharge: true }));
  };

  const getCurrentBookingAndRoomFirebase = () => {
    const roomType = booking.bookingCategory.toUpperCase();
    const practitionerId = booking.practitionerAppUserId;
    const role = booking.practitionerAppUser.roles[0];
    dispatch(Booking.getBookingMedication(id));
    dispatch(Booking.setCurrentBooking(booking));
    dispatch(Firebase.getRooms(roomType, practitionerId, role, patientId));
  };

  const handleToSummary = (bookingId) => {
    const hash = hashHelper(
      JSON.stringify({
        bookingId: bookingId,
        date: Date.now(),
        roomType: "Scheduling",
      })
    );
    history.push(`/summary/${hash}`);
    dispatch(
      Booking.updateBooking(id, true, { status: "DOCTOR_PENDING_NOTE" })
    );
  };

  return (
    <Row type="flex" gutter={[16, 8]}>
      {status &&
        ACTION_STATUS[status] &&
        ACTION_STATUS[status].length &&
        ACTION_STATUS[status].map((action) =>
          isSummaryPage && status === "DOCTOR_PENDING_NOTE" ? (
            medication.length > 0 === action.disabled && (
              <Col>
                <Popconfirm
                  placement="topLeft"
                  title={t(action.title)}
                  onConfirm={() => handleConfirm(id, action)}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button
                    type="primary"
                    danger={action.style}
                    className={isSummaryPage && "summary-secondary-button"}
                  >
                    {t(action.btnText)}
                  </Button>
                </Popconfirm>
              </Col>
            )
          ) : status === "DOCTOR_CONFIRM" ? (
            <Col>
              <Popconfirm
                title={t("are_you_sure_to_redirect_to_a_summary_page")}
                onConfirm={() => handleToSummary(id)}
              >
                <Button type="primary">{t("summary")}</Button>
              </Popconfirm>
            </Col>
          ) : (
            <Col>
              <Popconfirm
                placement="topLeft"
                title={t(action.title)}
                onConfirm={() => {
                  handleConfirm(id, action);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button
                  type="primary"
                  danger={action.style}
                  className={isSummaryPage && "summary-secondary-button"}
                >
                  {t(action.btnText)}
                </Button>
              </Popconfirm>
            </Col>
          )
        )}
      <Modal
        visible={visiblePrescriptionModal}
        onCancel={() => setVisiblePrescriptionModal(false)}
        okText="ส่งต่อเภสัชกร"
        cancelText="ปิด"
        okButtonProps={{
          disabled: medication && medication.length ? false : true,
        }}
        onOk={() => {
          handleConfirm(id, ACTION_STATUS.DOCTOR_PENDING_NOTE[1]);
          setVisiblePrescriptionModal(false);
        }}
      >
        <PrescriptionSummary
          prescription={medication}
          role={role}
          isSummaryPage={false}
        />
      </Modal>
    </Row>
  );
};

export default action;
