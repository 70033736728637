import http from "../services/httpServiceHealthCare";
import jwtDecode from "jwt-decode";
import moment from "moment";
const apiEndpoint = "/Bookings";

const FILTER_STATUS = {
  doctor: ["DOCTOR_PENDING", "DOCTOR_CONFIRM"],
  pharmacy: ["PHARMACY_PENDING", "PHARMACY_CONFIRM"],
};

const ORDER_STATUS = {
  descend: "DESC",
  ascend: "ASC",
};

async function getPrescriptionItems(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/prescriptionItems`);
  return data;
}

async function deletePrescriptionItem(request) {
  const { id, prescriptionItemId } = request;
  await http.delete(
    `${apiEndpoint}/${id}/prescriptionItems/${prescriptionItemId}`
  );
  return prescriptionItemId;
}

async function updatePrescriptionItem(request) {
  const { id, prescriptionItemId, medicine } = request;
  await http.put(
    `${apiEndpoint}/${id}/prescriptionItems/${prescriptionItemId}`,
    medicine
  );
  return prescriptionItemId;
}

async function getBookings(
  id,
  orgList,
  page,
  order,
  pageSize,
  orderByTab,
  status,
  filters
) {
  let filterOrgList = orgList;
  if (orgList.length > 1) {
    filterOrgList = orgList.filter((e) => !e?.name?.match?.(/ever/gi));
  }
  const role = jwtDecode(localStorage.getItem("token")).roles[0];
  let filter = "";
  let filterStatus = "";
  let filterBy = "";
  let filterOrg = "";

  if (role === "deliveryTeam") {
    filter = "&filter[where][status]=PHARMACY_COMPLETED";
  }
  if (Array.isArray(status)) {
    filterStatus = `&filter[where][and][0][status][regexp]=/${status.join(
      "|"
    )}/gi`;
  } else if (status) {
    filterStatus = `&filter[where][and][0][status]=${status}`;
  }
  if (filterOrgList.length > 0) {
    filterOrg = "&filter[where][and][1][organizationId]=" + filterOrgList[0].id;
  }
  if (filters && Object.keys(filters).length > 0) {
    for (const property in filters) {
      let base = `&filter[where]`;
      if (filters[property]?.length === 1) {
        filterBy = `${base}[${property}]=${filters[property][0]}`;
      } else if (filters[property]?.length > 1) {
        filters[property].forEach((fil, index) => {
          filterBy += `${base}[or][${index}][${property}]=${fil}`;
        });
      }
    }
  }

  const filterInclude =
    "filter[include]=practitionerAppUser&filter[include]=patient";
  const filterDoctor = id ? `filter[where][practitionerAppUserId]=${id}` : "";

  const pageNumber = `page=${page ? page : 1}`;
  const orderBy = getFilterOrderBy(order);
  const limitPageSize = getFilterPageSize(pageSize);
  const filterByDate = orderByTab ? getFilterByTab(orderByTab) : "";
  const allFilter = `${filterInclude}&${filterDoctor}${filterStatus}${filterOrg}${filter}&${pageNumber}&${orderBy}&${limitPageSize}&${filterByDate}${filterBy}`;
  const { data } = await http.get(`${apiEndpoint}/?${allFilter}`);

  return data;
}

async function getBooking(id) {
  const { data } = await http.get(
    `${apiEndpoint}/${id}?filter[include]=practitionerAppUser&filter[include]=patient&filter=[include]=drugUnit`
  );
  return data;
}

async function getBookingPayments(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/payments`);
  return data;
}

async function getBookingHistory({ userId, patientId, status }) {
  let filterInclude = `filter[include]=patient&filter[include]=practitionerAppUser&filter[where][practitionerAppUserId]=${userId}`;
  if (patientId) {
    filterInclude += `&filter[where][patientId]=${patientId}`;
  }
  if (status) {
    filterInclude += `&filter[where][status]=${status}`;
  }
  const { data } = await http.get(`${apiEndpoint}?${filterInclude}`);
  return data;
}

async function getBookingHistoryWithEarning(props) {
  const { userId, patientId, status } = props;
  let filterInclude = `filter[include]=patient&filter[include]=practitionerAppUser&filter[where][practitionerAppUserId]=${userId}`;
  if (patientId) {
    filterInclude += `&filter[where][patientId]=${patientId}`;
  }
  if (status) {
    filterInclude += `&filter[where][status]=${status}`;
  }
  const { data } = await http.get(`${apiEndpoint}?${filterInclude}`);
  const dailyBooking = data.filter(
    (booking) =>
      moment(booking.admitTime) >= moment().startOf("day") &&
      moment(booking.admitTime) <= moment().endOf("day")
  );
  const weeklyBooking = data.filter(
    (booking) =>
      moment(booking.admitTime) >= moment().startOf("week") &&
      moment(booking.admitTime) <= moment().endOf("week")
  );
  const monthlyBooking = data.filter(
    (booking) =>
      moment(booking.admitTime) >= moment().startOf("month") &&
      moment(booking.admitTime) <= moment().endOf("month")
  );
  const daily = dailyBooking.reduce((acc, booking) => {
    if (booking.prescription && booking.prescription.length > 0) {
      const total = booking.prescription.reduce(
        (acc, cur) =>
          acc + (Number(cur.amount) * Number(cur.unitPriceCents)) / 100,
        0
      );
      if (total >= 0) {
        return acc + total;
      }
    }
    return acc;
  }, 0);
  const weekly = weeklyBooking.reduce((acc, booking) => {
    if (booking.prescription && booking.prescription.length > 0) {
      const total = booking.prescription.reduce(
        (acc, cur) =>
          acc + (Number(cur.amount) * Number(cur.unitPriceCents)) / 100,
        0
      );
      if (total >= 0) {
        return acc + total;
      }
    }
    return acc;
  }, 0);
  const monthly = monthlyBooking.reduce((acc, booking) => {
    if (booking.prescription && booking.prescription.length > 0) {
      const total = booking.prescription.reduce(
        (acc, cur) =>
          acc + (Number(cur.amount) * Number(cur.unitPriceCents)) / 100,
        0
      );
      if (total >= 0) {
        return acc + total;
      }
    }
    return acc;
  }, 0);
  const total = data.reduce((acc, booking) => {
    if (booking.prescription && booking.prescription.length > 0) {
      const total = booking.prescription.reduce(
        (acc, cur) =>
          acc + (Number(cur.amount) * Number(cur.unitPriceCents)) / 100,
        0
      );
      if (total >= 0) {
        return acc + total;
      }
    }
    return acc;
  }, 0);
  return { data, earning: { daily, weekly, monthly, total } };
}

async function updateBooking({ id, detail }) {
  const { data } = await http.patch(`${apiEndpoint}/${id}`, detail);
  return data;
}

async function getBookingMedication({ id }) {
  const { data } = await http.get(`${apiEndpoint}/${id}/`);
  return data.prescription;
}

async function addPrescriptionItem({ id, medicine }) {
  const { data } = await http.post(
    `${apiEndpoint}/${id}/prescriptionItems`,
    medicine
  );
  return data;
}

async function getHistoryBookingByDateTime(props) {
  const { routineType, dateTime, status } = props;
  let filterDateTime = `filter[where][admitTime][between][0]=${moment(
    dateTime
  ).startOf(routineType)}&filter[where][admitTime][between][1]=${moment(
    dateTime
  ).endOf(routineType)}`;
  const { data } = await http.get(
    `${apiEndpoint}?filter[include]=practitionerAppUser&filter[include]=patient&${filterDateTime}&filter[where][status]=${status}`
  );
  return data;
}

function getFilterByTab(orderByTab) {
  const today = moment().startOf("day");
  switch (orderByTab) {
    case "today":
      return `filter[where][admitTime][between][0]=${today}&filter[where][admitTime][between][1]=${moment()
        .startOf("day")
        .add(24, "h")}`;
    case "greaterThan":
      return `filter[where][admitTime][gt]=${today.add(24, "h")}`;
    case "lessThan":
      return `filter[where][admitTime][lt]=${today.subtract(24, "h")}`;
    case "problemReport":
      return `filter[where][or][0][status]=DOCTOR_DECLINED&filter[where][or][1][status]=PHARMACY_DECLINED`;
    default:
      return "";
  }
}
function getFilterPageSize(pageSize) {
  return `filter[limit]=${pageSize ? pageSize : 10}`;
}
function getFilterOrderBy(order) {
  return `filter[order]=admitTime ${order ? ORDER_STATUS[order] : "DESC"}`;
}

async function searchBookings(
  organizationId,
  firstname,
  lastname,
  cId,
  admitTimeRangeType,
  status,
  page,
  pageSize
) {
  const orgId = organizationId ? `organizationId=${organizationId}` : "";
  const firstnameParam = firstname ? `firstname=${firstname}` : "";
  const lastnameParam = lastname ? `lastname=${lastname}` : "";
  const cIdParam = cId ? `cId=${cId}` : "";
  const admitTimeRangeTypeParam = admitTimeRangeType
    ? `admitTimeRangeType=${admitTimeRangeType}`
    : "";
  const statusParam = status ? `status=${status}` : "";
  const pageParam = page ? `page=${page}` : "";
  const pageSizeParam = pageSize ? pageSize : 10;
  const params = `${firstnameParam}&${lastnameParam}&${cIdParam}&${admitTimeRangeTypeParam}&${statusParam}&${pageParam}&${orgId}`;
  const { data } = await http.get(
    `${apiEndpoint}/search?filter[include]=practitionerAppUser&filter[include]=patient&${params}&filter[order]=admitTime DESC&filter[limit]=${pageSizeParam}`
  );
  return data;
}

async function getDoctorReferralByBookingId(id) {
  const { data } = await http.get(`${apiEndpoint}/${id}/doctorReferrals`);
  return data;
}

export {
  getBookings,
  getBookingPayments,
  getHistoryBookingByDateTime,
  getBookingHistory,
  getBookingHistoryWithEarning,
  getBooking,
  updateBooking,
  getBookingMedication,
  addPrescriptionItem,
  updatePrescriptionItem,
  searchBookings,
  getPrescriptionItems,
  deletePrescriptionItem,
  getDoctorReferralByBookingId,
};
