import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/Submissions";

async function updateUserOrgSubmission({ id, option, detail }) {
  const { data } = await http.patch(
    `${apiEndpoint}/${option}?submissionId=${id}`,
    detail
  );
  return data;
}

export { updateUserOrgSubmission };
