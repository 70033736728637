import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification, message } from "antd";
import jwtDecode from "jwt-decode";
import { decryptHelper, hashHelper } from "../../common/hash";
import {
  Booking,
  AppUserOma,
  SymptomGroup,
  Firebase,
  UserFcmToken,
  Questionaires,
  DrugIngredientGroup,
  MedicationForOrganization,
  DrugUnit,
  Treatment,
  PractitionerSpecialties,
  Practitioner,
  Clinic,
  ClinicPackages,
  DoctorReferral,
  DrugTimeEvent,
  DrugTimeFrequency,
} from "../../redux/actions";
import {
  NOTIFICATION,
  NOTIFICATION_DATA,
  DOCTOR_REFER_TYPE_LIST,
  DOCTOR_REFER_TYPE,
} from "./constant";
import Summary from "../../components/Summary";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

const SummaryContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { match, history } = props;

  const booking = useSelector((state) => state.booking);
  const appUserOma = useSelector((state) => state.appUserOma);
  const symptomGroup = useSelector((state) => state.symptomGroup);
  const questionaires = useSelector((state) => state.questionaires);
  const drugTimeEvent = useSelector((state) => state.drugTimeEvent);
  const drugTimeFrequency = useSelector((state) => state.drugTimeFrequency);

  const drugIngredientGroup = useSelector((state) => state.drugIngredientGroup);
  const practitionerSpecialties = useSelector(
    (state) => state.practitionerSpecialties
  );
  const clinic = useSelector((state) => state.clinic);
  const practitioners = useSelector((state) => state.practitioners);
  const clinicPackages = useSelector((state) => state.clinicPackages);
  const doctorReferral = useSelector((state) => state.doctorReferral);
  const medication = useSelector(
    (state) => state.medicationForOrganization.medication
  );
  const drugUnit = useSelector((state) => state.drugUnit);

  const [roomType, setRoomType] = useState("");
  const [country, setCountry] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [
    visibleHistoryPatientDetailModal,
    setVisibleHistoryPatientDetailModal,
  ] = useState(false);
  const [visibleSummaryNoteModal, setVisibleSummaryNoteModal] = useState(false);
  const [visibleDoctorReferralModal, setVisibleDoctorReferralModal] =
    useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [doctorReferSpecialty, setDoctorReferSpecialty] = useState(null);
  const [doctorReferSpecialtyId, setDoctorReferSpecialtyId] = useState(false);
  const [doctorReferType, setDoctorReferType] = useState("");
  const [color, setColor] = useState("");
  const [note, setNote] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [visiblePrescriptionList, setVisiblePrescriptionList] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    initialData();
    getDrugTime();
  }, []);

  useEffect(() => {
    if (booking.isSuccess) {
      setRoomType(booking.current.bookingCategory);
      setNote(booking.current.notes);
      if (
        booking.current.bookingCategory === "covid" &&
        booking.current.covidForm?.color
      ) {
        setColor(booking.current.covidForm.color);
      }
      if (
        isFirstLoad ||
        (!booking.prescription.loading &&
          !booking.prescription.isSuccess &&
          booking.prescription.data.length !==
            booking.current.prescriptionList.length)
      ) {
        dispatch(Booking.getPrescriptionItems(booking.current.id));
        setIsFirstLoad(false);
      }
      if (booking.prescription.isSuccess) {
        setMedicines(booking.prescription.data);
      }
    }
  }, [booking]);

  useEffect(() => {
    if (
      appUserOma.information &&
      appUserOma.information.roles &&
      appUserOma.isSuccess &&
      booking.isSuccess
    ) {
      updatePrescriptionListToFirebase();
    }
  }, [medicines, appUserOma]);

  useEffect(() => {
    if (
      booking.isSuccess &&
      appUserOma.information &&
      appUserOma.information.roles &&
      appUserOma.isSuccess &&
      drugUnit.isSuccess &&
      drugTimeEvent.isSuccess &&
      drugTimeFrequency.isSuccess &&
      practitionerSpecialties.isSuccess &&
      (appUserOma.information.roles[0].name !== "doctor" ||
        doctorReferral.isSuccess)
    ) {
      setIsFinishInitialLoad(true);
    }
  }, [
    booking,
    drugTimeEvent,
    drugTimeFrequency,
    appUserOma,
    isFirstLoad,
    drugUnit,
    practitionerSpecialties,
    doctorReferral,
  ]);

  useEffect(() => {
    dispatch(MedicationForOrganization.fetchMedications(search));
  }, [search]);

  useEffect(() => {
    if (
      appUserOma.isSuccess &&
      appUserOma.information.roles[0].name === "doctor"
    ) {
      if (
        doctorReferral?.data?.referralType &&
        doctorReferral?.data?.practitionerSpecialtyId &&
        Array.isArray(practitionerSpecialties?.data) &&
        practitionerSpecialties?.data?.length > 0 &&
        doctorReferral?.data?.bookingId?.replace(/"/g, "") ===
          booking.current.id
      ) {
        setDoctorReferType(doctorReferral?.data?.referralType);
        const findItem = practitionerSpecialties?.data.find(
          (ele) => ele.id === doctorReferral?.data?.practitionerSpecialtyId
        );
        setDoctorReferSpecialty(findItem?.name);
        setDoctorReferSpecialtyId(
          doctorReferral?.data?.practitionerSpecialtyId
        );
        getDataByReferTypeHandler(
          doctorReferral?.data?.referralType,
          findItem?.name
        );
      }
    }
  }, [doctorReferral?.data?.referralType, practitionerSpecialties]);

  const updatePrescriptionListToFirebase = () => {
    const newMedicines = medicines.map((medicine) => ({
      name: medicine._medicationForOrg.tradeName,
      amount: medicine.amount,
    }));
    if (appUserOma.information.roles[0].name === "communityPharmacist") {
      dispatch(
        Firebase.updateRoom(
          "COMMUNITY_PHARMACIST",
          appUserOma.information.id,
          booking.current.patient.id,
          {
            prescriptions: newMedicines,
          },
          "communityPharmacist"
        )
      );
    } else {
      dispatch(
        Firebase.updateRoomByCountryAndSpecialty(
          country,
          specialty,
          { prescriptions: newMedicines },
          booking.current.patientId
        )
      );
    }
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const getDataByReferTypeHandler = (type, pQ) => {
    switch (type) {
      case DOCTOR_REFER_TYPE.CLINIC:
        getClinicBySpecialties(pQ);
        break;
      case DOCTOR_REFER_TYPE.DOCTOR:
        getPractitionersBySpecialties(pQ);
        break;
      case DOCTOR_REFER_TYPE.TREATMENT:
        getClinicPackageBySpecialties(pQ);
        break;
    }
  };

  const getClinicBySpecialties = (pQ) => {
    dispatch(
      Clinic.getClinicBySpecialties({
        where: {
          and: [
            {
              or: [
                { country: { ilike: "Thailand%" } },
                { city: { ilike: "Thailand%" } },
              ],
            },
          ],
        },
        order: "sortingRank DESC",
        include: [
          {
            relation: "Procedures",
            scope: {
              where: {
                and: [{ or: [{ name: { ilike: pQ + "%" } }] }],
              },
            },
          },
          { relation: "ClinicReviews" },
        ],
      })
    );
  };

  const getClinicPackageBySpecialties = (pQ) => {
    dispatch(
      ClinicPackages.getClinicPackagesBySpecialties({
        where: {
          and: [
            { tagSearch: { ilike: "%" + `${pQ}`.toLocaleLowerCase() + "%" } },
          ],
        },
        order: "sortingRank DESC",
      })
    );
  };

  const getPractitionersBySpecialties = (pQ) => {
    dispatch(
      Practitioner.getPractitionerBySpecialties({
        where: { and: [{ tagSearch: { ilike: "%" + pQ + "%" } }] },
        order: "sortingRank DESC",
      })
    );
  };

  const selectDoctorReferTypeHandler = (type) => {
    setDoctorReferType(type);
    getDataByReferTypeHandler(type, doctorReferSpecialty);
  };

  const selectDoctorReferSpecialtyHandler = (value) => {
    setDoctorReferSpecialty(value);
    if (doctorReferType && value) {
      getDataByReferTypeHandler(doctorReferType, value);
    }
  };

  const createDoctorReferral = (record) => {
    setIsFinishInitialLoad(false);
    const payload = {
      referralType: doctorReferType,
      referralId: record.id,
      practitionerSpecialtyId: doctorReferSpecialtyId,
      appUserId: booking?.current?.patientId,
      bookingId: booking?.current?.id,
    };
    if (doctorReferral?.data?.id) {
      dispatch(
        DoctorReferral.updateDoctorReferral(doctorReferral?.data?.id, payload)
      );
    } else {
      dispatch(DoctorReferral.createDoctorReferral(payload));
    }
    setVisibleDoctorReferralModal(false);
  };

  const deleteDoctorReferral = (id) => {
    dispatch(DoctorReferral.deleteDoctorReferral(id));
  };

  const getDrugTime = () => {
    dispatch(DrugTimeEvent.getDrugTimeEvent());
    dispatch(DrugTimeFrequency.getDrugTimeFrequency());
  };

  const deleteDrugFromPrescription = (id) => {
    setMedicines((prev) => prev.filter((item) => item.id !== id));
    dispatch(Booking.deletePrescriptionItem(booking.current.id, id));
  };
  const handleSelectMedicine = (id) => {
    const checkDuplicatedMedicine = medicines.filter(
      (medicine) => medicine._medicationForOrg._id === id
    );
    if (checkDuplicatedMedicine && checkDuplicatedMedicine.length > 0) {
      message.error(t("medicine_cannot_be_repeated"));
    } else {
      const findMedication = medication.data.find((item) => item.id === id);
      const drugTimeEventNew = drugTimeEvent.data[0];
      const drugRoute = findMedication.medication.drugRoutes[0];
      const newDrugUnit = drugUnit.data[0];
      const drugTimeFrequencyNew = drugTimeFrequency.data[0];
      const medicine = {
        dose: 1,
        amount: 1,
        note: "",
        _medicationForOrg: {
          _id: findMedication.id,
          activeIngredient: findMedication.activeIngredient,
          tradeName: findMedication.tradeName,
          priceCents: parseInt(findMedication.priceCents),
          strength: findMedication.strength,
          dosageForm: findMedication.dosageForm,
          unitType: findMedication.unitType,
          medicationId: findMedication.medicationId,
          organizationId: findMedication.organizationId,
        },
        _drugUnit: {
          _id: newDrugUnit.id,
          name: newDrugUnit.name,
          nameTh: newDrugUnit.nameTh,
        },
        _drugRoute: {
          _id: drugRoute.id,
          topicalRoute: drugRoute.topicalRoute,
          routeEn: drugRoute.routeEn,
          routeTh: drugRoute.routeTh,
        },
        _drugTimeEvent: {
          _id: drugTimeEventNew.id,
          code: drugTimeEventNew.code,
          nameEn: drugTimeEventNew.nameEn,
          nameTh: drugTimeEventNew.nameTh,
        },
        _drugFrequency: {
          _id: drugTimeFrequencyNew.id,
          code: drugTimeFrequencyNew.code,
          nameEn: drugTimeFrequencyNew.nameEn,
          nameTh: drugTimeFrequencyNew.nameTh,
        },
      };
      dispatch(Booking.addPrescriptionItem(booking.current.id, medicine));
      setMedicines((prev) => [...prev, medicine]);
    }
  };

  const initialData = () => {
    const { roles, id } = jwtDecode(localStorage.getItem("token"));
    const unHash = JSON.parse(decryptHelper(match.params.id));
    const { bookingId, date, country, specialty } = unHash;
    dispatch(Booking.getBooking(bookingId));
    if (roles[0] === "doctor") {
      dispatch(DoctorReferral.getReferralsByBookingId(bookingId));
    }

    dispatch(AppUserOma.getUser(id));
    if (["doctor", "communityPharmacist"].includes(roles[0])) {
      dispatch(AppUserOma.getProfile(id));
    }
    dispatch(PractitionerSpecialties.getAllPractitionerSpecialties());
    dispatch(DrugUnit.getDrugUnits());
    setCountry(country);
    setSpecialty(specialty);
  };

  const getDrugTimeEventInfo = (code) => {
    const drugTimeInfo = drugTimeEvent.data.find((drug) => drug.code === code);
    return {
      code: drugTimeInfo.code,
      createdAt: drugTimeInfo.createdAt,
      nameEn: drugTimeInfo.nameEn,
      nameTh: drugTimeInfo.nameTh,
      _id: drugTimeInfo.id,
    };
  };

  const getDrugTimeFrequencyInfo = (code) => {
    const drugTimeInfo = drugTimeFrequency.data.find(
      (drug) => drug.code === code
    );
    return {
      code: drugTimeInfo.code,
      createdAt: drugTimeInfo.createdAt,
      nameEn: drugTimeInfo.nameEn,
      nameTh: drugTimeInfo.nameTh,
      _id: drugTimeInfo.id,
    };
  };

  const getDrugRouteInfo = (id, drugRoutes) => {
    const drugTimeInfo = drugRoutes.find((route) => route.id === id);
    return {
      createdAt: drugTimeInfo.createdAt,
      _id: drugTimeInfo.id,
      routeEn: drugTimeInfo.routeEn,
      routeTh: drugTimeInfo.routeTh,
      topicalRoute: drugTimeInfo.topicalRoute,
    };
  };

  const getDrugUnitInfo = (id) => {
    const drugUnitInfo = drugUnit.data.find((unit) => unit.id === id);
    return {
      _id: drugUnitInfo.id,
      name: drugUnitInfo.name,
      nameTh: drugUnitInfo.nameTh,
      createdAt: drugUnitInfo.createdAt,
    };
  };

  const handleOpenNotification = () => {
    notification.warning({
      message: t("enter_color_warning"),
      description: t("enter_color_warning_desp"),
      placement: "topRight",
    });
  };

  const updateColorInQuestionnaire = () => {
    if (booking.current?.covidForm?.id) {
      dispatch(
        Questionaires.updateQuestionaireById(booking.current.covidForm.id, {
          questionAnswers: {
            reports: [{ ...booking.current.covidForm, color, note }],
          },
          result: color,
        })
      );
    }
  };

  const handleUpdateBooking = () => {
    setIsFinishInitialLoad(false);
    if (roomType === "covid") {
      updateColorInQuestionnaire();
    }
    const data =
      roomType === "covid"
        ? { covidForm: { ...booking.current.covidForm, color }, notes: note }
        : { notes: note };
    dispatch(Booking.updateBooking(booking.current.id, true, { ...data }));
    setVisibleSummaryNoteModal(false);
  };

  const handleRedirect = () => {
    let path = "/homepage";
    if (appUserOma.information.roles[0].name === "callCenter") {
      path = "/support-dashboard";
    } else if (
      booking.current.bookingCategory === "general" &&
      booking.current.bookingType === "Telepharmacy"
    ) {
      path = "/dashboard-map";
    } else if (appUserOma.information.roles[0].name === "pharmacy") {
      path = "/round-robin/general";
    } else if (booking.current.bookingCategory === "scheduling") {
      path = "/lobby/telemedicine";
    }
    history.push(path);
  };

  const handleRedirectToDeliverDrug = () => {
    const unHash = JSON.parse(decryptHelper(match.params.id));
    const bookingIdHash = hashHelper(unHash.bookingId);
    dispatch(
      Firebase.updateRoomByCountryAndSpecialty(
        country,
        specialty,
        { status: "COMMUNITY_PHARMACIST_WAITING_DELIVERY" },
        booking.current.patientId
      )
    );
    history.push(`/deliver-drug/${bookingIdHash}`);
  };

  const handleRedirectToComplete = () => {
    if (
      roomType === "covid" &&
      !booking.current.covidForm?.color &&
      questionaires.updateData?.id !== booking.current.covidForm?.id
    ) {
      handleOpenNotification();
      setVisibleSummaryNoteModal(true);
      return;
    }
    const statusUpdate = "COMPLETED";
    let bookingCategory = booking.current.bookingCategory.toUpperCase();
    let roleUpperCase = appUserOma.information.roles[0].name.toUpperCase();
    if (appUserOma.information.roles[0].name === "callCenter") {
      if (booking.current.status === "DOCTOR_PENDING_NOTE") {
        roleUpperCase = "DOCTOR";
        bookingCategory = "DOCTOR";
      } else if (
        booking.current.status === "COMMUNITY_PHARMACIST_PENDING_NOTE"
      ) {
        roleUpperCase = "COMMUNITY_PHARMACIST";
        bookingCategory = "COMMUNITY_PHARMACIST";
      }
    }
    updateFirebaseStatus(roleUpperCase, bookingCategory, statusUpdate);
    updateBookingStatus(roleUpperCase, statusUpdate);
    sendNotification(statusUpdate);
    handleRedirect();
  };

  const handleRedirectToDashboard = () => {
    history.push("/dashboard-map");
  };

  const handleRedirectToCommunityPharmacist = () => {
    if (
      roomType === "covid" &&
      !booking.current.covidForm?.color &&
      questionaires.updateData?.id !== booking.current.covidForm?.id
    ) {
      handleOpenNotification();
      setVisibleSummaryNoteModal(true);
      return;
    }
    const statusUpdate = "COMPLETED";
    let bookingCategory = booking.current.bookingCategory.toUpperCase();
    let roleUpperCase = appUserOma.information.roles[0].name.toUpperCase();
    if (appUserOma.information.roles[0].name === "callCenter") {
      if (booking.current.status === "DOCTOR_PENDING_NOTE") {
        roleUpperCase = "DOCTOR";
        bookingCategory = "DOCTOR";
      } else if (
        booking.current.status === "COMMUNITY_PHARMACIST_PENDING_NOTE"
      ) {
        roleUpperCase = "COMMUNITY_PHARMACIST";
        bookingCategory = "COMMUNITY_PHARMACIST";
      }
    }
    updateFirebaseStatus(roleUpperCase, bookingCategory, statusUpdate);
    updateBookingStatus(roleUpperCase, statusUpdate);
    sendNotification(statusUpdate);
    const bookingData = {
      status: "COMMUNITY_PHARMACIST_PENDING",
      treatmentId: booking.current.treatmentId,
      patientId: booking.current.patientId,
      bookingCategory:
        booking.current && booking.current.bookingCategory
          ? booking.current.bookingCategory
          : null,
      prescription: medicines.map((medicine) => ({
        ...medicine,
        createdAt: new Date(),
      })),
    };
    dispatch(
      Treatment.createBookingByTreatment(
        booking.current.treatmentId,
        bookingData
      )
    );
    handleRedirect();
  };

  const handleRedirectToDecline = () => {
    const statusUpdate = "DECLINED";
    let bookingCategory = booking.current.bookingCategory.toUpperCase();
    let roleUpperCase = appUserOma.information.roles[0].name.toUpperCase();
    if (appUserOma.information.roles[0].name === "communityPharmacist") {
      roleUpperCase = "COMMUNITY_PHARMACIST";
      bookingCategory = "COMMUNITY_PHARMACIST";
    } else if (appUserOma.information.roles[0].name === "callCenter") {
      if (booking.current.status === "DOCTOR_PENDING_NOTE") {
        roleUpperCase = "DOCTOR";
        bookingCategory = "DOCTOR";
      } else if (
        booking.current.status === "COMMUNITY_PHARMACIST_PENDING_NOTE"
      ) {
        roleUpperCase = "COMMUNITY_PHARMACIST";
        bookingCategory = "COMMUNITY_PHARMACIST";
      }
    }
    updateFirebaseStatus(roleUpperCase, bookingCategory, statusUpdate);
    updateBookingStatus(roleUpperCase, statusUpdate);
    sendNotification(statusUpdate);
    handleRedirect();
  };

  const updateFirebaseStatus = (role, bookingCategory, statusUpdate) => {
    if (country && specialty) {
      dispatch(
        Firebase.updateRoomByCountryAndSpecialty(
          country,
          specialty,
          { status: `${role}_${statusUpdate}` },
          booking.current.patientId
        )
      );
    } else {
      dispatch(
        Firebase.updateRoom(
          bookingCategory,
          appUserOma.information.id,
          booking.current.patientId,
          {
            status: `${role}_${statusUpdate}`,
          },
          role
        )
      );
    }
  };

  const updateBookingStatus = (role, statusUpdate) => {
    dispatch(
      Booking.updateBooking(booking.current.id, true, {
        status: `${role}_${statusUpdate}`,
      })
    );
  };

  const sendNotification = (status) => {
    dispatch(
      UserFcmToken.sendNotification(
        booking.current.patientId,
        NOTIFICATION[status],
        NOTIFICATION_DATA(status, booking.current.admitTime)
      )
    );
  };

  const updatePrescriptionItem = (prescriptionItemId, medicine) => {
    dispatch(
      Booking.updatePrescriptionItem(
        booking.current.id,
        prescriptionItemId,
        medicine
      )
    );
  };

  return isFinishInitialLoad ? (
    <Summary
      booking={booking}
      roomType={roomType}
      userOma={booking.current.patient}
      color={color}
      setColor={setColor}
      role={appUserOma.information.roles[0].name}
      organization={appUserOma?.organization?.data[0]}
      note={note}
      setNote={setNote}
      medicines={medicines}
      prescription={booking.prescription}
      drugUnits={drugUnit.data}
      drugTimeEvent={drugTimeEvent.data}
      drugTimeFrequency={drugTimeFrequency.data}
      setMedicines={setMedicines}
      medication={medication}
      handleSelectMedicine={handleSelectMedicine}
      visibleHistoryPatientDetailModal={visibleHistoryPatientDetailModal}
      setVisibleHistoryPatientDetailModal={setVisibleHistoryPatientDetailModal}
      visibleSummaryNoteModal={visibleSummaryNoteModal}
      setVisibleSummaryNoteModal={setVisibleSummaryNoteModal}
      visibleDoctorReferralModal={visibleDoctorReferralModal}
      setVisibleDoctorReferralModal={setVisibleDoctorReferralModal}
      handleUpdateBooking={handleUpdateBooking}
      handleRedirect={handleRedirect}
      handleRedirectToDeliverDrug={handleRedirectToDeliverDrug}
      handleRedirectToDecline={handleRedirectToDecline}
      visiblePrescriptionList={visiblePrescriptionList}
      setVisiblePrescriptionList={setVisiblePrescriptionList}
      deleteDrugFromPrescription={deleteDrugFromPrescription}
      getDrugTimeFrequencyInfo={getDrugTimeFrequencyInfo}
      getDrugTimeEventInfo={getDrugTimeEventInfo}
      getDrugRouteInfo={getDrugRouteInfo}
      getDrugUnitInfo={getDrugUnitInfo}
      handleRedirectToComplete={handleRedirectToComplete}
      handleRedirectToCommunityPharmacist={handleRedirectToCommunityPharmacist}
      handleRedirectToDashboard={handleRedirectToDashboard}
      onSearch={onSearch}
      t={t}
      updatePrescriptionItem={updatePrescriptionItem}
      doctorReferralTypeList={DOCTOR_REFER_TYPE_LIST}
      doctorReferralType={DOCTOR_REFER_TYPE}
      practitionerSpecialties={practitionerSpecialties}
      practitioners={practitioners}
      selectDoctorReferTypeHandler={selectDoctorReferTypeHandler}
      selectDoctorReferSpecialtyHandler={selectDoctorReferSpecialtyHandler}
      doctorReferType={doctorReferType}
      doctorReferSpecialty={doctorReferSpecialty}
      createDoctorReferral={createDoctorReferral}
      clinic={clinic}
      clinicPackages={clinicPackages}
      doctorReferral={doctorReferral}
      setDoctorReferSpecialtyId={setDoctorReferSpecialtyId}
      deleteDoctorReferral={deleteDoctorReferral}
    />
  ) : (
    <Loading />
  );
};

export default SummaryContainer;
