export const NOTIFICATION = {
  COMPLETED: (language = "th") => ({
    body:
      language === "en"
        ? "The doctor has completed an assessment of the symptoms"
        : "แพทย์ได้ทำการประเมินอาการเรียบร้อยแล้ว",
    title: language === "en" ? "Alert" : "แจ้งเตือน",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
  DECLINED: (language = "th") => ({
    body:
      language === "en"
        ? "Your appointment has been cancelled"
        : "รายการถูกยกเลิกโดยเจ้าหน้าที่",
    title: language === "en" ? "Alert" : "แจ้งเตือน",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
};

export const NOTIFICATION_DATA = (key, admitTime) => {
  const returnData = {
    COMPLETED: {
      time: admitTime ? new Date(admitTime) : null,
      page: "Booking",
    },
    DECLINED: {
      page: "TeleSymptom",
    },
  };
  return returnData[key];
};

export const DOCTOR_REFER_TYPE_LIST = ["CLINIC", "TREATMENT", "DOCTOR"];
export const DOCTOR_REFER_TYPE = {
  CLINIC: "CLINIC",
  TREATMENT: "TREATMENT",
  DOCTOR: "DOCTOR",
};
