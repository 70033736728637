import * as type from "../types";

export function confirmPayment(id) {
  return {
    type: type.CONFIRM_PAYMENT_REQUESTED,
    id,
  };
}

export function rejectPayment(id) {
  return {
    type: type.REJECT_PAYMENT_REQUESTED,
    id,
  };
}
