import firebase from "firebase";
import jwtDecode from "jwt-decode";
import { eventChannel } from "redux-saga";
import { initializeFirebase } from "../services/push-notification";

const specialties = [
  {
    id: "gp-th-prime",
    data: {
      active: true,
      careType: {
        localeName: "ปรึกษาหมอทั่วไป",
        spCode: "gp-th-prime",
        specialties: "General Practitioner",
      },
      country: "Thailand",
      description: "15 - 30 นาที ปรึกษาแพทย์ผู้มีประสบการณ์มากกว่า 10 ปี",
      grade: "prime",
      name: "EverX",
      price: "500",
      receive: ["คำปรึกษา", "แนะนำยา", "แนะนำการรักษา"],
      waitingTime: "รับสายภายใน 5 - 15 นาที",
    },
  },
  {
    id: "fc-th",
    data: {
      active: true,
      careType: {
        localeName: "ปรึกษาหมอกับแคมเปญโรคอ้วน",
        spCode: "fc-th",
        specialties: "Fat Campaign",
      },
      country: "Thailand",
      description: "15 - 30 นาที ปรึกษาแพทย์ผู้มีประสบการณ์มากกว่า 10 ปี",
      grade: "prime",
      name: "EverX",
      price: "500",
      receive: ["คำปรึกษา", "แนะนำยา", "แนะนำการรักษา"],
      waitingTime: "รับสายภายใน 5 - 15 นาที",
    },
  },
  {
    id: "psycho-th",
    data: {
      active: true,
      careType: {
        localeName: "ปรึกษาหมอจิตวิทยา",
        spCode: "psycho-th",
        specialties: "Psychology",
      },
      country: "Thailand",
      description: "15 - 30 นาที ปรึกษาแพทย์ผู้มีประสบการณ์มากกว่า 10 ปี",
      grade: "prime",
      name: "EverX",
      price: "500",
      receive: ["คำปรึกษา", "แนะนำยา", "แนะนำการรักษา"],
      waitingTime: "รับสายภายใน 5 - 15 นาที",
    },
  },
];

initializeFirebase();
signInFirebase();

async function getApi(method, roomType, practitionerId, patientId, role) {
  let path = "";
  const database = firebase.database();
  switch (roomType) {
    case "SCHEDULING": {
      if (role.toUpperCase() === "DOCTOR") {
        path = `rooms/practitioners/practitioner${practitionerId}/patients`;
      } else {
        path = `rooms/telemed/pharmacy/patients/`;
      }
      break;
    }
    case "COVID":
      path = `rooms/roundRobin/covid/patients`;
      break;
    case "GENERAL":
      path = `rooms/roundRobin/general/patients`;
      break;
    case "COMMUNITY_PHARMACIST":
      path = `rooms/roundRobin/communityPharmacist/patients`;
      break;
  }
  if (patientId !== null && patientId !== 0) {
    path += `/patient${patientId}`;
  }
  return database.ref(path);
}

async function getRooms({ roomType, practitionerId, role, patientId }) {
  const roleName = role ?? "";
  const ref = await getApi(
    "GET",
    roomType,
    practitionerId,
    patientId,
    roleName
  );
  const snapshot = await ref.once("value");
  return snapshot.val();
}

async function getRoomByCountryAndSpecialty() {
  const countries = ["thailand"];
  const status = "DOCTOR_PENDING";

  let arrayOfRooms = [];

  for (let country of countries) {
    for (let specialty of specialties) {
      const { careType } = specialty.data;
      if (careType !== undefined) {
        const room = firebase
          .database()
          .ref(`rooms/roundRobin/${country}/${careType.spCode}/patients`);
        const snapshot = await room.once("value");
        const rooms = snapshot.val();
        if (rooms) {
          const filterRoomsAndConvertToArray = Object.keys(rooms)
            .filter(
              (roomId) =>
                rooms[roomId].status === status &&
                rooms[roomId].date &&
                rooms[roomId].date + 60 * 60 * 1000 > Date.now()
            )
            .map((roomId) => ({
              ...rooms[roomId],
              roomId,
              country: country,
              specialty: {
                id: specialty.id,
                ...specialty.data,
              },
            }));

          arrayOfRooms = [...arrayOfRooms, ...filterRoomsAndConvertToArray];
        }
      }
    }
  }
  const sortRoomsByDate = arrayOfRooms.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  return sortRoomsByDate;
}

async function updateRoomByCountryAndSpecialty(props) {
  const { country, specialty, detail, patientId } = props;
  const room = await firebase
    .database()
    .ref(
      `rooms/roundRobin/${country}/${specialty}/patients/patient${patientId}`
    );
  await room.update(detail);
}

async function updateRoom({
  roomType,
  practitionerId,
  patientId,
  detail,
  role,
}) {
  const ref = await getApi("UPDATE", roomType, practitionerId, patientId, role);
  await ref.update(detail);
}

async function createEventChannelCommunityPharmacist() {
  const ref = await firebase
    .database()
    .ref("rooms/roundRobin/communityPharmacist/patients");
  const listener = eventChannel((emit) => {
    ref.on("value", (snapshot) => {
      if (snapshot.exists()) {
        return emit(snapshot.val());
      }
    });
    return () => ref.off(listener);
  });
  return listener;
}

async function createEventChannel() {
  const ref = firebase.database().ref("rooms/roundRobin/thailand");
  const listener = eventChannel((emit) => {
    ref.on("value", (snapshot) => {
      if (snapshot.exists()) {
        return emit(snapshot.val());
      }
    });
    return () => ref.off(listener);
  });
  return listener;
}

async function createEventChannelTelemedicine() {
  const ref = firebase.database().ref(`rooms/practitioners`);
  const listener = eventChannel((emit) => {
    ref.on("value", (snapshot) => {
      if (snapshot.exists()) {
        return emit(snapshot.val());
      }
    });
    return () => ref.off(listener);
  });
  return listener;
}

function signInFirebase() {
  const firebaseAccount = process.env.REACT_APP_FIREBASE_AUTH_ACCOUNT;
  const firebasePassword = process.env.REACT_APP_FIREBASE_AUTH_PASSWORD;
  firebase
    .auth()
    .signInWithEmailAndPassword(firebaseAccount, firebasePassword)
    .catch((error) => {
      console.warn(`${error.code} ${error.message}`);
    });
}

async function confirmPayment(id) {
  const ref = firebase.database().ref(`payments/${id}`);
  await ref.update({ status: "successful" });
}

export {
  getRooms,
  getRoomByCountryAndSpecialty,
  updateRoomByCountryAndSpecialty,
  updateRoom,
  createEventChannel,
  createEventChannelCommunityPharmacist,
  createEventChannelTelemedicine,
  confirmPayment,
};
