import * as type from "../types";

export function getPractitionerBySpecialties(filter) {
  return {
    type: type.GET_PRACTITIONERS_BY_SPECIALTIES_REQUESTED,
    filter,
  };
}

export function getTimeSlotsByPractitionerId(id) {
  return {
    type: type.GET_TIME_SLOTS_BY_PRACTITIONER_ID_REQUESTED,
    id,
  };
}
