import { call, put, takeEvery } from "redux-saga/effects";
import { DrugUnit } from "../../api";
import * as type from "../types";

function* getDrugUnits() {
  try {
    const result = yield call(DrugUnit.getDrugUnits);
    yield put({ type: type.GET_DRUG_UNITS_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: type.GET_DRUG_UNITS_FAILED, error: e.message });
  }
}

function* drugUnitSaga() {
  yield takeEvery(type.GET_DRUG_UNITS_REQUESTED, getDrugUnits);
}

export default drugUnitSaga;
