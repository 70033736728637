import * as type from "../types";

export function getOrganizations(filter = "") {
  return {
    type: type.GET_ORGANIZATIONS_REQUESTED,
    filter,
  };
}

export function getUsersByOrganization(id) {
  return {
    type: type.GET_USERS_BY_ORGANIZATION_REQUESTED,
    id,
  };
}

export function getSubmissionList(id) {
  return {
    type: type.GET_ORGANIZATIONS_SUBMISION_LIST_REQUESTED,
    id,
  };
}

export function updateSubmissionList(id, option, detail) {
  return {
    type: type.UPDATE_ORGANIZATIONS_SUBMISION_LIST_REQUESTED,
    id,
    option,
    detail,
  };
}

export function getCommunityPharmaciesByOrganization(id) {
  return {
    type: type.GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_REQUESTED,
    id,
  };
}
