import { Layout, Modal, Button } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import EverHeader from "../../components/Header";
import { Menu, AppUserOma } from "../../redux/actions";
import { Container } from "./styles";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import "./style.css";

const { Header } = Layout;

const Home = (props) => {
  const { t } = useTranslation();
  const { render, history } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.appUserOma);
  const menu = useSelector((state) => state.menu);

  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  const [visibleLastRoomModal, setVisibleLastRoomModal] = useState(false);
  const [hash, setHash] = useState(null);
  const userInfo = user && user.information;
  const userOrg = user && user.organization?.data;
  const isLoading = !(menu && menu.data && menu.data.length && menu.current);
  const { id, roles } = jwtDecode(localStorage.getItem("token"));

  useEffect(() => {
    checkIsRooms();
  }, []);

  useEffect(() => {
    if (!user.information) {
      dispatch(AppUserOma.getUser(id));
      if (["doctor", "communityPharmacist"].includes(roles[0])) {
        dispatch(AppUserOma.getProfile(id));
      }
    }
    if (user.organization.isSuccess === false) {
      dispatch(AppUserOma.getOrganizationsUser(id));
    }
  }, []);

  useEffect(() => {
    if (userInfo && userOrg && menu.data.length === 0) {
      fetchUser();
    }
  }, [user, menu]);

  useEffect(() => {
    if (menu && menu.data && menu.data.length) {
      if (!localStorage.getItem("menu") && !menu.current) {
        checkCurrentMenu();
      } else if (!menu.current) {
        const data = JSON.parse(localStorage.getItem("menu"));
        dispatch(Menu.setMenu(data));
        history.push(`/${data.value}`);
      }
    }
  }, [menu]);

  const checkIsRooms = () => {
    const roomHash = localStorage.getItem("rooms");
    if (roomHash !== null) {
      setVisibleLastRoomModal(true);
      setHash(roomHash);
    }
  };

  const checkCurrentMenu = () => {
    const { data } = menu;
    localStorage.setItem("menu", JSON.stringify(data[0]));
    dispatch(Menu.setMenu(data[0]));
    data[0].onClick();
  };

  const fetchUser = async () => {
    const isEverOrg = userOrg.filter((org) => org.name === "Ever").length > 0;
    const sidebarOptions = await getSidebarOptions(isEverOrg);
    dispatch(Menu.getMenus(sidebarOptions));
  };

  const getSidebarOptions = (isEverOrg) => {
    const optionFilter = [
      {
        label: t("homepage"),
        icon: "home",
        value: "homepage",
        onClick: () => history.push("/homepage"),
      },
      {
        label: t("support_dashboard"),
        icon: "home",
        value: "support-dashboard",
        onClick: () => history.push("/support-dashboard"),
      },
      {
        label: t("dashboard_map"),
        icon: "home",
        value: "dashboard-map",
        onClick: () => history.push("/dashboard-map"),
      },
      {
        label: t("clinic_trial"),
        icon: "home",
        value: "clinic-trial",
        onClick: () => history.push("/clinic-trial"),
      },
      {
        label: t("clinic_trial_document"),
        icon: "folder",
        value: "clinic-document",
        onClick: () => history.push("/clinic-document"),
      },
      {
        label: t("scheduling"),
        icon: "inbox",
        value: "booking",
        onClick: () => history.push("/booking"),
      },
      {
        label: t("history_booking"),
        icon: "inbox",
        value: "history-booking",
        onClick: () => history.push("/history-booking"),
      },
      {
        label: t("covid_dashboard"),
        icon: "inbox",
        value: "dashboard-covid",
        onClick: () => history.push("/dashboard-covid"),
      },
      {
        label: t("telemedicine_room"),
        icon: "inbox",
        value: "lobby/telemedicine",
        onClick: () => history.push("/lobby/telemedicine"),
      },
      {
        label: t("covid_room"),
        icon: "inbox",
        value: "round-robin/covid",
        onClick: () => history.push("/round-robin/covid"),
      },
      {
        label: t("round_robin"),
        icon: "inbox",
        value: "round-robin",
        onClick: () => history.push("/round-robin"),
      },
      {
        label: t("doctor_lobby"),
        icon: "inbox",
        value: "doctor-lobby",
        onClick: () => history.push("/doctor-lobby"),
      },
      {
        label: t("general_room"),
        icon: "inbox",
        value: "round-robin/general",
        onClick: () => history.push("/round-robin/general"),
      },
      {
        label: t("monitor_lobby"),
        icon: "inbox",
        value: "monitor-lobby",
        onClick: () => history.push("/monitor-lobby"),
      },
      {
        label: t("user_list"),
        icon: "inbox",
        value: "userlist",
        onClick: () => history.push("/userlist"),
      },
      {
        label: t("submission_list"),
        icon: "inbox",
        value: "submissionlist",
        onClick: () => history.push("/submissionlist"),
      },
      {
        label: t("register"),
        icon: "inbox",
        value: "register",
        onClick: () => history.push("/register"),
      },
      {
        label: t("partner_earning"),
        icon: "inbox",
        value: "partner-earnings",
        onClick: () => history.push("/partner-earnings"),
      },
      {
        label: t("earning"),
        icon: "inbox",
        value: "earning",
        onClick: () => history.push("/earning"),
      },
      {
        label: t("settings"),
        icon: "setting",
        value: "settings",
        onClick: () => history.push("/settings"),
      },
      {
        label: t("logout"),
        icon: "inbox",
        value: "logout",
        onClick: () => {
          history.push("/logout");
        },
      },
    ];
    if (
      get(user.organization, "data", [])
        .map((item) => item.name)
        .includes("National Cancer Institute (NCI)") &&
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("callCenter")
    ) {
      return optionFilter.filter((option) =>
        ["submissionlist", "logout"].includes(option.value)
      );
    }
    // if (
    //   get(user.organization, "data", [])
    //     .map((item) => item.name)
    //     .includes("National Cancer Institute (NCI)")
    // ) {
    //   return optionFilter.filter((option) =>
    //     ["clinic-trial", "clinic-document", "logout"].includes(option.value)
    //   );
    // }

    if (
      user.information.practitioner &&
      user.information.practitioner.tagSearch.includes("CTMS")
    ) {
      return optionFilter.filter((option) =>
        ["clinic-trial", "clinic-document", "logout"].includes(option.value)
      );
    }

    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("doctor")
    ) {
      let lobby = [];
      if (isEverOrg) {
        lobby = [
          "homepage",
          "booking",
          "lobby/telemedicine",
          "partner-earnings",
          "settings",
          "logout",
        ];
      } else {
        lobby = ["booking", "lobby/telemedicine", "settings", "logout"];
      }
      return optionFilter.filter((option) => lobby.includes(option.value));
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("nurse")
    ) {
      let lobby = [];
      if (isEverOrg) {
        lobby = [
          "homepage",
          "booking",
          "lobby/telemedicine",
          "round-robin",
          "logout",
        ];
      } else {
        lobby = ["booking", "lobby/telemedicine", "logout"];
      }
      return optionFilter.filter((option) => lobby.includes(option.value));
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("callCenter")
    ) {
      return optionFilter.filter((option) =>
        [
          "support-dashboard",
          "userlist",
          "booking",
          "register",
          "submissionlist",
          "logout",
        ].includes(option.value)
      );
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("pharmacy")
    ) {
      let lobby = [];
      if (isEverOrg) {
        lobby = [
          "homepage",
          "booking",
          "lobby/telemedicine",
          "round-robin/general",
          "logout",
        ];
      } else {
        lobby = ["booking", "lobby/telemedicine", "logout"];
      }
      return optionFilter.filter((option) => lobby.includes(option.value));
    }
    if (
      get(user.information, "roles", [])
        .map((item) => item.name)
        .includes("communityPharmacist")
    ) {
      return optionFilter.filter((option) =>
        ["dashboard-map", "earning", "logout"].includes(option.value)
      );
    }
  };
  return isLoading ? (
    <Loading />
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <EverHeader />
      <Header>
        {isOpenSidebar && <Sidebar options={menu} history={history} />}
      </Header>
      <Container>
        {render({
          userInfo,
          history,
        })}
      </Container>
      <Modal
        width={600}
        title={t("last_consultation")}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setVisibleLastRoomModal(false);
              history.push(`/rooms/${hash}`);
            }}
          >
            {t("join_room")}
          </Button>,
        ]}
        visible={visibleLastRoomModal}
      >
        {t("last_consultation_detail")}
      </Modal>
    </Layout>
  );
};

export default Home;
