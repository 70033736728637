import firebase from "firebase/app";
import "firebase/database";
import constant from "../constant";

const config = {
  apiKey: "AIzaSyBDLYwoxv5GtDACl-UE5M4aaIN77Q1MYno",
  authDomain: "ever-oma.firebaseapp.com",
  databaseURL: "https://ever-oma.firebaseio.com",
  projectId: "ever-oma",
  storageBucket: "ever-oma.appspot.com",
  messagingSenderId: "774849206355",
  appId: "1:774849206355:web:f61642ede0031c5ed98ea6",
  measurementId: "G-L2VHJ7R1R7",
};

// const secondaryAppConfig = {
//   apiKey: "AIzaSyAoeGxv7rLZdQcIs-xu05eaoZtipgNQpcM",
//   authDomain: "vajira-smart-hospital.firebaseapp.com",
//   databaseURL: "https://vajira-smart-hospital.firebaseio.com",
//   projectId: "vajira-smart-hospital",
//   storageBucket: "vajira-smart-hospital.appspot.com",
//   messagingSenderId: "564454725768",
//   measurementId: "G-91W4NPBZJ5",
// };

// const config = {
//   apiKey: 'AIzaSyAOi1Sk0W59_qeCJqYc2Fi27nD58qguu0M',
//   authDomain: 'test-project-cd3f5.firebaseapp.com',
//   databaseURL: 'https://test-project-cd3f5.firebaseio.com',
//   projectId: 'test-project-cd3f5',
//   storageBucket: 'test-project-cd3f5.appspot.com',
//   messagingSenderId: '942446619054',
//   appId: '1:942446619054:web:f27375d1d555acd0a6403f',
//   measurementId: 'G-MZ3VGL2XL0'
// }

export const initializeFirebase = () => {
  firebase.initializeApp(config);
  // return firebase.messaging()
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      localStorage.setItem(constant.PUSH_TOKEN, token);
      return null;
    }
  } catch (error) {
    throw error;
  }
};

export const getMessaging = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    return messaging;
  }
};
