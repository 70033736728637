import { Card, Steps, Button } from "antd";
import styled from "styled-components";

export const CardMap = styled(Card)`
  border-radius: 25px !important;
  .ant-card-body {
    padding: 0px !important;
  }
`;

export const SummaryContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

export const SummaryContent = styled.div`
  max-width: 1440px;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const StepsContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummarySteps = styled(Steps)`
  max-width: 800px;
  line-height: 0px;
  .ant-steps-item-container {
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #b5b5b5;
    top: -10px;
    height: 2px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #b5b5b5;
    top: -10px;
    height: 2px;
  }
`;

export const StepsCard = styled("div")`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #cecece;
  padding: 12px 10px;
  border-radius: 20px;
`;

export const CardStyled = styled(Card)`
  &&& {
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  }
`;

export const ButtonStyled = styled(Button)`
  &&& {
    margin: 5px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 700;
    height: 100%;
  }
`;
