import React from "react";
import { Typography, Row, Col, Divider } from "antd";
import { formatter } from "../../common/format";
import RoomList from "../RoomList";

const { Title, Paragraph } = Typography;
const DoctorLobby = (props) => {
  const {
    t,
    booking,
    totalPrice,
    totalCompleteCase,
    totalCase,
    acceptanceRate,
    totalCancelCase,
    rooms,
    role,
    title,
    treatment,
    visibleCallModal,
    setVisibleCallModal,
    visibleCovidModal,
    setVisibleCovidModal,
    visibleHistoryBookingModal,
    setVisibleHistoryBookingModal,
    handleOpenCallModal,
    fetchBookingByTreatmentId,
    handleAcceptCall,
  } = props;
  return (
    <div className="mt-5" style={{ width: "100%", padding: "0px 300px" }}>
      <div className="px-5">
        <Paragraph>{t("today_earnings")}</Paragraph>
        <Title level={2} className="mt-1">
          {formatter.format(totalPrice)}
        </Title>
      </div>
      <Divider />
      <Row gutter={24} className="px-5">
        <Col>
          <Title level={5}>{totalCompleteCase}</Title>
          <Paragraph>{t("completed")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col>
          <Title level={5}>{totalCancelCase}</Title>
          <Paragraph>{t("cancellations")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col>
          <Title level={5}>{totalCase}</Title>
          <Paragraph>{t("total_case")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col>
          <Title level={5}>{acceptanceRate}%</Title>
          <Paragraph>{t("acceptance_rate")}</Paragraph>
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
      </Row>
      <Divider />
      <RoomList
        rooms={rooms}
        roomType={""}
        booking={booking}
        role={role}
        visibleCallModal={visibleCallModal}
        visibleCovidModal={visibleCovidModal}
        visibleHistoryBookingModal={visibleHistoryBookingModal}
        treatment={treatment}
        title={title}
        setVisibleCallModal={setVisibleCallModal}
        setVisibleCovidModal={setVisibleCovidModal}
        setVisibleHistoryBookingModal={setVisibleHistoryBookingModal}
        handleOpenCallModal={handleOpenCallModal}
        fetchBookingByTreatmentId={fetchBookingByTreatmentId}
        handleAcceptCall={handleAcceptCall}
        t={t}
      />
    </div>
  );
};

export default DoctorLobby;
