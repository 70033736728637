import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  data: null,
  organizations: {
    userData: [],
    submissionList: [],
    isSuccess: false,
  },
  user: {
    isSuccess: false,
    data: null,
    error: null,
    loading: false,
  },
  communityPharmacy: {
    isSuccess: false,
    data: null,
    error: null,
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_ORGANIZATIONS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case type.GET_ORGANIZATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case type.GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_REQUESTED:
      return {
        ...state,
        communityPharmacy: {
          loading: true,
          error: null,
        },
      };
    case type.GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        communityPharmacy: {
          data: action.data,
          isSuccess: true,
          loading: false,
        },
      };
    case type.GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_FAILED:
      return {
        ...state,
        communityPharmacy: {
          loading: false,
          isSuccess: false,
          error: action.error,
        },
      };
    case type.GET_USERS_BY_ORGANIZATION_REQUESTED:
      return {
        ...state,
        user: {
          loading: true,
          error: null,
          isSuccess: false,
        },
      };
    case type.GET_USERS_BY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        user: {
          loading: false,
          error: null,
          isSuccess: true,
          data: [].concat(
            ...action.data.map((item) => {
              return Array.isArray(item)
                ? item.sort((a, b) => b.id - a.id)
                : [];
            })
          ),
        },
      };
    case type.GET_USERS_BY_ORGANIZATION_FAILED:
      return {
        ...state,
        user: {
          loading: false,
          error: action.error,
          isSuccess: false,
        },
      };
    case type.GET_ORGANIZATIONS_SUBMISION_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        organizations: {
          ...state.organizations,
          isSuccess: false,
        },
      };
    case type.GET_ORGANIZATIONS_SUBMISION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: {
          submissionList: [].concat(
            ...action.data.map((item) => {
              return Array.isArray(item)
                ? item.sort((a, b) => b.id - a.id)
                : [];
            })
          ),
          userData: action.listUserOrg,
          isSuccess: true,
        },
      };
    case type.GET_ORGANIZATIONS_SUBMISION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        organizations: {
          ...state.organizations,
          isSuccess: false,
        },
      };
    case type.UPDATE_ORGANIZATIONS_SUBMISION_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        organizations: {
          ...state.organizations,
          isSuccess: false,
        },
      };
    case type.UPDATE_ORGANIZATIONS_SUBMISION_LIST_SUCCESS:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          submissionList: state.organizations.submissionList.map((item) =>
            item.id === action.data.id ? action.data : item
          ),
          isSuccess: true,
        },
        loading: false,
      };
    case type.UPDATE_ORGANIZATIONS_SUBMISION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        organizations: {
          ...state.organizations,
          isSuccess: false,
        },
      };
    default:
      return state;
  }
};
