export const CLINIC_TRIAL_FORMAT_KEY_INCLUDES = [
  "identificationNumber",
  "phoneno",
  "gender",
  "age",
  "height",
  "weight",
  "bmi",
  "pregnancy",
  "lungAbnormal",
  "examinationHistory",
  "rhinits",
  "sinus",
  "vacinated2dose",
  "covidInfected",
  "closeToPatient",
  "allergy",
  "clinicalTrial",
  "nasalSpray",
  "checkDate",
];

export const GENERAL_CONTACT_FORMAT_KEY_INCLUDES = [
  "identity",
  "gender",
  "birthDate",
  "status",
  "nationality",
  "religion",
  "education",
  "occupation",
  "address1",
  "address2",
  "addressDetail",
  "postalCode",
  "country",
  "phoneNumber",
  "email",
  "lineId",
  "createdAt",
  "subjectId",
  "eligibilityStatus",
  "productId",
  "study",
  "nasalSwab",
];

export const IN_EX_CRITERIA_FORMAT_KEY_INCLUDES = [
  "qualifyAge",
  "beingHealthy",
  "getVaccinated",
  "noCloseContactCovid",
  "negativeCovid19Test",
  "signedWrittenConsent",
  "complyWithRequirements",
  "abnormalitiesAtPhysicalExam",
  "vitalSignAbnormalities",
  "positiveUrinePregnancyTest",
  "covid19Infection",
  "allergicReaction",
  "partipationInClinicalStudy",
  "nasalProductUsage",
  "pulmonaryInfiltrate",
  "respiratotyTractAbnormalities",
  "chronicAllergicRhinitis",
  "anyReason",
];

export const ELIGIBILITY_FORMAT_KEY_INCLUDES = [
  "height",
  "weight",
  "bmi",
  "bloodPressure",
  "heartRate",
  "rr",
  "pulseOx",
  "medicationHistories",
  "underlingDiseases",
  "currentMedications",
  "covidInfectionHistory",
  "clinicalTrialCheck",
  "clinicalTrialEndDate",
  "clinicalTrialDescription",
  "physicalExamination",
  "nasopharyngealStatus",
  "nasopharyngealComment",
  "nasopharyngealDateOfSampleCollection",
  "nasopharyngealFileUrl",
  "sinuscopyPolypsLeft",
  "sinuscopyPolypsRight",
  "sinuscopyOedemaLeft",
  "sinuscopyOedemaRight",
  "sinuscopyDischargeLeft",
  "sinuscopyDischargeRight",
  "allSinus",
  "sinuscopyDischargeImageUrlLeft",
  "sinuscopyDischargeImageUrlRight",
  "cPassWuhanBefore",
  "cPassWuhanAfter",
  "cPassOmicronBefore",
  "cPassOmicronAfter",
  "cPassDeltaBefore",
  "cPassDeltaAfter",
];

export const FOLLOW_UP_FORMAT_KEY_INCLUDES = [
  "snot_blownose",
  "snot_nasalblockage",
  "snot_sneezing",
  "snot_runnynose",
  "snot_cough",
  "snot_postnasal",
  "snot_thicknasal",
  "snot_earfullness",
  "snot_dizziness",
  "snot_earpain",
  "snot_facial_pain_pressure",
  "snot_decreased_sense_smell_test",
  "snot_difficulty_fall_sleep",
  "snot_wakeup_night",
  "snot_lack_good_sleep",
  "snot_wakeup_tried",
  "snot_fatigue",
  "snot_reduce_productivity",
  "snot_reduce_concentrated",
  "snot_frustrated",
  "snot_sad",
  "snot_embarrassed",
  "affectedhealthchoices",
  "tnss_runnynose",
  "tnss_stuffynose",
  "tnss_itchynose",
  "tnss_sneeze",
  "adverse_events",
  "adverse_events_additional",
  "adverse_events_symptom",
  "druguse",
  "drugusingtime",
  "spraytwice",
];

export const EDIT_ELIGIBILITY = ["principleInvestigation", "doctor"];

export const IS_DATE = ["birthDate", "createdAt"];

export const CLINIC_TRIAL_FORMAT_INDEX_INCLUDES = [0, 7, 11, 14, 21];

export const GENERAL_CONTACT_FORMAT_INDEX_INCLUDES = [0, 8];

export const IN_EX_CRITERIA_FORMAT_INDEX_INCLUDES = [0, 7];

export const ELIGIBILITY_FORMAT_INDEX_INCLUDES = [1, 3, 7, 11, 15, 18, 26];

export const CLINIC_TRAIL_ADD_KEY_INDEX = {
  identificationNumber: 0,
  phoneno: 1,
  gender: 2,
  age: 3,
  height: 4,
  weight: 5,
  bmi: 6,
  pregnancy: 7,
  lungAbnormal: 8,
  examinationHistory: 9,
  rhinits: 10,
  sinus: 11,
  vacinated2dose: 12,
  covidInfected: 13,
  closeToPatient: 14,
  allergy: 15,
  clinicalTrial: 16,
  nasalSpray: 17,
  checkDate: 18,
};

export const EMPTY_FIELD = {
  value: "",
  style: {
    fill: { patternType: "solid", fgColor: { rgb: "bdbdbd" } },
  },
};

export const EMPTY_SNOT = [
  { value: "-", key: "snot_blownose" },
  { value: "-", key: "snot_nasalblockage" },
  { value: "-", key: "snot_sneezing" },
  { value: "-", key: "snot_runnynose" },
  { value: "-", key: "snot_cough" },
  { value: "-", key: "snot_postnasal" },
  { value: "-", key: "snot_thicknasal" },
  { value: "-", key: "snot_earfullness" },
  { value: "-", key: "snot_dizziness" },
  { value: "-", key: "snot_earpain" },
  { value: "-", key: "snot_facial_pain_pressure" },
  { value: "-", key: "snot_decreased_sense_smell_test" },
  { value: "-", key: "snot_difficulty_fall_sleep" },
  { value: "-", key: "snot_wakeup_night" },
  { value: "-", key: "snot_lack_good_sleep" },
  { value: "-", key: "snot_wakeup_tried" },
  { value: "-", key: "snot_fatigue" },
  { value: "-", key: "snot_reduce_productivity" },
  { value: "-", key: "snot_reduce_concentrated" },
  { value: "-", key: "snot_frustrated" },
  { value: "-", key: "snot_sad" },
  { value: "-", key: "snot_embarrassed" },
  { value: "-", key: "affectedhealthchoices" },
];

export const EMPTY_TNSS = [
  { value: "-", key: "tnss_runnynose" },
  { value: "-", key: "tnss_stuffynose" },
  { value: "-", key: "tnss_itchynose" },
  { value: "-", key: "tnss_sneeze" },
];

export const EMPTY_ADVERSE_EVENTS = [
  { value: "-", key: "adverse_events" },
  { value: "-", key: "adverse_events_additional" },
  { value: "-", key: "adverse_events_symptom" },
  { value: "-", key: "using_product_complicated" },
];

export const EMPTY_PRODUCT_APPLICATION = [
  { value: "-", key: "druguse" },
  { value: "-", key: "drugusingtime" },
  { value: "-", key: "spraytwice" },
];
