import http from "../services/httpServiceHealthCare";

const apiEndpoint = "/DoctorReferrals";

export async function createDoctorReferral(payload) {
  const { data } = await http.post(`${apiEndpoint}`, payload);
  return data;
}

export async function updateDoctorReferral(id, payload) {
  const { data } = await http.patch(`${apiEndpoint}/${id}`, payload);
  return data;
}

export async function deleteDoctorReferral(id) {
  const { data } = await http.delete(`${apiEndpoint}/${id}`);
  return data;
}
