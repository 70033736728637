import * as type from "../types";

export function getRoomByCountryAndSpecialty(status) {
  return {
    type: type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED,
  };
}

export function updateRoomByCountryAndSpecialty(
  country,
  specialty,
  detail,
  patientId
) {
  return {
    type: type.UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED,
    country,
    specialty,
    detail,
    patientId,
  };
}

export function getRooms(roomType, practitionerId, role, patientId) {
  return {
    type: type.GET_ROOM_TYPE[roomType].REQUESTED,
    roomType,
    practitionerId,
    role,
    patientId,
  };
}

export function updateRoom(roomType, practitionerId, patientId, detail, role) {
  return {
    type: type.UPDATE_ROOM_TYPE[roomType].REQUESTED,
    roomType,
    practitionerId,
    patientId,
    detail,
    role,
  };
}

export function setCurrentRoom(data) {
  return {
    type: type.GET_CURRENT_ROOM_REQUESTED,
    data,
  };
}

export function confirmPayment(id) {
  return {
    type: type.CONFIRM_FIREBASE_PAYMENT_REQUESTED,
    id,
  };
}
