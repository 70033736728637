import * as type from "../types";

const initialState = {
  loading: false,
  error: null,
  data: null,
  isSuccess: false,
  detail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_DOCTOR_REFERRAL_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.CREATE_DOCTOR_REFERRAL_SUCCESS:
      return {
        ...state,
        data: action.data,
        detail: action.detail,
        loading: false,
        isSuccess: true,
      };
    case type.CREATE_DOCTOR_REFERRAL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.DELETE_DOCTOR_REFERRAL_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.DELETE_DOCTOR_REFERRAL_SUCCESS:
      return {
        data: null,
        detail: null,
        loading: false,
        isSuccess: true,
      };
    case type.DELETE_DOCTOR_REFERRAL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.UPDATE_DOCTOR_REFERRAL_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        isSuccess: false,
      };
    case type.UPDATE_DOCTOR_REFERRAL_SUCCESS:
      return {
        ...state,
        data: action.data,
        detail: action.detail,
        loading: false,
        isSuccess: true,
      };
    case type.UPDATE_DOCTOR_REFERRAL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    case type.GET_REFERRALS_BY_BOOKING_ID_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
        detail: null,
        isSuccess: false,
      };
    case type.GET_REFERRALS_BY_BOOKING_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
        detail: action.detail,
        isSuccess: true,
      };
    case type.GET_REFERRALS_BY_BOOKING_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    default:
      return state;
  }
};
