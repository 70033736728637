import { call, put, takeEvery } from "redux-saga/effects";
import { Practitioner } from "../../api";
import * as type from "../types";

function* getPractitionersBySpecialties({ filter }) {
  try {
    const result = yield call(Practitioner.getPractitionersByFilter, filter);
    yield put({
      type: type.GET_PRACTITIONERS_BY_SPECIALTIES_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_PRACTITIONERS_BY_SPECIALTIES_FAILED,
      error: e.message,
    });
  }
}
function* getTimeSlotsByPractitionerId({ id }) {
  try {
    const result = yield call(Practitioner.getTimeSlots, id);
    yield put({
      type: type.GET_TIME_SLOTS_BY_PRACTITIONER_ID_SUCCESS,
      data: result[0],
    });
  } catch (e) {
    yield put({
      type: type.GET_TIME_SLOTS_BY_PRACTITIONER_ID_FAILED,
      error: e.message,
    });
  }
}

function* practitionerSaga() {
  yield takeEvery(
    type.GET_PRACTITIONERS_BY_SPECIALTIES_REQUESTED,
    getPractitionersBySpecialties
  );
  yield takeEvery(
    type.GET_TIME_SLOTS_BY_PRACTITIONER_ID_REQUESTED,
    getTimeSlotsByPractitionerId
  );
}

export default practitionerSaga;
