import React from "react";
import { Col, Row } from "antd";
import { Container, CardContainer, Text, Button } from "./styles";
import ClinicTrialParticipantList from "../ClinicTrialParticipantList";
import ClinicTrialResults from "../ClinicTrialResults";
import { FaChevronUp } from "react-icons/fa";

const ClinicTrialMainComponent = (props) => {
  const { tabIndex, setTabIndex, t } = props;

  return (
    <div
      style={{ overflowX: "scroll", width: "100%" }}
      className="pl-2 pr-5 py-4 mx-4"
    >
      <CardContainer minheight="90vh">
        <Row className="h-100">
          <Col sm={24} xs={24} md={8} lg={6} xl={4} className="border-right">
            <Container className="p-3">
              <Row align="middle" justify="space-between">
                <Text bold size="16px" color="#00000073">
                  {t("participants")}
                </Text>
                <FaChevronUp
                  color="#00000073"
                  onClick={() => {}}
                  style={{ cursor: "pointer" }}
                />
              </Row>
            </Container>
            <Row>
              <MenuButton
                index={0}
                title={t("paticipant_list")}
                tabIndex={tabIndex}
                onClick={() => setTabIndex(0)}
              />
              <MenuButton
                index={1}
                title={t("results_of_recruitment")}
                tabIndex={tabIndex}
                onClick={() => setTabIndex(1)}
              />
            </Row>
          </Col>
          <Col sm={24} xs={24} md={16} lg={18} xl={20} className="h-100">
            {tabIndex == 0 ? (
              <ClinicTrialParticipantList {...props} />
            ) : tabIndex === 1 ? (
              <ClinicTrialResults {...props} />
            ) : null}
          </Col>
        </Row>
      </CardContainer>
    </div>
  );
};

export default ClinicTrialMainComponent;

const MenuButton = ({ index, title, tabIndex, onClick }) => {
  return (
    <Button
      color={tabIndex === index ? "#F2FCFE" : "#FFF"}
      className="col-12 active"
      type="text"
      key={index}
      onClick={onClick}
    >
      <Text
        active={tabIndex === index}
        bold={tabIndex === index}
        className="text-left ml-3"
      >
        {title}
      </Text>
    </Button>
  );
};
