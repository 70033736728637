import React from "react";
import { Row, Space, Col } from "antd";
import {
  Container,
  Text,
  Icon,
  Input,
  DropDownWidget,
  TextError,
} from "../styles";
import { MdHome } from "react-icons/all";
import "../styles.css";
import { COUNTRY_LIST } from "../constant";

const ContactInformation = (props) => {
  const { t, i18n, contactForm } = props;

  const renderMenu = (key, items) => (
    <Container
      style={{
        minWidth: 250,
        backgroundColor: "white",
        maxHeight: 500,
        overflowY: "scroll",
      }}
      className="shadow px-2 py-1"
    >
      {items.map((item) => {
        return (
          <a
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              contactForm.setFieldValue(key, item.label);
            }}
          >
            <Text className="py-1 my-1" key={item.key}>
              {item.label}
            </Text>
          </a>
        );
      })}
    </Container>
  );

  return (
    <>
      <Container>
        <Space align="center">
          <Icon component={MdHome} size="30px" color="#2BA796" />
          <Text primary className="ml-2" bold size="24px">
            {t("contact_information")}
          </Text>
        </Space>
      </Container>
      <Container className="mt-4">
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} span={12}>
            <Container className="container-border container-border__tabs">
              <Text primary bold size="16px">
                {t("current_address")}
              </Text>
            </Container>
          </Col>
        </Row>
        <Container className="container-border container-boder__body px-3 py-4">
          <Row className="w-100">
            {/* <Col span={24}>
              <Space direction="horizontal">
                <Text bold size="12px">
                  {t("address")}
                </Text>
                <Text color="red">*</Text>
              </Space>
              <Input
                value={contactForm.values.address1}
                onChange={contactForm.handleChange("address1")}
                width="100%"
                placeHolder={t("enter_address")}
              />
              {contactForm.errors.address1 && (
                <TextError>{contactForm.errors.address1}</TextError>
              )}
            </Col> */}
            {/* <Col className="mt-4" span={24}>
              <Text size="12px" bold>
                {t("address")} ({t("additional")})
              </Text>
              <Input
                value={contactForm.values.address2}
                onChange={contactForm.handleChange("address2")}
                width="100%"
                placeHolder={t("enter_additional_address")}
              />
            </Col> */}
            {/* <Col className="mt-4" span={24}>
              <Space direction="horizontal">
                <Text size="12px" bold>
                  {t("full_address")}
                </Text>
                <Text color="red">*</Text>
              </Space>
              <Input
                value={contactForm.values.addressDetail}
                onChange={contactForm.handleChange("addressDetail")}
                width="100%"
                placeHolder={t("enter_full_address")}
              />
              {contactForm.errors.addressDetail && (
                <TextError>{contactForm.errors.addressDetail}</TextError>
              )}
            </Col> */}
            <Row gutter={[18, 18]} className="mt-4 w-100">
              <Col xs={24} sm={24} md={12} lg={4}>
                <Space direction="horizontal">
                  <Text size="12px" bold>
                    {t("postal_code")}
                  </Text>
                  <Text color="red">*</Text>
                </Space>
                <Input
                  value={contactForm.values.postalCode}
                  onChange={contactForm.handleChange("postalCode")}
                  width="100%"
                  placeHolder={t("enter_postal_code")}
                  maxLength={5}
                />
                {contactForm.errors.postalCode && (
                  <TextError>{contactForm.errors.postalCode}</TextError>
                )}
              </Col>
              {/* <Col xs={24} sm={24} md={8} lg={6}>
                <DropDownWidget
                  bold
                  width="100%"
                  value={contactForm.values.country}
                  key="Country"
                  title={t("country")}
                  placeholder={t("enter_country")}
                  required
                  renderList={renderMenu(
                    "country",
                    COUNTRY_LIST(i18n.language)
                  )}
                />
                {contactForm.errors.country && (
                  <TextError>{contactForm.errors.country}</TextError>
                )}
              </Col> */}
            </Row>
            <Row gutter={[18, 18]} className="mt-4 w-100 mb-3">
              <Col sm={24} md={24} lg={7} xl={7}>
                <Space direction="horizontal">
                  <Text size="12px" bold>
                    {t("mobile_number")}
                  </Text>
                  <Text color="red">*</Text>
                </Space>
                <Input
                  value={contactForm.values.phoneNumber}
                  onChange={contactForm.handleChange("phoneNumber")}
                  width="100%"
                  placeHolder={t("enter_phone_number")}
                  maxLength={10}
                />
                {contactForm.errors.phoneNumber && (
                  <TextError>{contactForm.errors.phoneNumber}</TextError>
                )}
              </Col>
              <Col sm={24} md={24} lg={10} xl={10}>
                <Space direction="horizontal">
                  <Text size="12px" bold>
                    {t("email")}
                  </Text>
                </Space>
                <Input
                  value={contactForm.values.email}
                  onChange={contactForm.handleChange("email")}
                  width="100%"
                  placeHolder={t("enter_email")}
                />
                {contactForm.errors.email && (
                  <TextError>{contactForm.errors.email}</TextError>
                )}
              </Col>
              <Col sm={24} md={24} lg={7} xl={7}>
                <Space>
                  <Text size="12px" bold>
                    {t("line_id")}
                  </Text>
                </Space>
                <Input
                  value={contactForm.values.lineId}
                  onChange={contactForm.handleChange("lineId")}
                  width="100%"
                  placeHolder={t("enter_line_id")}
                />
              </Col>
            </Row>
            <Row gutter={[18, 18]} className="mt-4 w-100 mb-3">
              <Col sm={24} md={24} lg={7} xl={7}>
                <Space direction="horizontal">
                  <Text size="12px" bold>
                    {t("emergency_contact")}
                  </Text>
                </Space>
                <Input
                  value={contactForm.values.emergencyName}
                  onChange={contactForm.handleChange("emergencyName")}
                  width="100%"
                  placeHolder={t("enter_emergency_contact")}
                  maxLength={10}
                />
              </Col>
              <Col sm={24} md={24} lg={10} xl={10}>
                <Space direction="horizontal">
                  <Text size="12px" bold>
                    {t("emergency_contact_relationship")}
                  </Text>
                </Space>
                <Input
                  value={contactForm.values.emergencyRelationship}
                  onChange={contactForm.handleChange(
                    "emergencyRelationship"
                  )}
                  width="100%"
                  placeHolder={t("enter_emergency_contact_relationship")}
                />
              </Col>
              <Col sm={24} md={24} lg={7} xl={7}>
                <Space>
                  <Text size="12px" bold>
                    {t("emergency_contact_phone_number")}
                  </Text>
                </Space>
                <Input
                  value={contactForm.values.emergencyPhoneNumber}
                  onChange={contactForm.handleChange(
                    "emergencyPhoneNumber"
                  )}
                  width="100%"
                  placeHolder={t("enter_emergency_contact_phone_number")}
                  maxLength={10}
                />
              </Col>
            </Row>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ContactInformation;
