import React from "react";
import { Row, Col, Card } from "antd";
import moment from "moment";
import PrescriptionCard from "../../Card/Prescription";
import GoogleMap from "../../../containers/GoogleMap";
import {
  ModalTransparent,
  CardMap,
  ParagraphStyle,
  ButtonCustom,
} from "./styles";

const Prescription = (props) => {
  const {
    t,
    visible,
    setVisible,
    patient,
    booking,
    handleVisibleNote,
    handleJoinLobby,
    communityPharmacy,
  } = props;
  const { id, date, prescriptionList, locationAddress } = booking;
  const fullName = `${patient.firstname} ${patient.lastname}`;
  const formatDate = moment(date).format("HH:mm");
  return (
    <ModalTransparent
      centered
      visible={visible}
      footer={null}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={1200}
      closable={false}
    >
      <Row gutter={16} align="center">
        <Col span={14}>
          <PrescriptionCard
            id={id}
            image={patient.img}
            medications={prescriptionList}
            fullName={fullName}
            date={formatDate}
            handleVisibleNote={handleVisibleNote}
          />
        </Col>
        <Col span={8}>
          <Row style={{ flexDirection: "column" }} gutter={[16, 16]}>
            <Col>
              <CardMap
                title={t("map")}
                extra={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${locationAddress.latitude},${locationAddress.longitude}`}
                  >
                    {t("link")}
                  </a>
                }
              >
                <GoogleMap
                  communityPharmacy={communityPharmacy}
                  bookings={[{ ...booking }]}
                  height="200px"
                  zoom={14}
                  radius="25px"
                  isShowUI
                />
              </CardMap>
            </Col>
            {handleJoinLobby && (
              <Col>
                <Card style={{ borderRadius: "25px" }}>
                  <ParagraphStyle size="18" bold>
                    {t("patient_is_waiting")}
                  </ParagraphStyle>
                  <ParagraphStyle size="12">
                    {t("please_check_to_see_that_you")}
                  </ParagraphStyle>
                  <ParagraphStyle size="12">
                    {t("have_all_drugs_available")}
                  </ParagraphStyle>
                  <Row
                    justify="space-between"
                    style={{ marginTop: 20 }}
                    align="bottom"
                  >
                    <Col>
                      <ParagraphStyle color="#3C9822" size="14">
                        {t("patient_live")}
                      </ParagraphStyle>
                    </Col>
                    <Col>
                      <ButtonCustom onClick={() => handleJoinLobby(booking)}>
                        <ParagraphStyle color="#ffffff" size="14">
                          {t("accept")}
                        </ParagraphStyle>
                      </ButtonCustom>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </ModalTransparent>
  );
};

export default Prescription;
