import React, { useEffect, useState } from "react";
import { AppUserOma, Booking, Organization } from "../../redux/actions";
import jwtDecode from "jwt-decode";
import Loading from "../../components/Loading";
import SupportDashboard from "../../components/SupportDashboard";
import Layout from "../Home";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { hashHelper } from "../../common/hash";

const FILTER_STATUS = [
  "DOCTOR_PENDING_NOTE",
  "COMMUNITY_PHARMACIST_PENDING_NOTE",
];

const SupportDashboardContainer = (props) => {
  const dispatch = useDispatch();

  const { history } = props;

  const { t } = useTranslation();

  const [isFinishInitialLoading, setIsFinishInitialLoading] = useState(false);
  const [isFirstLaning, setIsFirstLanding] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchBy, setSearchBy] = useState("name");
  const [searchText, setSearchText] = useState("");

  const booking = useSelector((state) => state.booking);
  const appUserOma = useSelector((state) => state.appUserOma);

  useEffect(() => {
    if (appUserOma.organization.data === null) {
      const id = jwtDecode(localStorage.getItem("token")).id;
      dispatch(AppUserOma.getOrganizationsUser(id));
    }
  }, []);

  useEffect(() => {
    if (appUserOma.organization?.isSuccess) {
      dispatch(
        Booking.getBookings(
          0,
          appUserOma.organization?.data,
          page,
          "descend",
          pageSize,
          null,
          FILTER_STATUS
        )
      );
    }
    setIsFirstLanding(true);
  }, [appUserOma]);

  useEffect(() => {
    if (booking.isSuccess && isFirstLaning) {
      setIsFinishInitialLoading(true);
    }
  }, [booking]);

  const onSearch = (page = 1, pageSize = 10) => {
    if (searchText && searchText.length) {
      let firstname = "";
      let lastname = "";
      let cId = "";
      let status = "";
      if (searchBy === "name") {
        firstname = searchText.split(" ")[0];
        lastname = searchText.split(" ")[1];
      } else if (searchBy === "cid") {
        cId = searchText;
      }
      dispatch(
        Booking.searchBookings(
          null,
          firstname,
          lastname,
          cId,
          null,
          status,
          page,
          pageSize
        )
      );
    } else {
      dispatch(
        Booking.getBookings(
          0,
          appUserOma.organization?.data,
          page,
          "descend",
          pageSize,
          null,
          FILTER_STATUS
        )
      );
    }
  };

  const getBookings = (pagination, filters, sorter) => {
    if (searchText) {
      onSearch(pagination.current, pagination.pageSize);
    } else {
      dispatch(
        Booking.getBookings(
          0,
          appUserOma.organization?.data,
          pagination ? pagination.current : 1,
          sorter ? sorter.order : "descend",
          pagination ? pagination.pageSize : 10,
          null,
          FILTER_STATUS
        )
      );
    }
  };
  const handleToSummary = (bookingId) => {
    const hash = hashHelper(
      JSON.stringify({
        bookingId: bookingId,
        date: Date.now(),
      })
    );
    history.push(`/summary/${hash}`);
  };

  return (
    <Layout
      {...props}
      render={(props) =>
        isFinishInitialLoading ? (
          <SupportDashboard
            t={t}
            booking={booking}
            onSearch={onSearch}
            handleTableChange={getBookings}
            handleToSummary={handleToSummary}
            setSearchBy={(value) => setSearchBy(value)}
            setSearchText={(value) => setSearchText(value)}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default SupportDashboardContainer;
