import React from "react";
import { CardSummaryStyled, Text, Button, ButtonStyled } from "./styles";
import { PlusOutlined } from "@ant-design/icons";
import { FaPenSquare } from "react-icons/fa";
import { Typography } from "antd";

const { Title } = Typography;

function DoctorReferral(props) {
  const {
    t,
    setVisibleDoctorReferralModal,
    doctorReferral,
    practitionerSpecialties,
    deleteDoctorReferral,
    doctorReferralType,
  } = props;

  const doctorReferSpecialty = React.useMemo(() => {
    if (
      doctorReferral?.data?.practitionerSpecialtyId &&
      Array.isArray(practitionerSpecialties?.data)
    ) {
      const findItem = practitionerSpecialties?.data?.find(
        (i) => i.id === doctorReferral?.data?.practitionerSpecialtyId
      );
      return findItem.name || "";
    }
    return "";
  }, [doctorReferral, practitionerSpecialties]);

  const doctorReferName = React.useMemo(() => {
    if (doctorReferral?.data?.referralType === doctorReferralType?.DOCTOR) {
      return `${doctorReferral?.detail?.title || ""} ${
        doctorReferral?.detail?.firstName || ""
      } ${doctorReferral?.detail?.lastName || ""}`;
    } else {
      return doctorReferral?.detail?.name;
    }
  }, [doctorReferral]);

  return (
    <CardSummaryStyled
      title={<Title level={4}>{t("doctor_referral")}</Title>}
      bodyStyle={{ height: "25vh" }}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setVisibleDoctorReferralModal(true)}
          className="d-flex justify-content-center align-items-center summary-button"
        >
          <p className="m-0 pl-2">{t("add_refer")}</p>
        </Button>
      }
    >
      {doctorReferral?.detail ? (
        <div>
          <Text bold>
            {t("refer_type")}
            <span style={{ fontWeight: "normal", marginLeft: 10 }}>
              {t(doctorReferral?.data?.referralType)}
            </span>
          </Text>
          <Text className="mt-2" bold>
            {t("department")}
            <span style={{ fontWeight: "normal", marginLeft: 10 }}>
              {doctorReferSpecialty}
            </span>
          </Text>
          <Text className="mt-2" bold>
            {t("name")}
            <span style={{ fontWeight: "normal", marginLeft: 10 }}>
              {doctorReferName}
            </span>
          </Text>
          {!!doctorReferral?.detail?.address && (
            <Text className="mt-2" bold>
              {t("clinic_address")}
              <span style={{ fontWeight: "normal", marginLeft: 10 }}>
                {doctorReferral?.detail?.address}
              </span>
            </Text>
          )}
          <div
            className="mt-2"
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonStyled
              className="mr-2"
              onClick={() => deleteDoctorReferral(doctorReferral?.data?.id)}
            >
              {t("cancel")}
            </ButtonStyled>
            <ButtonStyled
              onClick={() => setVisibleDoctorReferralModal(true)}
              type="primary"
            >
              {t("edit")}
            </ButtonStyled>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column text-center h-100">
          <FaPenSquare style={{ fontSize: "36px", color: "#757575" }} />
          <p className="mt-2 mb-5">{t("not_found_data")}</p>
        </div>
      )}
    </CardSummaryStyled>
  );
}

export default DoctorReferral;
