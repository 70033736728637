import country from "./assets/country.json";
import nationality from "./assets/nationality.json";
// export const CHECK_BOX_LIST_INCLUSION = [
// {
//   title: "inclusion_1",
//   key: "qualifyAge",
// },
// {
//   title: "inclusion_2",
//   key: "beingHealthy",
// },
// {
//   title: "inclusion_3",
//   key: "getVaccinated",
// },
// {
//   title: "inclusion_4",
//   key: "noCloseContactCovid",
// },
// {
//   title: "inclusion_5",
//   key: "negativeCovid19Test",
// },
// {
//   title: "inclusion_6",
//   key: "signedWrittenConsent",
// },
// {
//   title: "inclusion_7",
//   key: "complyWithRequirements",
// },
export const CHECK_BOX_LIST_INCLUSION_HEALTHY = [
  {
    title: "inclusion_healthy_1",
    key: "healthyQualifyAge",
  },
  {
    title: "inclusion_healthy_2",
    key: "healthyRefractive",
  },
  {
    title: "inclusion_healthy_3",
    key: "healthyPressure",
  },
  {
    title: "inclusion_healthy_4",
    key: "healthyCKD",
  },
  {
    title: "inclusion_healthy_5",
    key: "healthyConsents",
  },
  {
    title: "inclusion_healthy_6",
    key: "healthyWilling",
  },
];

export const CHECK_BOX_LIST_INCLUSION_CKD = [
  {
    title: "inclusion_ckd_1",
    key: "ckdQualifyAge",
  },
  {
    title: "inclusion_ckd_2",
    key: "ckdRefractive",
  },
  {
    title: "inclusion_ckd_3",
    key: "ckdPressure",
  },
  {
    title: "inclusion_ckd_4",
    key: "ckdCKD",
  },
];

export const CHECK_BOX_LIST_EXCLUSION = [
  // {
  //   title: "exclusion_1",
  //   key: "abnormalitiesAtPhysicalExam",
  // },
  // {
  //   title: "exclusion_2",
  //   key: "vitalSignAbnormalities",
  // },
  // {
  //   title: "exclusion_3",
  //   key: "positiveUrinePregnancyTest",
  // },
  // {
  //   title: "exclusion_4",
  //   key: "covid19Infection",
  // },
  // {
  //   title: "exclusion_5",
  //   key: "allergicReaction",
  // },
  // {
  //   title: "exclusion_6",
  //   key: "partipationInClinicalStudy",
  // },
  // {
  //   title: "exclusion_7",
  //   key: "nasalProductUsage",
  // },
  // {
  //   title: "exclusion_8",
  //   key: "pulmonaryInfiltrate",
  // },
  // {
  //   title: "exclusion_9",
  //   key: "respiratotyTractAbnormalities",
  // },
  // {
  //   title: "exclusion_10",
  //   key: "chronicAllergicRhinitis",
  // },
  // {
  //   title: "exclusion_11",
  //   key: "anyReason",
  // },
  {
    title: "exclusion_criteria_1",
    key: "myopia",
  },
  {
    title: "exclusion_criteria_2",
    key: "opticNerveHypoplasia",
  },
  {
    title: "exclusion_criteria_3",
    key: "macularDefects",
  },
  {
    title: "exclusion_criteria_4",
    key: "retinalVesselDefects",
  },
  {
    title: "exclusion_criteria_5",
    key: "historyOfHyperopia",
  },
  {
    title: "exclusion_criteria_6",
    key: "historyOfStrabismus",
  },
  {
    title: "exclusion_criteria_7",
    key: "undergoPriorIntraocular",
  },
  {
    title: "exclusion_criteria_8",
    key: "historyOfDiabetes",
  },
  {
    title: "exclusion_criteria_9",
    key: "notBlurred",
  },
];

export const RELIGION = [
  {
    label: "buddhsim",
    key: "1",
  },
  {
    label: "islam",
    key: "2",
  },
  {
    label: "christianity",
    key: "3",
  },
  {
    label: "hindu",
    key: "4",
  },
  {
    label: "sikhism",
    key: "5",
  },
  {
    label: "confucius",
    key: "6",
  },
  {
    label: "other",
    key: "7",
  },
  {
    label: "no_religion",
    key: "8",
  },
];

export const GENDER = [
  {
    label: "MALE",
    key: "1",
  },
  {
    label: "FEMALE",
    key: "2",
  },
];

export const GROUP = [
  {
    label: "Healthy",
    key: "Healthy",
  },
  {
    label: "CKD",
    key: "CKD",
  },
];

export const EDUCATION = [
  {
    label: "uneducated",
    key: "1",
  },
  {
    label: "primary_school",
    key: "2",
  },
  {
    label: "high_school",
    key: "3",
  },
  {
    label: "secondary_school",
    key: "4",
  },
  {
    label: "diploma",
    key: "5",
  },
  {
    label: "bachelor_degree",
    key: "6",
  },
  {
    label: "master_degree",
    key: "7",
  },
  {
    label: "other",
    key: "8",
  },
];

export const MARITAL_STATUS = [
  {
    label: "single",
    key: "1",
  },
  {
    label: "married",
    key: "2",
  },
  {
    label: "divorced",
    key: "3",
  },
  {
    label: "separated",
    key: "4",
  },
  {
    label: "widowed",
    key: "5",
  },
  {
    label: "engaged",
    key: "6",
  },
  {
    label: "civil_union",
    key: "7",
  },
];

export const NATIONALITY = (locale) => {
  return nationality.map((item, index) => ({
    label: locale === "th" ? item.th : item.en,
    key: index.toString(),
  }));
};
export const COUNTRY_LIST = (locale) =>
  country.map((item, index) => ({
    label: locale === "th" ? item.name : item.enName,
    key: index.toString(),
  }));
