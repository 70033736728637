import { Input, Col } from "antd";
import TextBase from "../Text";
import styled from "styled-components";

const { Search: SearchAnt } = Input;

export const Text = styled(TextBase)`
  font-weight: bold;
`;

export const TextCenter = styled(TextBase)`
  text-align: center;
  color: ${(props) => props.color ?? "black"};
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin-right: 15px;
  }
`;
export const Search = styled(SearchAnt)`
  i {
    color: ${(props) => props.theme.primaryButton};
  }
`;

export const DivCalendar = styled.div`
  width: 350px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

export const FlexCalendar = styled(Col)`
  display: flex;
  justify-content: center;
`;
