import jwtDecode from "jwt-decode";
import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import { Firebase } from "../../api";
import * as type from "../types";

const ROOM_SP_CODE = {
  "gp-th-prime": {
    id: "gp-th-prime",
    active: true,
    careType: {
      localeName: "ปรึกษาหมอทั่วไป",
      spCode: "gp-th-prime",
      specialties: "General Practitioner",
    },
    country: "Thailand",
    description: "15 - 30 นาที ปรึกษาแพทย์ผู้มีประสบการณ์มากกว่า 10 ปี",
    grade: "prime",
    name: "EverX",
    price: "500",
    receive: ["คำปรึกษา", "แนะนำยา", "แนะนำการรักษา"],
    waitingTime: "รับสายภายใน 5 - 15 นาที",
  },
  "fc-th": {
    id: "fc-th",
    active: true,
    careType: {
      localeName: "ปรึกษาหมอกับแคมเปญโรคอ้วน",
      spCode: "fc-th",
      specialties: "Fat Campaign",
    },
    country: "Thailand",
    description: "15 - 30 นาที ปรึกษาแพทย์ผู้มีประสบการณ์มากกว่า 10 ปี",
    grade: "prime",
    name: "EverX",
    price: "500",
    receive: ["คำปรึกษา", "แนะนำยา", "แนะนำการรักษา"],
    waitingTime: "รับสายภายใน 5 - 15 นาที",
  },
  "psycho-th": {
    id: "psycho-th",
    active: true,
    careType: {
      localeName: "ปรึกษาหมอจิตวิทยา",
      spCode: "psycho-th",
      specialties: "Psychology",
    },
    country: "Thailand",
    description: "15 - 30 นาที ปรึกษาแพทย์ผู้มีประสบการณ์มากกว่า 10 ปี",
    grade: "prime",
    name: "EverX",
    price: "500",
    receive: ["คำปรึกษา", "แนะนำยา", "แนะนำการรักษา"],
    waitingTime: "รับสายภายใน 5 - 15 นาที",
  },
};

function* getRooms({ roomType, practitionerId, role, patientId = null }) {
  try {
    const rooms = yield call(Firebase.getRooms, {
      roomType,
      practitionerId,
      role,
      patientId,
    });
    if (patientId !== null) {
      yield put({ type: type.GET_ROOM_SUCCESS, data: rooms });
    } else {
      yield put({ type: type.GET_ROOM_TYPE[roomType].SUCCESS, data: rooms });
    }
  } catch (e) {
    yield put({
      type: type.GET_ROOM_TYPE[roomType].FAILED,
      message: e.message,
    });
  }
}

function* getRoomByCountryAndSpecialty() {
  try {
    const rooms = yield call(Firebase.getRoomByCountryAndSpecialty);
    yield put({
      type: type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS,
      data: rooms,
    });
  } catch (e) {
    yield put({
      type: type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_FAILED,
      message: e.message,
    });
  }
}

function* updateRoomByCountryAndSpecialty(data) {
  try {
    yield call(Firebase.updateRoomByCountryAndSpecialty, data);
    yield put({
      type: type.UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_FAILED,
      message: e.message,
    });
  }
}

function* updateRoom({ roomType, practitionerId, patientId, detail, role }) {
  try {
    yield call(Firebase.updateRoom, {
      roomType,
      practitionerId,
      patientId,
      detail,
      role,
    });
    yield put({ type: type.UPDATE_ROOM_TYPE[roomType].SUCCESS });
  } catch (e) {
    yield put({
      type: type.UPDATE_ROOM_TYPE[roomType].FAILED,
      message: e.message,
    });
  }
}

function* setCurrentRoom(room) {
  try {
    yield put({ type: type.GET_CURRENT_ROOM_SUCCESS, data: room.data });
  } catch (e) {
    yield put({ type: type.GET_CURRENT_ROOM_FAILED, message: e.message });
  }
}

function* startListenerCommunityPharmacist() {
  const updateChannel = yield call(
    Firebase.createEventChannelCommunityPharmacist
  );
  while (true) {
    const rooms = yield take(updateChannel);
    const role = localStorage.getItem("token")
      ? jwtDecode(localStorage.getItem("token")).roles[0]
      : null;
    if (rooms && role && role === "communityPharmacist") {
      const filterRoomsAndConvertToArray = Object.keys(rooms)
        .filter(
          (roomId) => rooms[roomId].status === "COMMUNITY_PHARMACIST_PENDING"
        )
        .map((roomId) => ({
          ...rooms[roomId],
          roomId,
        }));
      yield put({
        type: type.GET_ROOM_TYPE["COMMUNITY_PHARMACIST"].SUCCESS,
        data: filterRoomsAndConvertToArray,
      });
    }
  }
}

function* confirmPayment({ id }) {
  try {
    yield call(Firebase.confirmPayment, id);
    yield put({ type: type.CONFIRM_FIREBASE_PAYMENT_SUCCESS });
  } catch (e) {
    yield put({
      type: type.CONFIRM_FIREBASE_PAYMENT_FAILED,
      message: e.message,
    });
  }
}

function* startListener() {
  const updateChannel = yield call(Firebase.createEventChannel);
  while (true) {
    const rooms = yield take(updateChannel);
    const role = localStorage.getItem("token")
      ? jwtDecode(localStorage.getItem("token")).roles[0]
      : null;
    if (rooms && role && role === "doctor") {
      const spCodes = Object.keys(rooms);
      let newRooms = [];
      for (let spCode of spCodes) {
        const roomIds = Object.keys(rooms[spCode].patients);
        for (let roomId of roomIds) {
          const room = rooms[spCode].patients[roomId];
          if (
            room.status === "DOCTOR_PENDING" &&
            room.date &&
            room.date + 60 * 60 * 1000 > Date.now()
          ) {
            newRooms.push({
              ...room,
              roomId,
              country: ROOM_SP_CODE[spCode].country.toLowerCase(),
              specialty: ROOM_SP_CODE[spCode],
            });
          }
        }
      }
      yield put({
        type: type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS,
        data: newRooms,
      });
    }
  }
}

function* startListenerTelemedicine() {
  const updateChannel = yield call(Firebase.createEventChannelTelemedicine);
  while (true) {
    const rooms = yield take(updateChannel);
    const token = localStorage.getItem("token")
      ? jwtDecode(localStorage.getItem("token"))
      : null;
    if (rooms && token && token.id && token.roles[0] === "doctor") {
      let key = `practitioner${token.id}`;
      let newRooms = [];
      if (rooms[key] && rooms[key].patients) {
        const roomIds = Object.keys(rooms[key].patients);
        for (let roomId of roomIds) {
          const room = rooms[key].patients[roomId];
          if (room.status === "DOCTOR_PENDING") {
            newRooms.push({
              ...room,
              roomId,
            });
          }
        }
      }

      yield put({
        type: type.GET_ROOM_TYPE["SCHEDULING"].SUCCESS,
        data: newRooms,
      });
    }
  }
}

function* watchAction() {
  yield takeEvery(type.CONFIRM_FIREBASE_PAYMENT_REQUESTED, confirmPayment);
  yield takeEvery(
    type.GET_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED,
    getRoomByCountryAndSpecialty
  );
  yield takeEvery(
    type.UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED,
    updateRoomByCountryAndSpecialty
  );
  yield takeEvery(type.GET_ROOM_TYPE["SCHEDULING"].REQUESTED, getRooms);
  yield takeEvery(
    type.GET_ROOM_TYPE["COMMUNITY_PHARMACIST"].REQUESTED,
    getRooms
  );
  yield takeEvery(type.GET_ROOM_TYPE["COVID"].REQUESTED, getRooms);
  yield takeEvery(type.GET_ROOM_TYPE["GENERAL"].REQUESTED, getRooms);
  yield takeEvery(type.UPDATE_ROOM_TYPE["SCHEDULING"].REQUESTED, updateRoom);
  yield takeEvery(
    type.UPDATE_ROOM_TYPE["COMMUNITY_PHARMACIST"].REQUESTED,
    updateRoom
  );
  yield takeEvery(type.UPDATE_ROOM_TYPE["COVID"].REQUESTED, updateRoom);
  yield takeEvery(type.UPDATE_ROOM_TYPE["GENERAL"].REQUESTED, updateRoom);
  yield takeEvery(type.GET_CURRENT_ROOM_REQUESTED, setCurrentRoom);
}

function* startListenerEvent() {
  yield fork(startListenerCommunityPharmacist);
  yield fork(startListenerTelemedicine);
  yield fork(startListener);
}

export default function* firebaseSaga() {
  yield fork(watchAction);
  yield fork(startListenerEvent);
}
