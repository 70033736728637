import React from "react";
import moment from "moment";
import { Row } from "antd";

import Card from "../Card/Widget";
import CovidModal from "../Modal/CovidModal/Covid";
import CallModal from "../Modal/CallModal/Call";
import HistoryBookingModal from "../Modal/HistoryBookingModal/HistoryBooking";
import { CALL_STATUS } from "./constant";

const RoomList = (props) => {
  const {
    rooms,
    booking,
    treatment,
    roomType,
    role,
    title,
    visibleCallModal,
    visibleCovidModal,
    visibleHistoryBookingModal,
    setVisibleCallModal,
    setVisibleCovidModal,
    setVisibleHistoryBookingModal,
    handleOpenCallModal,
    fetchBookingByTreatmentId,
    handleAcceptCall,
    t,
  } = props;
  return (
    <Row gutter={[32, 32]} className="p-4">
      {rooms.map((room) => {
        const { status, date, userId, specialty } = room;
        const { color, name } = CALL_STATUS[role][status];
        const formatDate = moment(date).format("DD/MM/YYYY HH:mm");
        return (
          <Card
            backgroundColor={room.color ?? "#ffffff"}
            date={formatDate}
            color={color}
            status={name}
            name={room.name}
            id={userId}
            specialty={specialty?.careType?.specialties}
            country={specialty?.country}
            setVisibleModal={() => handleOpenCallModal(name, room, date)}
          />
        );
      })}
      <CallModal
        t={t}
        title={title}
        visible={visibleCallModal}
        setVisible={setVisibleCallModal}
        setVisibleCovidModal={setVisibleCovidModal}
        fetchBookingByTreatmentId={fetchBookingByTreatmentId}
        booking={booking}
        roomType={roomType}
        handleAcceptCall={handleAcceptCall}
      />
      <CovidModal
        t={t}
        visible={visibleCovidModal}
        setVisible={setVisibleCovidModal}
        booking={booking}
      />
      <HistoryBookingModal
        visible={visibleHistoryBookingModal}
        setVisible={setVisibleHistoryBookingModal}
        treatment={treatment}
        t={t}
      />
    </Row>
  );
};

export default RoomList;
