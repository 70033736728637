import { Card, Button as ButtonAnt } from "antd";
import styled from "styled-components";
import TextBase from "../../Text";

export const CardSummaryStyled = styled(Card)`
  border: none !important;
  border-radius: 20px !important;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  padding: 8px;
  .ant-card-actions {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .ant-card-actions > li {
    display: flex;
    justify-content: end;
    padding-right: 10px;
  }
`;

export const Text = styled(TextBase)`
  color: ${(props) => (props.primary ? "#2BA796" : props.color)};
  font-size: ${(props) => props.size};
  margin: 0px;
`;

export const ButtonStyled = styled(ButtonAnt)`
  &&& {
    margin: 5px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 700;
    height: 100%;
  }
`;

export const Button = styled(ButtonAnt)``;
