import React, { useState, useEffect } from "react";
import DeliverDrug from "../../components/DeliverDrug";
import { useSelector, useDispatch } from "react-redux";
import {
  Booking,
  AppUserOma,
  Organization,
  Firebase,
  Payment,
} from "../../redux/actions";
import jwtDecode from "jwt-decode";
import Loading from "../../components/Loading";
import { decryptHelper } from "../../common/hash";
import ModalPrescription from "../../components/Modal/PrescriptionModal/Prescription";
import ModalPayment from "../../components/Modal/PaymentModal/Payment";
import { useTranslation } from "react-i18next";

const DeliverDrugContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { match, history } = props;

  const booking = useSelector((state) => state.booking);
  const appUserOma = useSelector((state) => state.appUserOma);
  const communityPharmacy = useSelector(
    (state) => state.organization.communityPharmacy
  );

  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [isFinishInitialLoad, setIsFinishInitialLoad] = useState(false);
  const [visibleModalPrescription, setVisibleModalPrescription] =
    useState(false);
  const [visibleModalPayment, setVisibleModalPayment] = useState(false);
  const [visibleModalNote, setVisibleModalNote] = useState(false);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    decryptHashFromPath();
    getCommunityPharmacistByOrgId(4);
  }, []);

  useEffect(() => {
    if (
      booking.isSuccess &&
      appUserOma.isSuccess &&
      isFirstLoad &&
      communityPharmacy.isSuccess &&
      origin !== null &&
      destination !== null
    ) {
      setIsFinishInitialLoad(true);
    }
  }, [
    booking,
    appUserOma,
    isFirstLoad,
    communityPharmacy,
    origin,
    destination,
  ]);

  useEffect(() => {
    if (booking.isSuccess) {
      const { latitude, longitude } = booking.current.locationAddress;
      setDestination({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [booking]);
  useEffect(() => {
    if (communityPharmacy.isSuccess) {
      const { lat, lng } = communityPharmacy.data[0].address.location;
      setOrigin({ lat, lng });
    }
  }, [communityPharmacy]);

  const decryptHashFromPath = () => {
    const id = jwtDecode(localStorage.getItem("token")).id;
    const bookingId = decryptHelper(match.params.id);
    dispatch(Booking.getBooking(bookingId));
    dispatch(AppUserOma.getUser(id));
    dispatch(AppUserOma.getProfile(id));
    setIsFirstLoad(true);
  };

  const getCommunityPharmacistByOrgId = (orgId) => {
    dispatch(Organization.getCommunityPharmaciesByOrganization(orgId));
  };

  const handleConfirmPayment = () => {
    let update = {
      status: "COMMUNITY_PHARMACIST_COMPLETED",
    };
    const roomType = "COMMUNITY_PHARMACIST";
    const userId = appUserOma.information.id;
    const patientId = booking.current.patientId;
    const role = appUserOma.information.roles[0].name;
    dispatch(Firebase.updateRoom(roomType, userId, patientId, update, role));
    dispatch(
      Booking.updateBooking(booking.current.id, true, {
        status: `COMMUNITY_PHARMACIST_COMPLETED`,
      })
    );
    if (
      booking.payment &&
      booking.payment.data &&
      booking.payment.data.length > 0
    ) {
      dispatch(Firebase.confirmPayment(booking.payment.data[0].id));
      dispatch(Payment.confirmPayment(booking.payment.data[0].id));
    }

    setVisibleModalPayment(false);
  };

  const handleUpdateStatus = (action) => {
    if (action === "CLOSE") {
      if (appUserOma.information.roles[0].name === "communityPharmacist") {
        history.push("/dashboard-map");
      } else if (appUserOma.information.roles[0].name === "callCenter") {
        history.push("/support-dashboard");
      }
    } else if (action === "COMPLETED") {
      dispatch(Booking.getBookingPayments(booking.current.id));
      setVisibleModalPayment(true);
    } else {
      let update = {
        status: `COMMUNITY_PHARMACIST_${action}`,
      };
      const roomType = "COMMUNITY_PHARMACIST";
      const userId = appUserOma.information.id;
      const patientId = booking.current.patientId;
      const role = appUserOma.information.roles[0].name;
      dispatch(Firebase.updateRoom(roomType, userId, patientId, update, role));
      dispatch(
        Booking.updateBooking(booking.current.id, true, {
          status: `COMMUNITY_PHARMACIST_${action}`,
        })
      );
    }
  };

  return isFinishInitialLoad ? (
    <>
      <DeliverDrug
        t={t}
        origin={origin}
        booking={booking.current}
        destination={destination}
        visibleModalNote={visibleModalNote}
        setVisibleModalNote={setVisibleModalNote}
        setVisibleModalPrescription={setVisibleModalPrescription}
        handleUpdateStatus={handleUpdateStatus}
      />
      <ModalPrescription
        visible={visibleModalPrescription}
        setVisible={setVisibleModalPrescription}
        patient={
          booking.current &&
          booking.current.patient &&
          booking.current.patient.userInformation
        }
        booking={booking.current}
        handleVisibleNote={setVisibleModalNote}
        communityPharmacy={communityPharmacy}
        t={t}
      />
      <ModalPayment
        visible={visibleModalPayment}
        setVisible={setVisibleModalPayment}
        payment={booking.payment}
        t={t}
        handleConfirmPayment={handleConfirmPayment}
      />
    </>
  ) : (
    <Loading />
  );
};

export default DeliverDrugContainer;
