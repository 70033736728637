import React from "react";
import { Col, Modal, Row, Space, Typography, Collapse } from "antd";
import { ShareAltOutlined, SearchOutlined } from "@ant-design/icons";
import { Text, Option, Select, Table, ButtonRefer, Input } from "./styles";
const { Title } = Typography;
const { Panel } = Collapse;

function DoctorReferralModal(props) {
  const {
    t,
    visible,
    setVisible,
    patient,
    doctorReferralTypeList = [],
    practitionerSpecialties,
    practitioners,
    selectDoctorReferTypeHandler,
    selectDoctorReferSpecialtyHandler,
    doctorReferType,
    doctorReferSpecialty,
    doctorReferralType,
    clinic,
    clinicPackages,
    createDoctorReferral,
    setDoctorReferSpecialtyId,
    doctorReferral,
  } = props;

  const [filterText, setFilterText] = React.useState("");
  const [tableParams, setTableParams] = React.useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const columnsDoctor = [
    {
      title: <Text>{t("refer_type")}</Text>,
      align: "center",
      key: "type",
      render: (_) => {
        return <Text>{t(doctorReferType)}</Text>;
      },
    },
    {
      title: <Text> {t("department")}</Text>,
      align: "center",
      key: "specialtyType",
      render: (_) => {
        return <Text>{doctorReferSpecialty}</Text>;
      },
    },
    {
      title: <Text>{t("name")}</Text>,
      align: "center",
      key: "name",
      render: (record) => {
        return (
          <Text>
            {record.title} {record.firstName} {record.lastName}
          </Text>
        );
      },
    },
    {
      title: <Text>{t("action")}</Text>,
      key: "action",
      align: "center",
      render: (record) => {
        return (
          <div className="d-flex w-100 justify-content-center align-items-center">
            <ButtonRefer
              icon={<ShareAltOutlined />}
              onClick={() => createDoctorReferral(record)}
              className="d-flex justify-content-center align-items-center"
            >
              <p className="m-0 pl-2">{t("refer_to")}</p>
            </ButtonRefer>
          </div>
        );
      },
    },
  ];

  const columnsClinic = [
    {
      title: <Text>{t("refer_type")}</Text>,
      align: "center",
      key: "type",
      render: (_) => {
        return <Text>{t(doctorReferType)}</Text>;
      },
    },
    {
      title: <Text> {t("department")}</Text>,
      align: "center",
      key: "specialtyType",
      render: (_) => {
        return <Text>{doctorReferSpecialty}</Text>;
      },
    },
    {
      title: <Text>{t("name")}</Text>,
      align: "center",
      key: "name",
      render: (record) => {
        return <Text>{record.name}</Text>;
      },
    },
    {
      title: <Text>{t("clinic_address")}</Text>,
      align: "center",
      key: "name",
      render: (record) => {
        return <Text>{record.address}</Text>;
      },
    },
    {
      title: <Text>{t("action")}</Text>,
      key: "action",
      align: "center",
      render: (record) => {
        return (
          <div className="d-flex w-100 justify-content-center align-items-center">
            <ButtonRefer
              icon={<ShareAltOutlined />}
              onClick={() => createDoctorReferral(record)}
              className="d-flex justify-content-center align-items-center"
            >
              <p className="m-0 pl-2">{t("refer_to")}</p>
            </ButtonRefer>
          </div>
        );
      },
    },
  ];

  const columnsTreatment = [
    {
      title: <Text>{t("refer_type")}</Text>,
      align: "center",
      key: "type",
      render: (_) => {
        return <Text>{t(doctorReferType)}</Text>;
      },
    },
    {
      title: <Text> {t("department")}</Text>,
      align: "center",
      key: "specialtyType",
      render: (_) => {
        return <Text>{doctorReferSpecialty}</Text>;
      },
    },
    {
      title: <Text>{t("TREATMENT")}</Text>,
      align: "center",
      key: "name",
      render: (record) => {
        return <Text>{record.name}</Text>;
      },
    },
    {
      title: <Text>{t("action")}</Text>,
      key: "action",
      align: "center",
      render: (record) => {
        return (
          <div className="d-flex w-100 justify-content-center align-items-center">
            <ButtonRefer
              icon={<ShareAltOutlined />}
              onClick={() => createDoctorReferral(record)}
              className="d-flex justify-content-center align-items-center"
            >
              <p className="m-0 pl-2">{t("refer_to")}</p>
            </ButtonRefer>
          </div>
        );
      },
    },
  ];

  const dataSource = React.useMemo(() => {
    switch (doctorReferType) {
      case doctorReferralType?.DOCTOR:
        if (filterText && Array.isArray(practitioners.data)) {
          return practitioners.data.filter(
            (ele) =>
              ele.firstName
                ?.toLowerCase?.()
                .includes?.(filterText.toLowerCase()) ||
              ele.lastName?.toLowerCase?.().includes?.(filterText.toLowerCase())
          );
        }
        return practitioners.data;
      case doctorReferralType?.CLINIC:
        if (filterText && Array.isArray(clinic.data)) {
          return clinic.data.filter((ele) =>
            ele.name?.toLowerCase?.().includes?.(filterText.toLowerCase())
          );
        }
        return clinic.data;
      case doctorReferralType?.TREATMENT:
        if (filterText && Array.isArray(clinicPackages.data)) {
          return clinicPackages.data.filter((ele) =>
            ele.name?.toLowerCase?.().includes?.(filterText.toLowerCase())
          );
        }
        return clinicPackages.data;
      default:
        return [];
    }
  }, [
    doctorReferType,
    doctorReferSpecialty,
    clinicPackages,
    clinic,
    practitioners,
    filterText,
  ]);

  const columnSource = React.useMemo(() => {
    switch (doctorReferType) {
      case doctorReferralType?.DOCTOR:
        return columnsDoctor;
      case doctorReferralType?.CLINIC:
        return columnsClinic;
      case doctorReferralType?.TREATMENT:
        return columnsTreatment;
      default:
        return [];
    }
  }, [doctorReferType, doctorReferSpecialty, doctorReferral]);

  return (
    <Modal
      width={1000}
      visible={visible}
      closable={true}
      onCancel={() => setVisible(false)}
      title={
        <Space align="center">
          <ShareAltOutlined style={{ fontSize: 22 }} />
          <Title className="mb-0 ml-2" level={5}>
            {t("doctor_referral")}
          </Title>
        </Space>
      }
      footer={null}
    >
      <div>
        <Row gutter={[8, 8]} justify="space-between">
          <Col>
            <Space>
              <Text size="12px" bold ellipsis>
                {t("department")}
              </Text>
              <Select
                placeholder={
                  <Row justify="start" align="middle">
                    <SearchOutlined style={{ fontSize: 16, marginRight: 5 }} />
                    <Text size="12px" color="rgba(0, 0, 0, 0.35)">
                      {t("select_department")}
                    </Text>
                  </Row>
                }
                value={doctorReferSpecialty}
                style={{ width: 230, fontSize: 12 }}
                onChange={(value) => {
                  selectDoctorReferSpecialtyHandler(value);
                  const findItem = practitionerSpecialties.data.find(
                    (ele) => ele.name === value
                  );
                  setDoctorReferSpecialtyId(findItem?.id);
                }}
              >
                {Array.isArray(practitionerSpecialties.data) &&
                  practitionerSpecialties.data.map((ele) => {
                    return (
                      <Option
                        key={ele.id}
                        style={{ fontSize: 12 }}
                        value={ele.name}
                      >
                        {ele.name}
                      </Option>
                    );
                  })}
              </Select>
            </Space>
          </Col>
          <Col>
            <Select
              style={{ width: 200, fontSize: 12 }}
              onChange={selectDoctorReferTypeHandler}
              value={doctorReferType}
            >
              {doctorReferralTypeList.map((item) => (
                <Option key={item} style={{ fontSize: 12 }} value={item}>
                  {t(item)}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={24}>
            <Collapse expandIconPosition="right" onChange={() => {}}>
              <Panel header={<Text>{t("patient_information")}</Text>} key="1">
                <Row justify="space-between">
                  <Col>
                    <Space align="center">
                      <Text color="grey">{t("name")}</Text>
                      <Text className="mr-2">
                        {patient?.userInformation?.firstname || ""}
                      </Text>
                      <Text color="grey">{t("last_name")}</Text>
                      <Text>{patient?.userInformation?.lastname || ""}</Text>
                    </Space>
                  </Col>
                  <Col>
                    <Space align="center">
                      <Text color="grey">{t("district")} : </Text>
                      <Text className="mr-2">
                        {patient?.userInformation?.addresses?.[0]?.district ||
                          ""}
                      </Text>
                      <Text color="grey">{t("province")} : </Text>
                      <Text>
                        {patient?.userInformation?.addresses?.[0]?.province ||
                          ""}
                      </Text>
                    </Space>
                  </Col>
                  <Col></Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        {!!doctorReferType && !!doctorReferSpecialty && (
          <Row className="mt-4">
            <Col span={24}>
              <Input
                placeholder={t(doctorReferType)}
                style={{ maxWidth: 250 }}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Col>
          </Row>
        )}
        {!!doctorReferType && !!doctorReferSpecialty && (
          <div className="mt-4">
            <Table
              rowKey={(ele) => ele.id}
              loading={
                practitioners.loading ||
                clinic.loading ||
                clinicPackages.loading
              }
              pagination={tableParams.pagination}
              onChange={handleTableChange}
              dataSource={dataSource}
              columns={columnSource}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default DoctorReferralModal;
