import React from "react";
import { Steps, Row, message } from "antd";
import { Container, Text, Button } from "./styles";
import "./styles.css";
import { CHECK_BOX_LIST_EXCLUSION, CHECK_BOX_LIST_INCLUSION_HEALTHY, CHECK_BOX_LIST_INCLUSION_CKD } from "./constant";
import ConsentForm from "./widgets/ConsentForm";
import GeneralInformation from "./widgets/GeneralInformation";
import ContactInformation from "./widgets/ContactInformation";
import EligibilityAssessment from "./widgets/EligibilityAssessment";
import ExclusionInclusionCriteria from "./widgets/ExclusionInclusionCriteria";
import CtmsAddNewParticipant from "../Modal/CtmsAddNewParticipant";
import { useFormik } from "formik";
import * as Yup from "yup";

import moment from "moment";

const { Step } = Steps;

const wrapTwoDecimal = (value) => {
  return +(Math.floor(value * 100) / 100).toFixed(2);
};

const RegisterClinicTrialParticipant = (props) => {
  const {
    t,
    i18n,
    clinicTrial,
    userPerm,
    customRequest,
    getNameFromUrl,
    currentRecord,
    setOnRegister,
    onConsentFormSubmit,
    onGeneralFormSubmit,
    onContactFormSubmit,
    onApproveResearchObjectById,
    onDeclineResearchObjectById,
    onEigibilityAssessmentSubmit,
    onManuallyApproveResearchObjectById,
    currentPageRegister,
    setCurrentPageRegister,
    onSelectStudyCaseHandler,
    modalManuallyVisible,
    setModalManuallyVisible,
  } = props;
  const validateDecimal = Yup.number().test(
    "is-decimal",
    t("input_number"),
    (value) => (value + "").match(/^(\d+)?([.]?\d{1})?$/) || !value
  );
  const eligibilityObj = currentRecord?.eligibilityAssessment;

  const consentForm = useFormik({
    initialValues: {
      fileUrls: currentRecord?.consentForm?.fileUrls ?? [],
      note: currentRecord?.consentForm?.note ?? "",
    },
    validationSchema: Yup.object().shape({
      fileUrls: Yup.array().min(1).required(t("upload_at_least_one_file")),
    }),
    onSubmit: (values) => {
      if (currentRecord.researchId) {
        onConsentFormSubmit(currentRecord.researchId, values);
      }
    },
  });

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const generalForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      identity: currentRecord?.generalInformation?.identity ?? "",
      gender: currentRecord?.formObject?.gender?.value
        ? currentRecord?.formObject?.gender?.value?.toUpperCase()
        : currentRecord?.generalInformation?.gender ?? "",
      birthDate: currentRecord?.generalInformation?.birthDate ?? "",
      // status: currentRecord?.generalInformation?.status ?? "",
      nationality: currentRecord?.generalInformation?.nationality ?? "",
      // religion: currentRecord?.generalInformation?.religion ?? "",
      // education: currentRecord?.generalInformation?.education ?? "",
      // occupation: currentRecord?.generalInformation?.occupation ?? "",
    },
    validationSchema: Yup.object().shape({
      identity: Yup.string()
        .min(13, t("fill_in_your_id_card"))
        .required(t("fill_in_your_id_card_or_passport")),
      gender: Yup.mixed()
        .oneOf(["MALE", "FEMALE"], t("identify_your_gender"))
        .required(t("identify_your_gender")),
      birthDate: Yup.date().required(t("identify_your_birth")),
      // status: Yup.string().required(t("marital_status")),
      nationality: Yup.string().required(t("specify_your_race")),
      // religion: Yup.string().required(t("specify_your_religion")),
      // education: Yup.string().required(t("level_of_education")),
    }),
    onSubmit: async (values) => {
      if (currentRecord.researchId) {
        onGeneralFormSubmit(currentRecord.researchId, {
          ...values,
          birthDate: values.birthDate ?? moment().toISOString(),
        });
      }
    },
  });

  const contactForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    initialValues: {
      // address1: currentRecord?.address?.address1 ?? "",
      // address2: currentRecord?.address?.address2 ?? "",
      // addressDetail: currentRecord?.address?.addressDetail ?? "",
      postalCode: currentRecord?.address?.postalCode ?? "",
      // country: currentRecord?.address?.country ?? "",
      phoneNumber: currentRecord?.address?.phoneNumber
        ? currentRecord?.address?.phoneNumber
        : currentRecord?.formObject?.phoneno?.value ?? "",
      email: currentRecord?.address?.email ?? "",
      lineId: currentRecord?.address?.lineId ?? "",

      emergencyName: currentRecord?.address?.emergencyName ?? "",
      emergencyRelationship: currentRecord?.address?.emergencyRelationship ?? "",
      emergencyPhoneNumber: currentRecord?.address?.emergencyPhoneNumber ?? "",


    },
    validationSchema: Yup.object().shape({
      // address1: Yup.string().required(t("fill_in_your_address")),
      // address2: Yup.string(),
      // addressDetail: Yup.string().required(
      //   t("fill_province_district_subdistrict")
      // ),
      postalCode: Yup.number()
        .min(5, t("fill_postal_code_correct"))
        .required(t("fill_postal_code")),
      // country: Yup.string().required(t("select_country")),
      email: Yup.string().trim().email(),
      phoneNumber: Yup.number().required(t("fill_in_your_phone_number")),

    }),
    onSubmit: (values) => {
      if (currentRecord.researchId) {
        onContactFormSubmit(currentRecord.researchId, values);
      }
    },
  });

  const eligibilityAssessmentForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      day: -1,
      height: eligibilityObj?.height
        ? eligibilityObj?.height
        : currentRecord?.formObject?.height?.value ?? "",
      weight: eligibilityObj?.weight
        ? eligibilityObj?.weight
        : currentRecord?.formObject?.weight?.value ?? "",
      bmi: eligibilityObj?.bmi
        ? eligibilityObj?.bmi
        : currentRecord?.formObject?.bmi?.value ?? 0,
      bloodPressure: eligibilityObj?.bloodPressure
        ? `${eligibilityObj?.bloodPressure}`.replace(".", "/")
        : 0,
      bloodPressureRange: eligibilityObj?.bloodPressureRange ?? "",
      heartRate: eligibilityObj?.heartRate ?? 0,
      rr: eligibilityObj?.rr ?? 0,
      pulseOx: eligibilityObj?.pulseOx ?? 0,

      diopter: eligibilityObj?.diopter ?? 0,
      intraocularPressure: eligibilityObj?.intraocularPressure ?? 0,
      eGFR: eligibilityObj?.eGFR ?? 0,
      acr: eligibilityObj?.acr ?? 0,



      medicationHistories:
        Array.isArray(eligibilityObj?.medicationHistories) &&
          eligibilityObj?.medicationHistories.length > 0
          ? eligibilityObj?.medicationHistories
          : [
            {
              medicationName: "",
              date: "",
            },
          ],
      underlingDiseases:
        Array.isArray(eligibilityObj?.underlingDiseases) &&
          eligibilityObj?.underlingDiseases.length > 0
          ? eligibilityObj?.underlingDiseases
          : ["", ""],
      currentMedications:
        Array.isArray(eligibilityObj?.currentMedications) &&
          eligibilityObj?.currentMedications.length > 0
          ? eligibilityObj?.currentMedications
          : [""],
      vaccinneHistory:
        Array.isArray(eligibilityObj?.covidInfectionHistory?.vaccinneHistory) &&
          eligibilityObj?.covidInfectionHistory?.vaccinneHistory.length > 0
          ? eligibilityObj?.covidInfectionHistory?.vaccinneHistory
          : [
            {
              vaccineName: "",
              date: "",
              site: "",
            },
          ],
      covidInfectionHistory: {
        closeContact: eligibilityObj?.covidInfectionHistory?.closeContact ?? "",
        closeContactComment:
          eligibilityObj?.covidInfectionHistory?.closeContactComment ?? "",
        closeContactDate:
          eligibilityObj?.covidInfectionHistory?.closeContactDate ?? "",
        covidInfectionHistoryUrl:
          eligibilityObj?.covidInfectionHistory?.covidInfectionHistoryUrl ?? "",
        covidInfected:
          eligibilityObj?.covidInfectionHistory?.covidInfected ?? "",
        covidInfectedComment:
          eligibilityObj?.covidInfectionHistory?.covidInfectedComment ?? "",
        covidInfectedDate:
          eligibilityObj?.covidInfectionHistory?.covidInfectedDate ?? "",
      },
      clinicalTrialCheck: eligibilityObj?.clinicalTrialCheck ?? "",
      clinicalTrialStartDate:
        eligibilityObj?.clinicalTrialStartDate ?? moment().toISOString(),
      clinicalTrialEndDate: eligibilityObj?.clinicalTrialEndDate
        ? eligibilityObj?.clinicalTrialEndDate
        : "",
      clinicalTrialDescription: eligibilityObj?.clinicalTrialDescription ?? "",
      physicalExamination: eligibilityObj?.physicalExamination ?? "",
      nasopharyngealStatus:
        eligibilityObj?.nasopharyngealStatus ?? "not_detected",
      nasopharyngealDateOfSampleCollection:
        eligibilityObj?.nasopharyngealDateOfSampleCollection ?? "",
      nasopharyngealFileUrl: eligibilityObj?.nasopharyngealFileUrl ?? "",
      sinuscopyPolypsRight: eligibilityObj?.sinuscopyPolypsRight ?? 0,
      sinuscopyOedemaRight: eligibilityObj?.sinuscopyOedemaRight ?? 0,
      sinuscopyDischargeRight: eligibilityObj?.sinuscopyDischargeRight ?? 0,
      sinuscopyDischargeImageUrlRight:
        eligibilityObj?.sinuscopyDischargeImageUrlRight ?? "",
      sinuscopyPolypsLeft: eligibilityObj?.sinuscopyPolypsLeft ?? 0,
      sinuscopyOedemaLeft: eligibilityObj?.sinuscopyOedemaLeft ?? 0,
      sinuscopyDischargeLeft: eligibilityObj?.sinuscopyDischargeLeft ?? 0,
      sinuscopyDischargeImageUrlLeft:
        eligibilityObj?.sinuscopyDischargeImageUrlLeft ?? "",
      cPassWuhanBefore: eligibilityObj?.cPassWuhanBefore ?? 0,
      cPassWuhanAfter: eligibilityObj?.cPassWuhanAfter ?? 0,
      cPassOmicronBefore: eligibilityObj?.cPassOmicronBefore ?? 0,
      cPassOmicronAfter: eligibilityObj?.cPassOmicronAfter ?? 0,
      cPassDeltaBefore: eligibilityObj?.cPassDeltaBefore ?? 0,
      cPassDeltaAfter: eligibilityObj?.cPassDeltaAfter ?? 0,
    },
    validationSchema: Yup.object().shape({
      cPassWuhanBefore: validateDecimal,
      cPassWuhanAfter: validateDecimal,
      cPassOmicronBefore: validateDecimal,
      cPassOmicronAfter: validateDecimal,
      cPassDeltaBefore: validateDecimal,
      cPassDeltaAfter: validateDecimal,
    }),

    onSubmit: (values) => {
      if (currentRecord.researchId) {
        onEigibilityAssessmentSubmit(
          currentRecord.researchId,
          currentRecord.eligibilityAssessmentId,
          {
            ...values,
            covidInfectionHistory: {
              vaccinneHistory: values.vaccinneHistory,
              ...values.covidInfectionHistory,
            },
            nasopharyngealDateOfSampleCollection:
              values.nasopharyngealDateOfSampleCollection || null,
            clinicalTrialEndDate: values.clinicalTrialEndDate || null,
            cPassWuhanBefore: wrapTwoDecimal(values.cPassWuhanBefore),
            cPassWuhanAfter: wrapTwoDecimal(values.cPassWuhanAfter),
            cPassOmicronBefore: wrapTwoDecimal(values.cPassOmicronBefore),
            cPassOmicronAfter: wrapTwoDecimal(values.cPassOmicronAfter),
            cPassDeltaBefore: wrapTwoDecimal(values.cPassDeltaBefore),
            cPassDeltaAfter: wrapTwoDecimal(values.cPassDeltaAfter),
          }
        );
      }
    },
  });

  const inAndExCriteriaForm = useFormik({
    initialValues: {
      // qualifyAge: false,
      // beingHealthy: false,
      // getVaccinated: false,
      // noCloseContactCovid: false,
      // negativeCovid19Test: false,
      // signedWrittenConsent: false,
      // complyWithRequirements: false,
      // abnormalitiesAtPhysicalExam: false,
      // vitalSignAbnormalities: false,
      // positiveUrinePregnancyTest: false,
      // covid19Infection: false,
      // allergicReaction: false,
      // partipationInClinicalStudy: false,
      // nasalProductUsage: false,
      // pulmonaryInfiltrate: false,
      // respiratotyTractAbnormalities: false,
      // chronicAllergicRhinitis: false,
      // anyReason: false,
      participantGroup: "Healthy",
      healthyQualifyAge: false,
      healthyRefractive: false,
      healthyPressure: false,
      healthyCKD: false,
      healthyConsents: false,
      healthyWilling: false,

      ckdQualifyAge: false,
      ckdRefractive: false,
      ckdPressure: false,
      ckdCKD: false,

      myopia: false,
      opticNerveHypoplasia: false,
      macularDefects: false,
      retinalVesselDefects: false,
      historyOfHyperopia: false,
      historyOfStrabismus: false,
      undergoPriorIntraocular: false,
      historyOfDiabetes: false,
      notBlurred: false,
    },
    onSubmit: (values) => {
      if (currentRecord.researchId) {

        if (!values.myopia &&
          !values.opticNerveHypoplasia &&
          !values.macularDefects &&
          !values.retinalVesselDefects &&
          !values.historyOfHyperopia &&
          !values.historyOfStrabismus &&
          !values.undergoPriorIntraocular &&
          !values.historyOfDiabetes &&
          !values.notBlurred) {
          if (values.participantGroup === "Healthy") {
            if (
              values.healthyQualifyAge &&
              values.healthyRefractive &&
              values.healthyPressure &&
              values.healthyCKD &&
              values.healthyConsents &&
              values.healthyWilling
            ) {
              onApproveResearchObjectById(currentRecord.researchId, values);
            } else {
              onDeclineResearchObjectById(currentRecord.researchId, values);
            }
          } else if (values.participantGroup === "CKD") {
            if (
              values.ckdQualifyAge &&
              values.ckdRefractive &&
              values.ckdPressure &&
              values.ckdCKD
            ) {
              onApproveResearchObjectById(currentRecord.researchId, values);
            } else {
              onDeclineResearchObjectById(currentRecord.researchId, values);
            }
          }
        }
        else {
          onDeclineResearchObjectById(currentRecord.researchId, values);
        }
      }


      // if (currentRecord.researchId) {
      //   if (
      //     // values.qualifyAge &&
      //     // values.beingHealthy &&
      //     // values.getVaccinated &&
      //     // values.noCloseContactCovid &&
      //     // values.negativeCovid19Test &&
      //     // values.signedWrittenConsent &&
      //     // values.complyWithRequirements &&
      //     // !values.abnormalitiesAtPhysicalExam &&
      //     // !values.vitalSignAbnormalities &&
      //     // !values.positiveUrinePregnancyTest &&
      //     // !values.covid19Infection &&
      //     // !values.allergicReaction &&
      //     // !values.partipationInClinicalStudy &&
      //     // !values.nasalProductUsage &&
      //     // !values.pulmonaryInfiltrate &&
      //     // !values.respiratotyTractAbnormalities &&
      //     // !values.chronicAllergicRhinitis &&
      //     // !values.anyReason
      //     values.participantGroup &&
      //     values.healthyQualifyAge &&
      //     values.healthyRefractive &&
      //     values.healthyPressure &&
      //     values.healthyCKD &&
      //     values.healthyConsents &&
      //     values.healthyWilling &&

      //     values.ckdQualifyAge &&
      //     values.ckdRefractive &&
      //     values.ckdPressure &&
      //     values.ckdCKD &&

      //     !values.myopia &&
      //     !values.opticNerveHypoplasia &&
      //     !values.macularDefects &&
      //     !values.retinalVesselDefects &&
      //     !values.historyOfHyperopia &&
      //     !values.historyOfStrabismus &&
      //     !values.undergoPriorIntraocular &&
      //     !values.historyOfDiabetes &&
      //     !values.notBlurred
      //   ) {
      //     // onApproveResearchObjectById(currentRecord.researchId, values);
      //     console.log("onApproveResearchObjectById")
      //   } else {
      //     // onDeclineResearchObjectById(currentRecord.researchId, values);
      //     console.log("onDeclineResearchObjectById")
      //   }
      // }
    },
  });

  const manuallyRegisterForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      study: "",
      nasalSwab: "",
      subjectId: "",
      productId: "",
    },
    validationSchema: Yup.object().shape({
      study: Yup.string().required(t("please_select_one_study")),
      nasalSwab: Yup.string().required(t("please_select_one_of_nasal_swab")),
      productId: Yup.string().required(t("please_select_product_id.")),
    }),
    onSubmit: (values) => {
      const criteriaPayload = inAndExCriteriaForm.values;
      const payload = {
        subjectId: values.subjectId,
        nasalSwab: values.nasalSwab,
      };
      onManuallyApproveResearchObjectById(
        currentRecord.researchId,
        payload,
        criteriaPayload
      );
    },
  });

  const selectStudyHandler = async (key, value) => {
    if (key === "study") {
      manuallyRegisterForm.setValues({
        ...manuallyRegisterForm.values,
        subjectId: "",
        productId: "",
      });
      const generateResult = await onSelectStudyCaseHandler(value);
      if (generateResult) {
        manuallyRegisterForm.setValues({
          ...manuallyRegisterForm.values,
          subjectId: generateResult.subjectId,
          productId: generateResult.productId,
        });
      } else {
        message.error(`${t("didnt_have_new_subject")} ${value}`);
      }
    }
    manuallyRegisterForm.setFieldValue(key, value);
  };

  const onNextStepRegisterForm = () => {
    if (currentPageRegister === 0) {
      consentForm.handleSubmit();
    } else if (currentPageRegister === 1) {
      generalForm.handleSubmit();
    } else if (currentPageRegister === 2) {
      contactForm.handleSubmit();
    } else if (currentPageRegister === 3) {
      eligibilityAssessmentForm.handleSubmit();
    } else if (currentPageRegister === 4) {
      inAndExCriteriaForm.handleSubmit();
    }
  };

  const onBackStepRegisterForm = () => {
    if (currentPageRegister === 0) {
      setCurrentPageRegister(0);
      setOnRegister(false);
    } else {
      setCurrentPageRegister((prev) => Math.max(0, prev - 1));
    }
  };

  const currentComponent = React.useCallback(() => {
    switch (currentPageRegister) {
      case 0:
        return (
          <ConsentForm
            currentRecord={currentRecord}
            consentForm={consentForm}
            getNameFromUrl={getNameFromUrl}
            customRequest={customRequest}
            t={t}
          />
        );
      case 1:
        return (
          <GeneralInformation
            t={t}
            i18n={i18n}
            disabledDate={disabledDate}
            generalForm={generalForm}
          />
        );
      case 2:
        return (
          <ContactInformation t={t} i18n={i18n} contactForm={contactForm} />
        );
      case 3:
        return (
          <EligibilityAssessment
            t={t}
            disabledDate={disabledDate}
            customRequest={customRequest}
            getNameFromUrl={getNameFromUrl}
            currentRecord={currentRecord}
            eligibilityAssessmentForm={eligibilityAssessmentForm}
          />
        );
      case 4:
        return (
          <ExclusionInclusionCriteria
            t={t}
            //  inclusion={CHECK_BOX_LIST_INCLUSION}
            // check condition for inclusion and exclusion
            inclusion={CHECK_BOX_LIST_INCLUSION_HEALTHY}
            inclusionCKD={CHECK_BOX_LIST_INCLUSION_CKD}
            exclusion={CHECK_BOX_LIST_EXCLUSION}
            inAndExCriteriaForm={inAndExCriteriaForm}
          />
        );
    }
  }, [currentPageRegister, generalForm, consentForm]);

  const statusHeader = React.useCallback(
    (index) => {
      if (currentPageRegister === index) {
        return "process";
      } else if (currentPageRegister > index) {
        return "finish";
      }
      return "wait";
    },
    [currentPageRegister]
  );

  return (
    <Container className="p-3">
      <Row>
        <Container className="my-4">
          <Steps size="small">
            <Step status={statusHeader(0)} title={t("consent_form")} />
            <Step status={statusHeader(1)} title={t("general_information")} />
            <Step status={statusHeader(2)} title={t("contact_information")} />
            <Step
              status={statusHeader(3)}
              title={t("eligibility_assessment")}
            />
            <Step
              status={statusHeader(4)}
              title={t("inclusion_exclusion_criteria")}
            />
          </Steps>
        </Container>
        <Container className="container-flex__overflow p-4">
          {currentComponent()}
        </Container>
      </Row>
      <Container
        className="px-4"
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={onBackStepRegisterForm}>
          <Text>{t("back")}</Text>
        </Button>
        {userPerm.CAN_MANUAL_SUBMIT_PP && currentPageRegister === 4 && (
          <Button
            disabled={
              !(
                inAndExCriteriaForm.values.qualifyAge &&
                inAndExCriteriaForm.values.beingHealthy &&
                inAndExCriteriaForm.values.getVaccinated &&
                inAndExCriteriaForm.values.noCloseContactCovid &&
                inAndExCriteriaForm.values.negativeCovid19Test &&
                inAndExCriteriaForm.values.signedWrittenConsent &&
                inAndExCriteriaForm.values.complyWithRequirements &&
                !inAndExCriteriaForm.values.abnormalitiesAtPhysicalExam &&
                !inAndExCriteriaForm.values.vitalSignAbnormalities &&
                !inAndExCriteriaForm.values.positiveUrinePregnancyTest &&
                !inAndExCriteriaForm.values.covid19Infection &&
                !inAndExCriteriaForm.values.allergicReaction &&
                !inAndExCriteriaForm.values.partipationInClinicalStudy &&
                !inAndExCriteriaForm.values.nasalProductUsage &&
                !inAndExCriteriaForm.values.pulmonaryInfiltrate &&
                !inAndExCriteriaForm.values.respiratotyTractAbnormalities &&
                !inAndExCriteriaForm.values.chronicAllergicRhinitis &&
                !inAndExCriteriaForm.values.anyReason
              )
            }
            onClick={() => setModalManuallyVisible(true)}
            className="ml-3"
          >
            <Text>{t("manually_register")}</Text>
          </Button>
        )}
        {currentPageRegister !== 4 ? (
          <Button
            type="primary"
            onClick={onNextStepRegisterForm}
            className="ml-3"
          >
            <Text color="#FFF">
              {currentPageRegister === 4 ? t("submit") : t("next")}
            </Text>
          </Button>
        ) : (
          currentPageRegister === 4 &&
          userPerm.CAN_SUBMIT_PP && (
            <Button
              type="primary"
              onClick={onNextStepRegisterForm}
              className="ml-3"
            >
              <Text color="#FFF">
                {currentPageRegister === 4 ? t("submit") : t("next")}
              </Text>
            </Button>
          )
        )}
      </Container>
      <CtmsAddNewParticipant
        t={t}
        form={manuallyRegisterForm}
        visible={modalManuallyVisible}
        setVisible={setModalManuallyVisible}
        selectStudyHandler={selectStudyHandler}
        loading={clinicTrial.registerForm.loading}
      />
    </Container>
  );
};

export default RegisterClinicTrialParticipant;
