import { Font, StyleSheet, View, Text as TextPDF } from "@react-pdf/renderer";
import SarabunRegular from "../../fonts/Sarabun-Regular.ttf";
import SarabunBold from "../../fonts/Sarabun-Bold.ttf";
import SarabunItalic from "../../fonts/Sarabun-Italic.ttf";

const sizeMedium = 8;
const fontFamily = "Sarabun";

Font.register({
  family: "Sarabun",
  fonts: [
    {
      src: SarabunRegular,
    },
    {
      src: SarabunBold,
      fontWeight: "bold",
    },
    {
      src: SarabunItalic,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  header: {
    fontFamily: fontFamily,
    marginTop: 20,
    marginBottom: 14,
    fontSize: sizeMedium,
    textAlign: "center",
    color: "grey",
  },
  dateSignedText: {
    width: 120,
    fontSize: sizeMedium,
    color: "grey",
  },
  timeText: {
    fontSize: sizeMedium,
    color: "grey",
  },
  subHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 18,
  },
  titleHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 10,
  },
  titleContainer: {
    backgroundColor: "#E4EDEE",
    borderWidth: 1,
    borderColor: "#4F4F4F",
    marginBottom: 15,
  },
  titleSpcBtwContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 15,
    borderWidth: 1,
    backgroundColor: "#E4EDEE",
    borderColor: "#4F4F4F",
    marginBottom: 10,
  },
  textSubTitle: {
    fontSize: 10,
    color: "#0000008C",
    fontFamily: fontFamily,
    fontWeight: "bold",
  },
  titleText: {
    fontSize: 10,
    paddingVertical: 6,
    color: "#000000BF",
    textAlign: "center",
    fontFamily: fontFamily,
    fontWeight: "bold",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  checkboxLabel: {
    fontSize: sizeMedium,
    marginLeft: 8,
    color: "grey",
  },
  logoEver: {
    width: 105,
  },
  signatureImg: {
    height: "auto",
    width: "100%",
    maxWidth: 100,
  },
  body: {
    flex: 1,
  },
  paddingBody: {
    paddingHorizontal: 25,
  },
  paddingFooter: {
    paddingHorizontal: 25,
    paddingBottom: 20,
  },
  tableContainer: {
    borderWidth: 2,
    borderColor: "#E0E0E0",
    borderRadius: 8,
  },
  borderBottomTable: {
    padding: 5,
    paddingHorizontal: 13,
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  divider: {
    height: 1,
    width: "100%",
    marginVertical: 15,
    backgroundColor: "#A5ABB2",
  },
  borderTopTable: {
    borderTopWidth: 2,
    borderTopColor: "#E0E0E0",
  },
  textCenter: {
    textAlign: "center",
  },
  fontBold: {
    fontFamily: fontFamily,
    fontWeight: "bold",
  },
  textDescription: {
    paddingVertical: 1,
    minHeight: 6,
    fontFamily: fontFamily,
    fontSize: sizeMedium,
    color: "#0000008C",
  },
  textBold: {
    paddingVertical: 1,
    minHeight: 6,
    fontSize: sizeMedium,
    color: "#0000008C",
    fontFamily: fontFamily,
    fontWeight: "bold",
  },
  textDescriptionCenter: {
    paddingVertical: 1,
    minHeight: 6,
    fontSize: sizeMedium,
    color: "#0000008C",
    fontFamily: fontFamily,
    textAlign: "center",
  },
  textBoxContainer: {
    flex: 1,
    marginLeft: 5,
    border: 1,
    minHeight: 16,
    borderRadius: 5,
    borderColor: "#aaa",
    padding: 2,
  },
  row: {
    flexDirection: "row",
  },
  rowInputBox: {
    marginVertical: 3,
    alignItems: "center",
    flexDirection: "row",
  },
  rowTwoContainer: {
    flex: 1,
    flexDirection: "row",
    marginHorizontal: 5,
    alignItems: "center",
  },
  colWidthdrawn: {
    width: 60,
    padding: 6,
    alignItems: "center",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  colLastWithDrawn: {
    width: 60,
    padding: 6,
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  col: {
    flexBasis: "auto",
    flexGrow: 1,
  },
  colItemWOFlex: {
    padding: 3,
    alignItems: "center",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  colLastItemWOFlex: {
    padding: 3,
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  colItemFlex: {
    flex: 1,
    padding: 3,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  colItemTNSS: {
    flex: 1,
    padding: 6,
    alignItems: "center",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  colItemSNOTT: {
    width: 25,
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  colLastItemSNOTT: {
    width: 25,
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderRightWidth: 1,
    borderColor: "#E0E0E0",
  },
  colItemProdFes: {
    flex: 1,
    padding: 6,
    alignItems: "center",
    borderRightWidth: 2,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: "#E0E0E0",
  },
  colHeaderAdv: {
    width: 25,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    justifyContent: "flex-end",
    borderColor: "#E0E0E0",
  },
  colItemAdv: {
    width: 25,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    justifyContent: "center",
    borderColor: "#E0E0E0",
  },
  colLastRowItemAdv: {
    width: 25,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderRightWidth: 1,
    justifyContent: "center",
    borderColor: "#E0E0E0",
  },
  viewCenter: {
    justifyContent: "center",
    alignItems: "flex-start",
  },
  colLastItem: {
    flex: 1,
    padding: 6,
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#E0E0E0",
  },
  colItemLastRow: {
    flex: 1,
    padding: 6,
    alignItems: "center",
    borderRightWidth: 1,
    borderColor: "#E0E0E0",
  },
  colLastItemLastRow: {
    flex: 1,
    padding: 6,
    alignItems: "center",
  },
  textVertical: {
    transform: "rotate(90deg)",
    width: "100%",
    paddingLeft: 7,
  },
  textVerticalAdv: {
    width: "100%",
    paddingLeft: 10,
    transform: "rotate(-90deg)",
    alignSelf: "center",
    position: "absolute",
    fontSize: sizeMedium,
    color: "#0000008C",
  },
  textVerticalWidthdrawn: {
    bottom: "50%",
    paddingLeft: 10,
    transform: "rotate(-90deg)",
    alignSelf: "center",
    position: "absolute",
    fontSize: 8,
    color: "#0000008C",
  },
  circleText: {
    alignSelf: "center",
    position: "absolute",
    borderRadius: 20,
    width: 14,
    height: 14,
    borderWidth: 1,
    borderColor: "grey",
  },
});

export const TitlePDFFile = ({ t }) => {
  return (
    <View style={styles.subHeader}>
      <TextPDF
        style={{
          fontFamily: fontFamily,
          fontSize: sizeMedium,
          textAlign: "center",
          color: "grey",
          fontStyle: "italic",
        }}
      >
        ({t("crf_title")})
      </TextPDF>
    </View>
  );
};

export const TableContainer = ({ children, style }) => {
  return <View style={{ ...styles.tableContainer, ...style }}>{children}</View>;
};

export default styles;
