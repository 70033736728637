import { call, put, takeEvery } from "redux-saga/effects";
import { PractitionerSpecialty } from "../../api";
import * as type from "../types";

function* getPractitonerSpecialties(roleId) {
  try {
    const result = yield call(
      PractitionerSpecialty.getpractitonerSpecialty,
      roleId
    );
    yield put({
      type: type.GET_PRACTITIONER_SPECIALTIES_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.GET_PRACTITIONER_SPECIALTIES_FAILED,
      error: e.message,
    });
  }
}

function* getAllPractitonerSpecialties() {
  try {
    const data = yield call(PractitionerSpecialty.getAllpractitonerSpecialty);
    yield put({
      type: type.GET_ALL_PRACTITIONER_SPECIALTIES_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: type.GET_ALL_PRACTITIONER_SPECIALTIES_FAILED,
      error: e.message,
    });
  }
}

function* practitionerSpecialtiesSaga() {
  yield takeEvery(
    type.GET_PRACTITIONER_SPECIALTIES_REQUESTED,
    getPractitonerSpecialties
  );
  yield takeEvery(
    type.GET_ALL_PRACTITIONER_SPECIALTIES_REQUESTED,
    getAllPractitonerSpecialties
  );
}

export default practitionerSpecialtiesSaga;
