import React from "react";
import { Row, Col, List, Button, Typography, Select } from "antd";
import { PlusOutlined, DeleteFilled, StarOutlined } from "@ant-design/icons";
import { FaPrescriptionBottleAlt } from "react-icons/fa";
import { SummaryCard } from "./styles";
import { ROLE_ACCESS_TO_REMOVE_DRUG } from "./constant";
import PrescriptionList from "../Modal/PrescriptionListModal/PrescriptionList";
import "./style.css";

const { Title } = Typography;

const PrescriptionSummary = ({
  t,
  role,
  booking,
  medicines,
  medication,
  updatePrescriptionItem,
  setMedicines,
  prescription,
  drugUnits,
  drugTimeEvent,
  drugTimeFrequency,
  onSearch,
  visiblePrescriptionList,
  setVisiblePrescriptionList,
  deleteDrugFromPrescription,
  getDrugTimeEventInfo,
  getDrugRouteInfo,
  getDrugUnitInfo,
  getDrugTimeFrequencyInfo,
  handleSelectMedicine,
}) => {
  return (
    <>
      <SummaryCard
        bodyStyle={{
          height: "36vh",
        }}
        title={<Title level={4}>{t("prescription")}</Title>}
        extra={
          booking.current.bookingCategory === "general" ? (
            <Row>
              {medicines.length === 0 ? (
                <Col style={{ paddingLeft: 10 }}>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => setVisiblePrescriptionList(true)}
                    className="d-flex justify-content-center align-items-center summary-button"
                  >
                    <p className="m-0 pl-2">{t("add_prescription_list")}</p>
                  </Button>
                </Col>
              ) : (
                <Col style={{ paddingLeft: 10 }}>
                  <Button
                    type="primary"
                    onClick={() => setVisiblePrescriptionList(true)}
                    className="summary-button"
                  >
                    {t("edit_list")}
                  </Button>
                </Col>
              )}
            </Row>
          ) : (
            <Row>
              <Col style={{ paddingLeft: 10 }}>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => setVisiblePrescriptionList(true)}
                  className="d-flex justify-content-center align-items-center summary-button"
                >
                  <p className="m-0 pl-2">{t("add_prescription_list")}</p>
                </Button>
              </Col>
            </Row>
          )
        }
      >
        <List
          style={{ height: "90%", overflow: "scroll", overflowX: "hidden" }}
          itemLayout="horizontal"
          dataSource={medicines}
          loading={prescription.loading}
          locale={{
            emptyText: (
              <div className="d-flex align-items-center justify-content-center flex-column text-center h-100">
                <FaPrescriptionBottleAlt
                  style={{ fontSize: "36px", color: "#757575" }}
                />
                <p className="mt-2 mb-5 text-dark">{t("no_prescription")}</p>
              </div>
            ),
          }}
          renderItem={(medicine) => {
            const { _medicationForOrg, amount, id } = medicine;
            const description = `${t("quantity")}: ${amount}`;
            return (
              <List.Item
                actions={[
                  ROLE_ACCESS_TO_REMOVE_DRUG.includes(role) && (
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<DeleteFilled />}
                      className="summary-button"
                      onClick={() => deleteDrugFromPrescription(id)}
                    />
                  ),
                ]}
              >
                <List.Item.Meta
                  title={_medicationForOrg.tradeName}
                  description={description}
                />
              </List.Item>
            );
          }}
        />
      </SummaryCard>
      <PrescriptionList
        deleteDrugFromPrescription={deleteDrugFromPrescription}
        visible={visiblePrescriptionList}
        setVisible={setVisiblePrescriptionList}
        handleSelectMedicine={handleSelectMedicine}
        onSearch={onSearch}
        medication={medication}
        updatePrescriptionItem={updatePrescriptionItem}
        setMedicines={setMedicines}
        drugUnits={drugUnits}
        drugTimeEvent={drugTimeEvent}
        drugTimeFrequency={drugTimeFrequency}
        getDrugTimeEventInfo={getDrugTimeEventInfo}
        getDrugRouteInfo={getDrugRouteInfo}
        getDrugUnitInfo={getDrugUnitInfo}
        getDrugTimeFrequencyInfo={getDrugTimeFrequencyInfo}
        medicines={medicines}
        prescription={prescription}
        t={t}
      />
    </>
  );
};

export default PrescriptionSummary;
