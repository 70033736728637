export const NOTIFICATION = {
  success: (language = "th") => ({
    body:
      language === "en"
        ? "Congratulations! Your account has been approved."
        : "ยินดีด้วย! บัญชีผู้ใช้ท่านได้รับการอนุมัติการใช้งานแล้ว",
    title: language === "en" ? "Alert" : "แจ้งเตือน",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
  failed: (language = "th") => ({
    body:
      language === "en"
        ? "Sorry, your account has been rejected. Please check your information before re-applying again."
        : "ต้องขออภัย บัญชีของท่านถูกปฏิเสธ โปรดตรวจสอบข้อมูลในการสมัครอีกครั้ง",
    title: language === "en" ? "Alert" : "แจ้งเตือน",
    icon: "ic_notification",
    color: "#f1c40f",
    sound: "default",
  }),
};

export const NOTIFICATION_DATA = {
  success: {
    auth: true,
    page: "Home",
  },
  failed: {
    auth: false,
    page: "Profile",
  },
};
