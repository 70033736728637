import * as type from "../types";

export function createDoctorReferral(payload) {
  return {
    type: type.CREATE_DOCTOR_REFERRAL_REQUESTED,
    payload,
  };
}

export function updateDoctorReferral(id, payload) {
  return {
    type: type.UPDATE_DOCTOR_REFERRAL_REQUESTED,
    id,
    payload,
  };
}

export function deleteDoctorReferral(id) {
  return {
    type: type.DELETE_DOCTOR_REFERRAL_REQUESTED,
    id,
  };
}

export function getReferralsByBookingId(id) {
  return {
    type: type.GET_REFERRALS_BY_BOOKING_ID_REQUESTED,
    id,
  };
}
