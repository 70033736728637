import React from "react";
import { BookOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { Container, HeaderWrapper, Text, Search } from "./styles";
import Table from "../Table";
import moment from "moment";

const HistoryBooking = (props) => {
  const {
    t,
    filterData,
    visibleNote,
    setVisibleNote,
    setNote,
    note,
    checkAddress,
    onSearch,
  } = props;
  const COLUMNS = [
    {
      title: t("date_time"),
      key: "dateTime",
      width: 150,
      align: "center",
      fixed: "left",
      render: (record) =>
        moment(record.admitTime).format("DD/MM/YYYY, HH:mm:ss"),
      sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
      defaultSortOrder: "descend",
    },
    {
      title: t("full_name"),
      width: 250,
      align: "center",
      render: (record) => {
        const userInfo =
          record.patient && record.patient.userInformation
            ? record.patient.userInformation
            : null;
        return userInfo ? `${userInfo.firstname} ${userInfo.lastname}` : "-";
      },
    },
    {
      title: t("consultation_type"),
      width: 150,
      align: "center",
      dataIndex: "bookingCategory",
    },
    {
      title: t("consult_with"),
      key: "consultWith",
      width: 200,
      align: "center",
      render: (record) => {
        const practitionerInfo =
        record.practitionerAppUser && record.practitionerAppUser.practitioner
          ? record.practitionerAppUser.practitioner
          : null;
      return practitionerInfo ? `${practitionerInfo.firstName} ${practitionerInfo.lastName}` : "-";
      },
    },
    
    {
      title: t("note"),
      key: "note",
      width: 100,
      dataIndex: ["notes"],
      render: (text) =>
        text && (
          <Button
            onClick={() => {
              setVisibleNote(true);
              setNote(text);
            }}
          >
            Note
          </Button>
        ),
    },
    {
      title: t("address"),
      key: "address",
      width: 300,
      align: "center",
      render: (record) => {
        if (record.patient && record.patient.userInformation) {
          const address = checkAddress(
            record.patient.userInformation.addresses,
            1
          );
          if (address) {
            return `เลขที่ ${address.no || "-"} หมู่บ้าน ${
              address.village || "-"
            } ซอย ${address.soi || "-"} ${address.road || "ถนน -"}`;
          }
        }
        return "-";
      },
    },
  ];
  const { history } = props;
  return (
    <Container>
      <HeaderWrapper>
        <BookOutlined />
        <Text bold style={{ paddingTop: 10 }}>
          {t("history_booking")} ({history.data.length})
        </Text>
        <Search
          style={{ width: 200, marginLeft: "auto" }}
          onSearch={onSearch}
        />
      </HeaderWrapper>
      <Table
        columns={COLUMNS}
        loading={history.loading}
        dataSource={filterData || history.data}
      />
      <Modal
        title="Note"
        visible={visibleNote}
        footer={null}
        onOk={() => setVisibleNote(false)}
        onCancel={() => setVisibleNote(false)}
      >
        <div>{note && <div>{note}</div>}</div>
      </Modal>
    </Container>
  );
};

export default HistoryBooking;
