import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import CtmsIconHeaderModal from "../Modal/CtmsIconHeaderModal";
import CtmsWithdrawnFormModal from "../Modal/CtmsWithdrawnFormModal";
import { useDispatch } from "react-redux";
import { ClinicTrial } from "../../redux/actions";
import TableParticipant from "./TableParticipant";

const ClinicTrialParticipantList = (props) => {
  const {
    history,
    t,
    clinicTrial,
    userPerm,
    fetchParticipantListHandler,
    onSubmitSeperateSectionHandler,
    onDeleteDevationsHandler,
  } = props;
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawnModal, setWithdrawnModal] = useState(false);
  const [withdrawnFormModal, setWithdrawnFormModal] = useState(false);
  useEffect(() => {
    fetchParticipantListHandler(1);
  }, []);

  useEffect(() => {
    if (clinicTrial.deviationData?.submit) {
      setWithdrawnFormModal(false);
      fetchParticipantListHandler(1);
    }
  }, [clinicTrial.deviationData?.submit]);

  useEffect(() => {
    if (
      clinicTrial.particitpantList?.isSuccess &&
      clinicTrial.particitpantList?.data &&
      Array.isArray(clinicTrial.particitpantList?.data)
    ) {
      setData(clinicTrial.particitpantList?.data);
    }
  }, [
    clinicTrial.particitpantList?.data,
    clinicTrial.particitpantList?.isSuccess,
  ]);

  const onWitdrawnSubmitHandler = (payload) => {
    if (selectedItem.id) {
      dispatch(
        ClinicTrial.withdrawnResearchObjectById(selectedItem.id, payload)
      );
    }
  };

  const onOpenWithDrawnModal = () => {
    if (selectedItem.id) {
      dispatch(ClinicTrial.getDeviationsById(selectedItem.id));
      setWithdrawnFormModal(true);
    }
  };

  return (
    <Container>
      <Container>
        <TableParticipant
          t={t}
          data={data}
          setData={setData}
          history={history}
          userPerm={userPerm}
          loading={clinicTrial.particitpantList.loading}
        />
      </Container>
      <CtmsIconHeaderModal
        t={t}
        key="withdrawn"
        body={
          <>
            {t("withdrawn_confirmation_body")}{" "}
            <b>
              {t("subject_id")}: {selectedItem?.filtering?.subjectId}?
            </b>
          </>
        }
        title={t("withdrawn_confirmation")}
        visible={withdrawnModal}
        setVisible={setWithdrawnModal}
        onClick={onOpenWithDrawnModal}
      />
      <CtmsWithdrawnFormModal
        t={t}
        researchId={selectedItem?.id ?? null}
        subjectId={selectedItem.filtering?.subjectId ?? ""}
        clinicTrial={clinicTrial}
        visible={withdrawnFormModal}
        setVisible={setWithdrawnFormModal}
        onWitdrawnSubmitHandler={onWitdrawnSubmitHandler}
        onSubmitSeperateSectionHandler={(id, payload) =>
          onSubmitSeperateSectionHandler(selectedItem.id, id, payload)
        }
        onDeleteDevationsHandler={(id) =>
          onDeleteDevationsHandler(selectedItem.id, id)
        }
      />
    </Container>
  );
};

export default ClinicTrialParticipantList;
