import { call, put, takeEvery } from "redux-saga/effects";
import {
  DoctorReferral,
  Booking,
  Practitioner,
  Clinic,
  ClinicPackages,
} from "../../api";
import * as type from "../types";

function* createDoctorReferral({ payload }) {
  try {
    const result = yield call(DoctorReferral.createDoctorReferral, payload);
    let detailRefer;
    if (result?.referralType === "DOCTOR") {
      detailRefer = yield call(
        Practitioner.getPractitionerById,
        result?.referralId
      );
    } else if (result?.referralType === "CLINIC") {
      detailRefer = yield call(Clinic.getClinicById, result?.referralId);
    } else if (result?.referralType === "TREATMENT") {
      detailRefer = yield call(
        ClinicPackages.getClinicPackageById,
        result?.referralId
      );
    }
    yield put({
      type: type.CREATE_DOCTOR_REFERRAL_SUCCESS,
      data: result,
      detail: detailRefer,
    });
  } catch (e) {
    yield put({
      type: type.CREATE_DOCTOR_REFERRAL_FAILED,
      error: e.message,
    });
  }
}

function* deleteDoctorReferral({ id }) {
  try {
    const result = yield call(DoctorReferral.deleteDoctorReferral, id);
    yield put({
      type: type.DELETE_DOCTOR_REFERRAL_SUCCESS,
      data: result,
    });
  } catch (e) {
    yield put({
      type: type.DELETE_DOCTOR_REFERRAL_FAILED,
      error: e.message,
    });
  }
}

function* updateDoctorReferral({ id, payload }) {
  try {
    const result = yield call(DoctorReferral.updateDoctorReferral, id, payload);
    let detailRefer;
    if (result?.referralType === "DOCTOR") {
      detailRefer = yield call(
        Practitioner.getPractitionerById,
        result?.referralId
      );
    } else if (result?.referralType === "CLINIC") {
      detailRefer = yield call(Clinic.getClinicById, result?.referralId);
    } else if (result?.referralType === "TREATMENT") {
      detailRefer = yield call(
        ClinicPackages.getClinicPackageById,
        result?.referralId
      );
    }
    yield put({
      type: type.UPDATE_DOCTOR_REFERRAL_SUCCESS,
      data: result,
      detail: detailRefer,
    });
  } catch (e) {
    yield put({
      type: type.UPDATE_DOCTOR_REFERRAL_FAILED,
      error: e.message,
    });
  }
}

function* getReferralsByBookingId({ id }) {
  try {
    const result = yield call(Booking.getDoctorReferralByBookingId, id);
    const reserveResult = Array.isArray(result) ? result.pop() : {};
    let detailRefer;
    if (reserveResult?.referralType === "DOCTOR") {
      detailRefer = yield call(
        Practitioner.getPractitionerById,
        reserveResult?.referralId
      );
    } else if (reserveResult?.referralType === "CLINIC") {
      detailRefer = yield call(Clinic.getClinicById, reserveResult?.referralId);
    } else if (reserveResult?.referralType === "TREATMENT") {
      detailRefer = yield call(
        ClinicPackages.getClinicPackageById,
        reserveResult?.referralId
      );
    }
    yield put({
      type: type.GET_REFERRALS_BY_BOOKING_ID_SUCCESS,
      data: reserveResult,
      detail: detailRefer,
    });
  } catch (e) {
    yield put({
      type: type.GET_REFERRALS_BY_BOOKING_ID_FAILED,
      error: e.message,
    });
  }
}

function* doctorReferralSaga() {
  yield takeEvery(type.DELETE_DOCTOR_REFERRAL_REQUESTED, deleteDoctorReferral);
  yield takeEvery(type.CREATE_DOCTOR_REFERRAL_REQUESTED, createDoctorReferral);
  yield takeEvery(type.UPDATE_DOCTOR_REFERRAL_REQUESTED, updateDoctorReferral);
  yield takeEvery(
    type.GET_REFERRALS_BY_BOOKING_ID_REQUESTED,
    getReferralsByBookingId
  );
}

export default doctorReferralSaga;
