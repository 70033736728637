import * as type from "../types";

export function getResearchProgramsByOrganization(id) {
  return {
    type: type.GET_RESEARCH_PROGRAM_BY_ORG_REQUEST,
    id,
  };
}

export function getRecruitmentObjectsByOrgAndProgram(
  id,
  programId,
  statusList
) {
  return {
    type: type.GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST,
    id,
    programId,
    statusList,
  };
}

export function getResearchObjectsByOrgAndProgram(id, programId, statusList) {
  return {
    type: type.GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST,
    id,
    programId,
    statusList,
  };
}

export function createConsentData(id, payload) {
  return {
    type: type.CREATE_CLINIC_TRIAL_CONSENT_FORM_REQUEST,
    id,
    payload,
  };
}

export function createGeneralInfoForm(id, payload) {
  return {
    type: type.CREATE_CLINIC_TRIAL_GENERAL_FORM_REQUEST,
    id,
    payload,
  };
}

export function createContactInfoForm(id, payload) {
  return {
    type: type.CREATE_CLINIC_TRIAL_CONTACT_FORM_REQUEST,
    id,
    payload,
  };
}

export function createEligibilityForm(id, payload) {
  return {
    type: type.CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST,
    id,
    payload,
  };
}

export function updateEligibilityForm(id, fk, payload) {
  return {
    type: type.UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST,
    id,
    fk,
    payload,
  };
}

export function editEligibilityForm(id, fk, payload) {
  return {
    type: type.EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST,
    id,
    fk,
    payload,
  };
}

export function getAssessmentObjectById(id) {
  return {
    type: type.GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_REQUEST,
    id,
  };
}

export function addNewEligibilityAssessment(id, payload) {
  return {
    type: type.ADD_NEW_CLINIC_ASSESSMENT_BY_ID_REQUEST,
    id,
    payload,
  };
}

export function approveResearchObjectById(id, payload) {
  return {
    type: type.APPROVE_CLINIC_TRIAL_REQUEST,
    id,
    payload,
  };
}

export function approveResearchObjectManually(id, payload, criteriaPayload) {
  return {
    type: type.APPROVE_CLINIC_TRIAL_MANUALLY_REQUEST,
    id,
    payload,
    criteriaPayload,
  };
}

export function declineResearchObjectById(id, payload) {
  return {
    type: type.DECLINE_CLINIC_TRIAL_REQUEST,
    id,
    payload,
  };
}

export function withdrawnResearchObjectById(id, payload) {
  return {
    type: type.WITHDRAWN_CLINIC_TRIAL_REQUEST,
    id,
    payload,
  };
}

export function updateRecruitmentObject(id, payload) {
  return {
    type: type.UPDATE_RECRUITMENT_OBJECT_REQUEST,
    id,
    payload,
  };
}

export function createDeviationsById(id, payload) {
  return {
    type: type.CREATE_CLINIC_TRIAL_DEVIATIONS_REQUEST,
    id,
    payload,
  };
}

export function updateDeviationById(id, fk, payload) {
  return {
    type: type.UPDATE_CLINIC_TRIAL_DEVIATIONS_REQUEST,
    id,
    fk,
    payload,
  };
}

export function getDeviationsById(id) {
  return {
    type: type.GET_CLINIC_TRIAL_DEVIATIONS_REQUEST,
    id,
  };
}

export function deleteDebviationById(id, fk) {
  return {
    type: type.DELETE_DEVIATIONS_BY_ID_REQUEST,
    id,
    fk,
  };
}

export function getResearchObjectsByOrganization(id, page, limit, statusList) {
  return {
    type: type.GET_RESEARCH_OBJECTS_REQUESTED,
    id,
    page,
    limit,
    statusList,
  };
}
