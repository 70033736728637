import * as type from "../types";

const initialState = {
  data: [],
  meta: {},
  current: false,
  loading: false,
  error: null,
  history: {
    loading: false,
    error: null,
    isSuccess: false,
    data: [],
    earning: {
      daily: 0,
      weekly: 0,
      monthly: 0,
      total: 0,
    },
  },
  prescription: {
    loading: false,
    error: null,
    isSuccess: false,
    data: [],
  },
  payment: {
    loading: false,
    error: null,
    data: [],
    isSuccess: false,
  },
  medication: [],
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_BOOKINGS_REQUESTED:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: null,
      };
    case type.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        data: action.data,
      };
    case type.GET_BOOKINGS_FAILED:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    case type.GET_BOOKING_PAYMENTS_REQUESTED:
      return {
        ...state,
        payment: {
          loading: true,
          isSuccess: false,
          data: [],
          error: null,
        },
      };
    case type.GET_BOOKING_PAYMENTS_SUCCESS:
      return {
        ...state,
        payment: {
          loading: false,
          isSuccess: true,
          data: action.data,
          error: null,
        },
      };
    case type.GET_BOOKING_PAYMENTS_FAILED:
      return {
        ...state,
        payment: {
          loading: false,
          isSuccess: false,
          data: [],
          error: action.error,
        },
      };
    case type.ADD_BOOKING_PRESCRIPTION_ITEMS_REQUESTED:
      return {
        ...state,
        prescription: {
          loading: true,
          isSuccess: false,
          data: state.prescription.data,
          error: null,
        },
      };
    case type.ADD_BOOKING_PRESCRIPTION_ITEMS_SUCCESS:
      return {
        ...state,
        prescription: {
          loading: false,
          isSuccess: true,
          data: [...state.prescription.data, action.data],
          error: null,
        },
      };
    case type.ADD_BOOKING_PRESCRIPTION_ITEMS_FAILED:
      return {
        ...state,
        prescription: {
          loading: false,
          isSuccess: false,
          data: [],
          error: null,
        },
      };
    case type.GET_BOOKING_PRESCRIPTION_ITEMS_REQUESTED:
      return {
        ...state,
        prescription: {
          loading: true,
          isSuccess: false,
          data: [],
          error: null,
        },
      };
    case type.GET_BOOKING_PRESCRIPTION_ITEMS_SUCCESS:
      return {
        ...state,
        prescription: {
          loading: false,
          isSuccess: true,
          data: action.data,
          error: null,
        },
      };
    case type.GET_BOOKING_PRESCRIPTION_ITEMS_FAILED:
      return {
        ...state,
        prescription: {
          loading: false,
          isSuccess: false,
          data: [],
          error: action.error,
        },
      };
    case type.DELETE_BOOKING_PRESCRIPTION_ITEM_REQUESTED:
      return {
        ...state,
        prescription: {
          loading: true,
          isSuccess: false,
          error: null,
          data: state.prescription.data,
        },
      };
    case type.DELETE_BOOKING_PRESCRIPTION_ITEM_SUCCESS:
      return {
        ...state,
        prescription: {
          loading: false,
          isSuccess: true,
          data: state.prescription.data.filter(
            (pres) => pres.id !== action.data
          ),
          error: null,
        },
      };
    case type.DELETE_BOOKING_PRESCRIPTION_ITEM_FAILED:
      return {
        ...state,
        prescription: {
          loading: false,
          isSuccess: false,
          error: action.error,
          data: [],
        },
      };
    case type.GET_HISTORY_BOOKING_BY_DATE_TIME_REQUESTED:
      return {
        ...state,
        history: {
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_HISTORY_BOOKING_BY_DATE_TIME_SUCCESS:
      return {
        ...state,
        history: {
          loading: false,
          isSuccess: true,
          data: action.data,
          error: null,
        },
      };
    case type.GET_HISTORY_BOOKING_BY_DATE_TIME_FAILED:
      return {
        ...state,
        history: {
          loading: false,
          isSuccess: false,
          error: action.error,
        },
      };
    case type.GET_BOOKING_HISTORY_WITH_EARNING_REQUESTED:
      return {
        ...state,
        history: {
          loading: true,
          isSuccess: false,
          error: null,
          earning: {
            daily: 0,
            weekly: 0,
            monthly: 0,
            total: 0,
          },
        },
      };
    case type.GET_BOOKING_HISTORY_WITH_EARNING_SUCCESS:
      return {
        ...state,
        history: {
          loading: false,
          isSuccess: true,
          data: action.data,
          earning: action.earning,
          error: null,
        },
      };
    case type.GET_BOOKING_HISTORY_WITH_EARNING_FAILED:
      return {
        ...state,
        history: {
          loading: false,
          isSuccess: false,
          error: action.error,
        },
      };
    case type.GET_BOOKING_REQUESTED:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: null,
      };
    case type.GET_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        current: action.data,
        medication: action.data.prescription ? action.data.prescription : [],
      };
    case type.GET_BOOKING_FAILED:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.message,
      };
    case type.GET_BOOKING_HISTORY_REQUESTED:
      return {
        ...state,
        history: {
          loading: true,
          isSuccess: false,
          error: null,
        },
      };
    case type.GET_BOOKING_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          loading: false,
          isSuccess: true,
          data: action.data,
        },
      };
    case type.GET_BOOKING_HISTORY_FAILED:
      return {
        ...state,
        history: {
          loading: false,
          isSuccess: false,
          error: action.error,
        },
      };
    case type.UPDATE_BOOKING_REQUESTED:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: null,
      };
    case type.UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.data.id) {
            let prevItem = action.data;
            prevItem = {
              ...prevItem,
              patient: item.patient,
              practitionerAppUser: item.practitionerAppUser,
            };
            return prevItem;
          }
          return item;
        }),
        current: {
          ...action.data,
          patient: state.current.patient,
          practitionerAppUser: state.current.practitionerAppUser,
        },
        isSuccess: true,
        loading: false,
      };
    case type.DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        data: state.data.filter((item) => item.id !== action.data.id),
      };
    case type.UPDATE_BOOKING_FAILED:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    case type.DELETE_MEDICATION_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        medication: action.data.prescription ? action.data.prescription : [],
      };
    case type.DELETE_MEDICATION_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    case type.DELETE_MEDICATION_ITEM_REQUESTED:
      return {
        ...state,
        error: null,
        isSuccess: false,
      };
    case type.GET_CURRENT_BOOKING_REQUESTED:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: null,
      };
    case type.GET_CURRENT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        current: action.data,
      };
    case type.GET_CURRENT_BOOKING_FAILED:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    case type.GET_BOOKINGS_META_DATA_SUCCESS:
      return {
        ...state,
        meta: action.data,
        loading: false,
      };
    case type.SEARCH_BOOKING_REQUESTED:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: null,
      };
    case type.SEARCH_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        data: action.data,
      };
    case type.SEARCH_BOOKING_FAILED:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    default:
      return state;
  }
};
