export const GET_AVAILABLE_TIMES_REQUESTED = "GET_AVAILABLE_TIMES_REQUESTED";
export const GET_AVAILABLE_TIMES_SUCCESS = "GET_AVAILABLE_TIMES_SUCCESS";
export const GET_AVAILABLE_TIMES_FAILED = "GET_AVAILABLE_TIMES_FAILED";
export const UPDATE_AVAILABLE_TIME_REQUESTED =
  "UPDATE_AVAILABLE_TIME_REQUESTED";
export const UPDATE_AVAILABLE_TIME_SUCCESS = "UPDATE_AVAILABLE_TIME_SUCCESS";
export const UPDATE_AVAILABLE_TIME_FAILED = "UPDATE_AVAILABLE_TIME_FAILED";

export const GET_BOOKINGS_REQUESTED = "GET_BOOKINGS_REQUESTED";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAILED = "GET_BOOKINGS_FAILED";
export const GET_BOOKING_HISTORY_REQUESTED = "GET_BOOKING_HISTORY_REQUESTED";
export const GET_BOOKING_HISTORY_SUCCESS = "GET_BOOKING_HISTORY_SUCCESS";
export const GET_BOOKING_HISTORY_FAILED = "GET_BOOKING_HISTORY_FAILED";
export const GET_BOOKING_HISTORY_WITH_EARNING_REQUESTED =
  "GET_BOOKING_HISTORY_WITH_EARNING_REQUESTED";
export const GET_BOOKING_HISTORY_WITH_EARNING_SUCCESS =
  "GET_BOOKING_HISTORY_WITH_EARNING_SUCCESS";
export const GET_BOOKING_HISTORY_WITH_EARNING_FAILED =
  "GET_BOOKING_HISTORY_WITH_EARNING_FAILED";
export const GET_BOOKING_REQUESTED = "GET_BOOKING_REQUESTED";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_FAILED = "GET_BOOKING_FAILED";
export const GET_HISTORY_BOOKING_BY_DATE_TIME_REQUESTED =
  "GET_HISTORY_BOOKING_BY_DATE_TIME_REQUESTED";
export const GET_HISTORY_BOOKING_BY_DATE_TIME_SUCCESS =
  "GET_HISTORY_BOOKING_BY_DATE_TIME_SUCCESS";
export const GET_HISTORY_BOOKING_BY_DATE_TIME_FAILED =
  "GET_HISTORY_BOOKING_BY_DATE_TIME_FAILED";
export const SEARCH_BOOKING_REQUESTED = "SEARCH_BOOKING_REQUESTED";
export const SEARCH_BOOKING_SUCCESS = "SEARCH_BOOKING_SUCCESS";
export const SEARCH_BOOKING_FAILED = "SEARCH_BOOKING_FAILED";
export const ADD_BOOKING_PRESCRIPTION_ITEMS_REQUESTED =
  "ADD_BOOKING_PRESCRIPTION_ITEMS_REQUESTED";
export const ADD_BOOKING_PRESCRIPTION_ITEMS_SUCCESS =
  "ADD_BOOKING_PRESCRIPTION_ITEMS_SUCCESS";
export const ADD_BOOKING_PRESCRIPTION_ITEMS_FAILED =
  "ADD_BOOKING_PRESCRIPTION_ITEMS_FAILED";
export const UPDATE_BOOKING_PRESCRIPTION_ITEM_REQUESTED =
  "UPDATE_BOOKING_PRESCRIPTION_ITEM_REQUESTED";
export const UPDATE_BOOKING_PRESCRIPTION_ITEM_SUCCESS =
  "UPDATE_BOOKING_PRESCRIPTION_ITEM_SUCCESS";
export const UPDATE_BOOKING_PRESCRIPTION_ITEM_FAILED =
  "UPDATE_BOOKING_PRESCRIPTION_ITEM_FAILED";
export const GET_BOOKING_PRESCRIPTION_ITEMS_REQUESTED =
  "GET_BOOKING_PRESCRIPTION_ITEMS_REQUESTED";
export const GET_BOOKING_PRESCRIPTION_ITEMS_SUCCESS =
  "GET_BOOKING_PRESCRIPTION_ITEMS_SUCCESS";
export const GET_BOOKING_PRESCRIPTION_ITEMS_FAILED =
  "GET_BOOKING_PRESCRIPTION_ITEMS_FAILED";
export const DELETE_BOOKING_PRESCRIPTION_ITEM_REQUESTED =
  "DELETE_BOOKING_PRESCRIPTION_ITEM_REQUESTED";
export const DELETE_BOOKING_PRESCRIPTION_ITEM_SUCCESS =
  "DELETE_BOOKING_PRESCRIPTION_ITEM_SUCCESS";
export const DELETE_BOOKING_PRESCRIPTION_ITEM_FAILED =
  "DELETE_BOOKING_PRESCRIPTION_ITEM_FAILED";
export const GET_BOOKING_PAYMENTS_REQUESTED = "GET_BOOKING_PAYMENTS_REQUESTED";
export const GET_BOOKING_PAYMENTS_SUCCESS = "GET_BOOKING_PAYMENTS_SUCCESS";
export const GET_BOOKING_PAYMENTS_FAILED = "GET_BOOKING_PAYMENTS_FAILED";

export const GET_BOOKINGS_META_DATA_SUCCESS = "GET_BOOKINGS_META_DATA_SUCCESS";

export const UPDATE_BOOKING_REQUESTED = "UPDATE_BOOKING_REQUESTED";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAILED = "UPDATE_BOOKING_FAILED";

export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";

export const GET_TOKEN_REQUESTED = "GET_TOKEN_REQUESTED";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_FAILED = "GET_TOKEN_FAILED";
export const DELETE_TOKEN_REQUESTED = "DELETE_TOKEN_REQUESTED";
export const DELETE_TOKEN_SUCCESS = "DELETE_TOKEN_SUCCESS";
export const DELETE_TOKEN_FAILED = "DELETE_TOKEN_FAILED";

export const GET_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED =
  "GET_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED";
export const GET_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS =
  "GET_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS";
export const GET_ROOM_BY_COUNTRY_AND_SPECIALTY_FAILED =
  "GET_ROOM_BY_COUNTRY_AND_SPECIALTY_FAILED";
export const UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED =
  "UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_REQUESTED";
export const UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS =
  "UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_SUCCESS";
export const UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_FAILED =
  "UPDATE_ROOM_BY_COUNTRY_AND_SPECIALTY_FAILED";

export const GET_ROOM_TYPE = {
  SCHEDULING: {
    REQUESTED: "GET_SCHEDULING_ROOMS_REQUESTED",
    SUCCESS: "GET_SCHEDULING_ROOMS_SUCCESS",
    FAILED: "GET_SCHEDULING_ROOMS_FAILED",
  },
  COVID: {
    REQUESTED: "GET_ROUND_ROBIN_COVID_ROOMS_REQUESTED",
    SUCCESS: "GET_ROUND_ROBIN_COVID_ROOMS_SUCCESS",
    FAILED: "GET_ROUND_ROBIN_COVID_ROOMS_FAILED",
  },
  GENERAL: {
    REQUESTED: "GET_ROUND_ROBIN_GENERAL_ROOMS_REQUESTED",
    SUCCESS: "GET_ROUND_ROBIN_GENERAL_ROOMS_SUCCESS",
    FAILED: "GET_ROUND_ROBIN_GENERAL_ROOMS_FAILED",
  },
  COMMUNITY_PHARMACIST: {
    REQUESTED: "GET_COMMUNITY_PHARMACIST_ROOMS_REQUESTED",
    SUCCESS: "GET_COMMUNITY_PHARMACIST_ROOMS_SUCCESS",
    FAILED: "GET_COMMUNITY_PHARMACIST_ROOMS_FAILED",
  },
};
export const GET_ROOM_SUCCESS = "GET_ROOM_SUCCESS";
export const UPDATE_ROOM_TYPE = {
  SCHEDULING: {
    REQUESTED: "UPDATE_SCHEDULING_ROOMS_REQUESTED",
    SUCCESS: "UPDATE_SCHEDULING_ROOMS_SUCCESS",
    FAILED: "UPDATE_SCHEDULING_ROOMS_FAILED",
  },
  COVID: {
    REQUESTED: "UPDATE_ROUND_ROBIN_COVID_ROOMS_REQUESTED",
    SUCCESS: "UPDATE_ROUND_ROBIN_COVID_ROOMS_SUCCESS",
    FAILED: "UPDATE_ROUND_ROBIN_COVID_ROOMS_FAILED",
  },
  GENERAL: {
    REQUESTED: "UPDATE_ROUND_ROBIN_GENERAL_ROOMS_REQUESTED",
    SUCCESS: "UPDATE_ROUND_ROBIN_GENERAL_ROOMS_SUCCESS",
    FAILED: "UPDATE_ROUND_ROBIN_GENERAL_ROOMS_FAILED",
  },
  COMMUNITY_PHARMACIST: {
    REQUESTED: "UPDATE_COMMUNITY_PHARMACIST_ROOMS_REQUESTED",
    SUCCESS: "UPDATE_COMMUNITY_PHARMACIST_ROOMS_SUCCESS",
    FAILED: "UPDATE_COMMUNITY_PHARMACIST_ROOMS_FAILED",
  },
};

export const GET_CURRENT_BOOKING_REQUESTED = "GET_CURRENT_BOOKING_REQUESTED";
export const GET_CURRENT_BOOKING_SUCCESS = "GET_CURRENT_BOOKING_SUCCESS";
export const GET_CURRENT_BOOKING_FAILED = "GET_CURRENT_BOOKING_FAILED";

export const GET_CURRENT_ROOM_REQUESTED = "GET_CURRENT_ROOM_REQUESTED";
export const GET_CURRENT_ROOM_SUCCESS = "GET_CURRENT_ROOM_SUCCESS";
export const GET_CURRENT_ROOM_FAILED = "GET_CURRENT_ROOM_FAILED";

export const LOGIN_APP_USER_OMA_REQUESTED = "LOGIN_APP_USER_OMA_REQUESTED";
export const LOGIN_APP_USER_OMA_SUCCESS = "LOGIN_APP_USER_OMA_SUCCESS";
export const LOGIN_APP_USER_OMA_FAILED = "LOGIN_APP_USER_OMA_FAILED";
export const CREATE_APP_USER_OMA_REQUESTED = "CREATE_APP_USER_OMA_REQUESTED";
export const CREATE_APP_USER_OMA_SUCCESS = "CREATE_APP_USER_OMA_SUCCESS";
export const CREATE_APP_USER_OMA_FAILED = "CREATE_APP_USER_OMA_FAILED";
export const GET_APP_USER_OMA_REQUESTED = "GET_APP_USER_OMA_REQUESTED";
export const GET_APP_USER_OMA_SUCCESS = "GET_APP_USER_OMA_SUCCESS";
export const GET_APP_USER_OMA_FAILED = "GET_APP_USER_OMA_FAILED";
export const GET_PROFILE_REQUESTED = "GET_PROFILE_REQUESTED";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";
export const UPDATE_PROFILE_REQUESTED = "UPDATE_PROFILE_REQUESTED";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const ADD_PHOTO_REQUESTED = "ADD_PHOTO_REQUESTED";
export const ADD_PHOTO_SUCCESS = "ADD_PHOTO_SUCCESS";
export const ADD_PHOTO_FAILED = "ADD_PHOTO_FAILED";
export const UPDATE_PHOTO_REQUESTED = "UPDATE_PHOTO_REQUESTED";
export const UPDATE_PHOTO_SUCCESS = "UPDATE_PHOTO_SUCCESS";
export const UPDATE_PHOTO_FAILED = "UPDATE_PHOTO_FAILED";
export const UPDATE_SPECIALTIES_REQUESTED = "UPDATE_SPECIALTIES_REQUESTED";
export const UPDATE_SPECIALTIES_SUCCESS = "UPDATE_SPECIALTIES_SUCCESS";
export const UPDATE_SPECIALTIES_FAILED = "UPDATE_SPECIALTIES_FAILED";
export const DELETE_APP_USER_OMA_REQUESTED = "DELETE_APP_USER_OMA_REQUESTED";
export const DELETE_APP_USER_OMA_SUCCESS = "DELETE_APP_USER_OMA_SUCCESS";
export const DELETE_APP_USER_OMA_FAILED = "DELETE_APP_USER_OMA_FAILED";
export const GET_ORGANIZATIONS_BY_USER_REQUESTED =
  "GET_ORGANIZATIONS_BY_USER_REQUESTED";
export const GET_ORGANIZATIONS_BY_USER_SUCCESS =
  "GET_ORGANIZATIONS_BY_USER_SUCCESS";
export const GET_ORGANIZATIONS_BY_USER_FAILED =
  "GET_ORGANIZATIONS_BY_USER_FAILED";
export const GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_REQUESTED =
  "GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_REQUESTED";
export const GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_SUCCESS =
  "GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_SUCCESS";
export const GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_FAILED =
  "GET_COMMUNITY_PHARMACIES_BY_ORGANIZATION_FAILED";

export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const GET_MENUS_REQUESTED = "GET_MENUS_REQUESTED";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";
export const GET_MENUS_FAILED = "GET_MENUS_FAILED";
export const SET_CURRENT_MENU_REQUESTED = "SET_CURRENT_MENU_REQUESTED";
export const SET_CURRENT_MENU_SUCCESS = "SET_CURRENT_MENU_SUCCESS";
export const SET_CURRENT_MENU_FAILED = "SET_CURRENT_MENU_FAILED";

export const GET_USER_OMA_REQUESTED = "GET_USER_OMA_REQUESTED";
export const GET_USER_OMA_SUCCESS = "GET_USER_OMA_SUCCESS";
export const GET_USER_OMA_FAILED = "GET_USER_OMA_FAILED";
export const GET_USER_OMA_ID_REQUESTED = "GET_USER_OMA_ID_REQUESTED";
export const GET_USER_OMA_ID_SUCCESS = "GET_USER_OMA_ID_SUCCESS";
export const GET_USER_OMA_ID_FAILED = "GET_USER_OMA_ID_FAILED";
export const GET_USER_OMA_BY_HIE_REQUESTED = "GET_USER_OMA_BY_HIE_REQUESTED";
export const GET_USER_OMA_BY_HIE_SUCCESS = "GET_USER_OMA_BY_HIE_SUCCESS";
export const GET_USER_OMA_BY_HIE_FAILED = "GET_USER_OMA_BY_HIE_FAILED";
export const UPDATE_USER_OMA_REQUESTED = "UPDATE_USER_OMA_REQUESTED";
export const UPDATE_USER_OMA_SUCCESS = "UPDATE_USER_OMA_SUCCESS";
export const UPDATE_USER_OMA_FAILED = "UPDATE_USER_OMA_FAILED";
export const GET_USER_OMA_DIAGNOSIS_REQUESTED =
  "GET_USER_OMA_DIAGNOSIS_REQUESTED";
export const GET_USER_OMA_DIAGNOSIS_SUCCESS = "GET_USER_OMA_DIAGNOSIS_SUCCESS";
export const GET_USER_OMA_DIAGNOSIS_FAILED = "GET_USER_OMA_DIAGNOSIS_FAILED";
export const CLEAR_USER_OMA = "CLEAR_USER_OMA";

export const GET_KYC_IMAGES_REQUESTED = "GET_KYC_IMAGES_REQUESTED";
export const GET_KYC_IMAGES_SUCCESS = "GET_KYC_IMAGES_SUCCESS";
export const GET_KYC_IMAGES_FAILED = "GET_KYC_IMAGES_FAILED";

export const GET_ORGANIZATIONS_REQUESTED = "GET_ORGANIZATIONS_REQUESTED";
export const GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS";
export const GET_ORGANIZATIONS_FAILED = "GET_ORGANIZATIONS_FAILED";
export const GET_USERS_BY_ORGANIZATION_REQUESTED =
  "GET_USERS_BY_ORGANIZATION_REQUESTED";
export const GET_USERS_BY_ORGANIZATION_SUCCESS =
  "GET_USERS_BY_ORGANIZATION_SUCCESS";
export const GET_USERS_BY_ORGANIZATION_FAILED =
  "GET_USERS_BY_ORGANIZATION_FAILED";
export const GET_ROLE_REQUESTED = "GET_ROLE_REQUESTED";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILED = "GET_ROLE_FAILED";

export const CREATE_BOOKING_BY_TREATMENT_REQUESTED =
  "CREATE_BOOKING_BY_TREATMENT_REQUESTED";
export const CREATE_BOOKING_BY_TREATMENT_SUCCESS =
  "CREATE_BOOKING_BY_TREATMENT_SUCCESS";
export const CREATE_BOOKING_BY_TREATMENT_FAILED =
  "CREATE_BOOKING_BY_TREATMENT_FAILED";
export const UPDATE_TREATMENT_REQUESTED = "UPDATE_TREATMENT_REQUESTED";
export const UPDATE_TREATMENT_SUCCESS = "UPDATE_TREATMENT_SUCCESS";
export const UPDATE_TREATMENT_FAILED = "UPDATE_TREATMENT_FAILED";
export const GET_BOOKINGS_BY_TREATMENT_SUCCESS =
  "GET_BOOKINGS_BY_TREATMENT_SUCCESS";
export const GET_BOOKINGS_BY_TREATMENT_FAILED =
  "GET_BOOKINGS_BY_TREATMENT_FAILED";
export const GET_BOOKINGS_BY_TREATMENT_REQUESTED =
  "GET_BOOKINGS_BY_TREATMENT_REQUESTED";

export const GET_MONITORING_REPORT_REQUESTED =
  "GET_MONITORING_REPORT_REQUESTED";
export const GET_MONITORING_REPORT_SUCCESS = "GET_MONITORING_REPORT_SUCCESS";
export const GET_MONITORING_REPORT_FAILED = "GET_MONITORING_REPORT_FAILED";

export const SEND_NOTIFICATION_REQUESTED = "SEND_NOTIFICATION_REQUESTED";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const GET_MEDICATION_REQUESTED = "GET_MEDICATION_REQUESTED";
export const GET_MEDICATION_SUCCESS = "GET_MEDICATION_SUCCESS";
export const GET_MEDICATION_FAILED = "GET_MEDICATION_FAILED";
export const GET_MEDICATION_KEYWORD_REQUESTED =
  "GET_MEDICATION_KEYWORD_REQUESTED";
export const GET_MEDICATION_KEYWORD_SUCCESS = "GET_MEDICATION_KEYWORD_SUCCESS";
export const GET_MEDICATION_KEYWORD_FAILED = "GET_MEDICATION_KEYWORD_FAILED";

export const GET_PRACTITIONER_SPECIALTIES_REQUESTED =
  "GET_PRACTITIONER_SPECIALTIES_REQUESTED";
export const GET_PRACTITIONER_SPECIALTIES_SUCCESS =
  "GET_PRACTITIONER_SPECIALTIES_SUCCESS";
export const GET_PRACTITIONER_SPECIALTIES_FAILED =
  "GET_PRACTITIONER_SPECIALTIES_FAILED  ";
export const GET_TIME_SLOTS_BY_PRACTITIONER_ID_REQUESTED =
  "GET_TIME_SLOTS_BY_PRACTITIONER_ID_REQUESTED";
export const GET_TIME_SLOTS_BY_PRACTITIONER_ID_SUCCESS =
  "GET_TIME_SLOTS_BY_PRACTITIONER_ID_SUCCESS";
export const GET_TIME_SLOTS_BY_PRACTITIONER_ID_FAILED =
  "GET_TIME_SLOTS_BY_PRACTITIONER_ID_FAILED";

export const GET_MEDICATION_BOOKING_REQUESTED =
  "GET_MEDICATION_BOOKING_REQUESTED";
export const GET_MEDICATION_BOOKING_SUCCESS = "GET_MEDICATION_BOOKING_SUCCESS";
export const GET_MEDICATION_BOOKING_FAILED = "GET_MEDICATION_BOOKING_FAILED";
export const UPDATE_MEDICATION_ITEM_REQUESTED =
  "UPDATE_MEDICATION_ITEM_REQUESTED";
export const UPDATE_MEDICATION_ITEM_SUCCESS = "UPDATE_MEDICATION_ITEM_SUCCESS";
export const UPDATE_MEDICATION_ITEM_FAILED = "UPDATE_MEDICATION_ITEM_FAILED";
export const DELETE_MEDICATION_ITEM_REQUESTED =
  "DELETE_MEDICATION_ITEM_REQUESTED";
export const DELETE_MEDICATION_ITEM_SUCCESS = "DELETE_MEDICATION_ITEM_SUCCESS";
export const DELETE_MEDICATION_ITEM_FAILED = "DELETE_MEDICATION_ITEM_FAILED";
export const DELETE_MEDICATION_BOOKING_REQUESTED =
  "DELETE_MEDICATION_BOOKING_REQUESTED";
export const DELETE_MEDICATION_BOOKING_SUCCESS =
  "DELETE_MEDICATION_BOOKING_SUCCESS";
export const DELETE_MEDICATION_BOOKING_FAILED =
  "DELETE_MEDICATION_BOOKING_FAILED";

export const GET_DRUG_TIME_EVENT_REQUESTED = "GET_DRUG_TIME_EVENT_REQUESTED";
export const GET_DRUG_TIME_EVENT_SUCCESS = "GET_DRUG_TIME_EVENT_SUCCESS";
export const GET_DRUG_TIME_EVENT_FAILED = "GET_DRUG_TIME_EVENT_FAILED";

export const GET_DRUG_TIME_FREQUENCY_REQUESTED =
  "GET_DRUG_TIME_FREQUENCY_REQUESTED";
export const GET_DRUG_TIME_FREQUENCY_SUCCESS =
  "GET_DRUG_TIME_FREQUENCY_SUCCESS";
export const GET_DRUG_TIME_FREQUENCY_FAILED = "GET_DRUG_TIME_FREQUENCY_FAILED";

export const GET_ORGANIZATIONS_SUBMISION_LIST_REQUESTED =
  "GET_ORGANIZATIONS_SUBMISION_LIST_REQUESTED";
export const GET_ORGANIZATIONS_SUBMISION_LIST_SUCCESS =
  "GET_ORGANIZATIONS_SUBMISION_LIST_SUCCESS";
export const GET_ORGANIZATIONS_SUBMISION_LIST_FAILED =
  "GET_ORGANIZATIONS_SUBMISION_LIST_FAILED";

export const UPDATE_ORGANIZATIONS_SUBMISION_LIST_REQUESTED =
  "UPDATE_ORGANIZATIONS_SUBMISION_LIST_REQUESTED";
export const UPDATE_ORGANIZATIONS_SUBMISION_LIST_SUCCESS =
  "UPDATE_ORGANIZATIONS_SUBMISION_LIST_SUCCESS";
export const UPDATE_ORGANIZATIONS_SUBMISION_LIST_FAILED =
  "UPDATE_ORGANIZATIONS_SUBMISION_LIST_FAILED";

export const GET_SYMPTOM_GROUP_REQUESTED = "GET_SYMPTOM_GROUP_REQUESTED";
export const GET_SYMPTOM_GROUP_SUCCESS = "GET_SYMPTOM_GROUP_SUCCESS";
export const GET_SYMPTOM_GROUP_FAILED = "GET_SYMPTOM_GROUP_FAILED";
export const REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_REQUESTED =
  "REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_REQUESTED";
export const REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_SUCCESS =
  "REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_SUCCESS";
export const REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_FAILED =
  "REMOVE_MEDICATION_BY_SYMPTOM_GROUP_ID_FAILED";
export const GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_SUCCESS =
  "GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_SUCCESS";
export const GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_FAILED =
  "GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_FAILED";
export const GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_REQUESTED =
  "GET_SYMPTOM_GROUP_BY_MEDICATION_FOR_ORG_REQUESTED";

export const GET_DRUG_INGREDIENT_REQUESTED = "GET_DRUG_INGREDIENT_REQUESTED";
export const GET_DRUG_INGREDIENT_SUCCESS = "GET_DRUG_INGREDIENT_SUCCESS";
export const GET_DRUG_INGREDIENT_FAILED = "GET_DRUG_INGREDIENT_SUCCESS";

export const UPDATE_QUESTIONAIRE_BY_ID_REQUESTED =
  "UPDATE_QUESTIONAIRE_BY_ID_REQUESTED";
export const UPDATE_QUESTIONAIRE_BY_ID_SUCCESS =
  "UPDATE_QUESTIONAIRE_BY_ID_SUCCESS";
export const UPDATE_QUESTIONAIRE_BY_ID_FAILED =
  "UPDATE_QUESTIONAIRE_BY_ID_FAILED";
export const GET_ALL_NEW_CASE_QUESTIONAIRE_REQUESTED =
  "GET_ALL_NEW_CASE_QUESTIONAIRE_REQUESTED";
export const GET_ALL_NEW_CASE_QUESTIONAIRE_SUCCESS =
  "GET_ALL_NEW_CASE_QUESTIONAIRE_SUCCESS";
export const GET_ALL_NEW_CASE_QUESTIONAIRE_FAILED =
  "GET_ALL_NEW_CASE_QUESTIONAIRE_FAILED";
export const GET_ALL_REGISTERED_QUESTIONAIRE_REQUESTED =
  "GET_ALL_REGISTERED_QUESTIONAIRE_REQUESTED";
export const GET_ALL_REGISTERED_QUESTIONAIRE_SUCCESS =
  "GET_ALL_REGISTERED_QUESTIONAIRE_SUCCESS";
export const GET_ALL_REGISTERED_QUESTIONAIRE_FAILED =
  "GET_ALL_REGISTERED_QUESTIONAIRE_FAILED";

export const GET_MEDICATION_FOR_ORGANIZATION_REQUESTED =
  "GET_MEDICATION_FOR_ORGANIZATION_REQUESTED";
export const GET_MEDICATION_FOR_ORGANIZATION_SUCCESS =
  "GET_MEDICATION_FOR_ORGANIZATION_SUCCESS";
export const GET_MEDICATION_FOR_ORGANIZATION_FAILED =
  "GET_MEDICATION_FOR_ORGANIZATION_FAILED";

export const GET_RESEARCH_OBJECTS_REQUESTED = "GET_RESEARCH_OBJECTS_REQUESTED";
export const GET_RESEARCH_OBJECTS_SUCCESS = "GET_RESEARCH_OBJECTS_SUCCESS";
export const GET_RESEARCH_OBJECTS_FAILED = "GET_RESEARCH_OBJECTS_FAILED";
export const GET_RESEARCH_PROGRAM_BY_ORG_REQUEST =
  "GET_RESEARCH_PROGRAM_BY_ORG_REQUEST";
export const GET_RESEARCH_PROGRAM_BY_ORG_SUCCESS =
  "GET_RESEARCH_PROGRAM_BY_ORG_SUCCESS";
export const GET_RESEARCH_PROGRAM_BY_ORG_FAILED =
  "GET_RESEARCH_PROGRAM_BY_ORG_FAILED";
export const GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST =
  "GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST";
export const GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS =
  "GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS";
export const GET_RECRUITMENT_OBJECTS_BY_ORG_AND_PROGRAM_FAILED =
  "GET_RECRUITMENT_OBJECTS_BY_ORG_FAILED";
export const GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST =
  "GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_REQUEST";
export const GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS =
  "GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_SUCCESS";
export const GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_FAILED =
  "GET_RESEARCH_OBJECTS_BY_ORG_AND_PROGRAM_FAILED";
export const CREATE_RESEARCH_OBJECT_FORM_RECRUITMENT_OBJECT_REQUEST =
  "CREATE_RESEARCH_OBJECT_FORM_RECRUITMENT_OBJECT_REQUEST";
export const CREATE_RESEARCH_OBJECT_FORM_RECRUITMENT_OBJECT_SUCCESS =
  "CREATE_RESEARCH_OBJECT_FORM_RECRUITMENT_OBJECT_SUCCESS";
export const CREATE_RESEARCH_OBJECT_FORM_RECRUITMENT_OBJECT_FAILED =
  "CREATE_RESEARCH_OBJECT_FORM_RECRUITMENT_OBJECT_FAILED";
export const CREATE_CLINIC_TRIAL_CONSENT_FORM_REQUEST =
  "CREATE_CLINIC_TRIAL_CONSENT_FORM_REQUEST";
export const CREATE_CLINIC_TRIAL_CONSENT_FORM_SUCCESS =
  "CREATE_CLINIC_TRIAL_CONSENT_FORM_SUCCESS";
export const CREATE_CLINIC_TRIAL_CONSENT_FORM_FAILED =
  "CREATE_CLINIC_TRIAL_CONSENT_FORM_FAILED";
export const CREATE_CLINIC_TRIAL_GENERAL_FORM_REQUEST =
  "CREATE_CLINIC_TRIAL_GENERAL_FORM_REQUEST";
export const CREATE_CLINIC_TRIAL_GENERAL_FORM_SUCCESS =
  "CREATE_CLINIC_TRIAL_GENERAL_FORM_SUCCESS";
export const CREATE_CLINIC_TRIAL_GENERAL_FORM_FAILED =
  "CREATE_CLINIC_TRIAL_GENERAL_FORM_FAILED";
export const CREATE_CLINIC_TRIAL_CONTACT_FORM_REQUEST =
  "CREATE_CLINIC_TRIAL_CONTACT_FORM_REQUEST";
export const CREATE_CLINIC_TRIAL_CONTACT_FORM_SUCCESS =
  "CREATE_CLINIC_TRIAL_CONTACT_FORM_SUCCESS";
export const CREATE_CLINIC_TRIAL_CONTACT_FORM_FAILED =
  "CREATE_CLINIC_TRIAL_CONTACT_FORM_FAILED";
export const CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST =
  "CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST";
export const CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS =
  "CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS";
export const CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED =
  "CREATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED";
export const UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST =
  "UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST";
export const UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS =
  "UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS";
export const UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED =
  "UPDATE_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED";
export const EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST =
  "EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_REQUEST";
export const EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS =
  "EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_SUCCESS";
export const EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED =
  "EDIT_CLINIC_TRIAL_ELIGIBILITY_FORM_FAILED";
export const GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_REQUEST =
  "GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_REQUEST";
export const GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_SUCCESS =
  "GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_SUCCESS";
export const GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_FAILED =
  "GET_CLINIC_ASSESSMENT_OBJECT_BY_ID_FAILED";
export const ADD_NEW_CLINIC_ASSESSMENT_BY_ID_REQUEST =
  "ADD_NEW_CLINIC_ASSESSMENT_BY_ID_REQUEST";
export const ADD_NEW_CLINIC_ASSESSMENT_BY_ID_SUCCESS =
  "ADD_NEW_CLINIC_ASSESSMENT_BY_ID_SUCCESS";
export const ADD_NEW_CLINIC_ASSESSMENT_BY_ID_FAILED =
  "ADD_NEW_CLINIC_ASSESSMENT_BY_ID_FAILED";
export const UPDATE_RECRUITMENT_OBJECT_REQUEST =
  "UPDATE_RECRUITMENT_OBJECT_REQUEST";
export const UPDATE_RECRUITMENT_OBJECT_SUCCESS =
  "UPDATE_RECRUITMENT_OBJECT_SUCCESS";
export const UPDATE_RECRUITMENT_OBJECT_FAILED =
  "UPDATE_RECRUITMENT_OBJECT_FAILED";

export const APPROVE_CLINIC_TRIAL_REQUEST = "APPROVE_CLINIC_TRIAL_REQUEST";
export const APPROVE_CLINIC_TRIAL_SUCCESS = "APPROVE_CLINIC_TRIAL_SUCCESS";
export const APPROVE_CLINIC_TRIAL_FAILED = "APPROVE_CLINIC_TRIAL_FAILED";

export const APPROVE_CLINIC_TRIAL_MANUALLY_REQUEST =
  "APPROVE_CLINIC_TRIAL_MANUALLY_REQUEST";
export const APPROVE_CLINIC_TRIAL_MANUALLY_SUCCESS =
  "APPROVE_CLINIC_TRIAL_MANUALLY_SUCCESS";
export const APPROVE_CLINIC_TRIAL_MANUALLY_FAILED =
  "APPROVE_CLINIC_TRIAL_MANUALLY_FAILED";

export const DECLINE_CLINIC_TRIAL_REQUEST = "DECLINE_CLINIC_TRIAL_REQUEST";
export const DECLINE_CLINIC_TRIAL_SUCCESS = "DECLINE_CLINIC_TRIAL_SUCCESS";
export const DECLINE_CLINIC_TRIAL_FAILED = "DECLINE_CLINIC_TRIAL_FAILED";

export const CREATE_CLINIC_TRIAL_DEVIATIONS_REQUEST =
  "CREATE_CLINIC_TRIAL_DEVIATIONS_REQUEST";
export const CREATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS =
  "CREATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS";
export const CREATE_CLINIC_TRIAL_DEVIATIONS_FAILED =
  "CREATE_CLINIC_TRIAL_DEVIATIONS_FAILED";
export const UPDATE_CLINIC_TRIAL_DEVIATIONS_REQUEST =
  "UPDATE_CLINIC_TRIAL_DEVIATIONS_REQUEST";
export const UPDATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS =
  "UPDATE_CLINIC_TRIAL_DEVIATIONS_SUCCESS";
export const UPDATE_CLINIC_TRIAL_DEVIATIONS_FAILED =
  "UPDATE_CLINIC_TRIAL_DEVIATIONS_FAILED";

export const GET_CLINIC_TRIAL_DEVIATIONS_REQUEST =
  "GET_CLINIC_TRIAL_DEVIATIONS_REQUEST";
export const GET_CLINIC_TRIAL_DEVIATIONS_SUCCESS =
  "GET_CLINIC_TRIAL_DEVIATIONS_SUCCESS";
export const GET_CLINIC_TRIAL_DEVIATIONS_FAILED =
  "GET_CLINIC_TRIAL_DEVIATIONS_FAILED";

export const DELETE_DEVIATIONS_BY_ID_REQUEST =
  "DELETE_DEVIATIONS_BY_ID_REQUEST";
export const DELETE_DEVIATIONS_BY_ID_SUCCESS =
  "DELETE_DEVIATIONS_BY_ID_SUCCESS";
export const DELETE_DEVIATIONS_BY_ID_FAILED = "DELETE_DEVIATIONS_BY_ID_FAILED";

export const WITHDRAWN_CLINIC_TRIAL_REQUEST = "WITHDRAWN_CLINIC_TRIAL_REQUEST";
export const WITHDRAWN_CLINIC_TRIAL_SUCCESS = "WITHDRAWN_CLINIC_TRIAL_SUCCESS";
export const WITHDRAWN_CLINIC_TRIAL_FAILED = "WITHDRAWN_CLINIC_TRIAL_FAILED";

export const GET_RESEARCH_OBJECT_BY_ID_REQUESTED =
  "GET_RESEARCH_OBJECT_BY_ID_REQUESTED";
export const GET_RESEARCH_OBJECT_BY_ID_SUCCESS =
  "GET_RESEARCH_OBJECT_BY_ID_SUCCESS";
export const GET_RESEARCH_OBJECT_BY_ID_FAILED =
  "GET_RESEARCH_OBJECT_BY_ID_FAILED";

export const GET_RECRUITMENT_OBJECT_BY_ID_REQUESTED =
  "GET_RECRUITMENT_OBJECT_BY_ID_REQUESTED";
export const GET_RECRUITMENT_OBJECT_BY_ID_SUCCESS =
  "GET_RECRUITMENT_OBJECT_BY_ID_SUCCESS";
export const GET_RECRUITMENT_OBJECT_BY_ID_FAILED =
  "GET_RECRUITMENT_OBJECT_BY_ID_FAILED";

export const GET_DRUG_UNITS_REQUESTED = "GET_DRUG_UNITS_REQUESTED";
export const GET_DRUG_UNITS_SUCCESS = "GET_DRUG_UNITS_SUCCESS";
export const GET_DRUG_UNITS_FAILED = "GET_DRUG_UNITS_FAILED";

export const CONFIRM_PAYMENT_REQUESTED = "CONFIRM_PAYMENT_REQUESTED";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAILED = "CONFIRM_PAYMENT_FAILED";
export const REJECT_PAYMENT_REQUESTED = "REJECT_PAYMENT_REQUESTED";
export const REJECT_PAYMENT_SUCCESS = "REJECT_PAYMENT_SUCCESS";
export const REJECT_PAYMENT_FAILED = "REJECT_PAYMENT_FAILED";

export const GET_ALL_PRACTITIONER_SPECIALTIES_REQUESTED =
  "GET_ALL_PRACTITIONER_SPECIALTIES_REQUESTED";
export const GET_ALL_PRACTITIONER_SPECIALTIES_SUCCESS =
  "GET_ALL_PRACTITIONER_SPECIALTIES_SUCCESS";
export const GET_ALL_PRACTITIONER_SPECIALTIES_FAILED =
  "GET_ALL_PRACTITIONER_SPECIALTIES_FAILED";

export const GET_PRACTITIONERS_BY_SPECIALTIES_REQUESTED =
  "GET_PRACTITIONERS_BY_SPECIALTIES_REQUESTED";
export const GET_PRACTITIONERS_BY_SPECIALTIES_SUCCESS =
  "GET_PRACTITIONERS_BY_SPECIALTIES_SUCCESS";
export const GET_PRACTITIONERS_BY_SPECIALTIES_FAILED =
  "GET_PRACTITIONERS_BY_SPECIALTIES_FAILED";

export const GET_CLINIC_BY_SPECIALTIES_REQUESTED =
  "GET_CLINIC_BY_SPECIALTIES_REQUESTED";
export const GET_CLINIC_BY_SPECIALTIES_SUCCESS =
  "GET_CLINIC_BY_SPECIALTIES_SUCCESS";
export const GET_CLINIC_BY_SPECIALTIES_FAILED =
  "GET_CLINIC_BY_SPECIALTIES_FAILED";

export const GET_CLINIC_PACKAGE_BY_SPECIALTIES_REQUESTED =
  "GET_CLINIC_PACKAGE_BY_SPECIALTIES_REQUESTED";
export const GET_CLINIC_PACKAGE_BY_SPECIALTIES_SUCCESS =
  "GET_CLINIC_PACKAGE_BY_SPECIALTIES_SUCCESS";
export const GET_CLINIC_PACKAGE_BY_SPECIALTIES_FAILED =
  "GET_CLINIC_PACKAGE_BY_SPECIALTIES_FAILED";

export const CREATE_DOCTOR_REFERRAL_REQUESTED =
  "CREATE_DOCTOR_REFERRAL_REQUESTED";
export const CREATE_DOCTOR_REFERRAL_SUCCESS = "CREATE_DOCTOR_REFERRAL_SUCCESS";
export const CREATE_DOCTOR_REFERRAL_FAILED = "CREATE_DOCTOR_REFERRAL_FAILED";
export const DELETE_DOCTOR_REFERRAL_REQUESTED =
  "DELETE_DOCTOR_REFERRAL_REQUESTED";
export const DELETE_DOCTOR_REFERRAL_SUCCESS = "DELETE_DOCTOR_REFERRAL_SUCCESS";
export const DELETE_DOCTOR_REFERRAL_FAILED = "DELETE_DOCTOR_REFERRAL_FAILED";
export const UPDATE_DOCTOR_REFERRAL_REQUESTED =
  "UPDATE_DOCTOR_REFERRAL_REQUESTED";
export const UPDATE_DOCTOR_REFERRAL_SUCCESS = "UPDATE_DOCTOR_REFERRAL_SUCCESS";
export const UPDATE_DOCTOR_REFERRAL_FAILED = "UPDATE_DOCTOR_REFERRAL_FAILED";

export const GET_REFERRALS_BY_BOOKING_ID_REQUESTED =
  "GET_REFERRALS_BY_BOOKING_ID_REQUESTED";
export const GET_REFERRALS_BY_BOOKING_ID_SUCCESS =
  "GET_REFERRALS_BY_BOOKING_ID_SUCCESS";
export const GET_REFERRALS_BY_BOOKING_ID_FAILED =
  "GET_REFERRALS_BY_BOOKING_ID_FAILED";

export const CONFIRM_FIREBASE_PAYMENT_REQUESTED =
  "CONFIRM_FIREBASE_PAYMENT_REQUESTED";
export const CONFIRM_FIREBASE_PAYMENT_SUCCESS =
  "CONFIRM_FIREBASE_PAYMENT_SUCCESS";
export const CONFIRM_FIREBASE_PAYMENT_FAILED =
  "CONFIRM_FIREBASE_PAYMENT_FAILED";
