import moment from "moment";

export const convertDateTimeToMinutes = (dateTime) => {
  return (
    moment(dateTime).days() * 1440 +
    moment(dateTime).hours() * 60 +
    moment(dateTime).minutes()
  );
};

export const convertDateToRangeOfMinutes = (dateTime) => {
  const day = moment(dateTime).days() === 0 ? 6 : moment(dateTime).days() - 1;
  return [day * 1440, (day + 1) * 1439];
};

export const convertMinuteToPeriod = (minute) => {
  if (minute >= 300 && minute <= 719) {
    return "morning";
  } else if (minute >= 720 && minute <= 1079) {
    return "afternoon";
  } else if (minute >= 1080 && minute <= 1259) {
    return "evening";
  } else if (
    (minute >= 1260 && minute <= 1439) ||
    (minute >= 0 && minute <= 299)
  ) {
    return "night";
  }
  return "";
};
