import { BookOutlined } from "@ant-design/icons";
import { Button, Row, Modal, Tabs, Select, Calendar, Col } from "antd";
import React, { useState } from "react";
import {
  Container,
  HeaderWrapper,
  Text,
  TextCenter,
  DivCalendar,
  FlexCalendar,
} from "./styles";
import { useSelector } from "react-redux";
import Table from "./table";
import SearchBox from "../SearchBox";
import { TABS } from "./constant";
const { Option } = Select;
import moment from "moment";
import Loading from "../Loading";

const { TabPane } = Tabs;

const BookingTable = ({
  loading,
  history,
  onSearch,
  handleTableChange,
  setTab,
  searchBy,
  searchText,
  setSearchBy,
  setSearchText,
  t,
  visibleModalAppointment,
  setVisibleModalAppointment,
  currentDateTimeAppointment,
  setCurrentBooking,
  setCurrentDateTimeAppointment,
  periods,
  times,
  isInitialAppointmentLoading,
  handleChangeNewAppointment,
  handleConfirmNewAppointment,
  visibleModalConfirmAppointment,
  setVisibleModalConfirmAppointment,
  selectedDate,
  setSelectedDate,
  newDateTimeAppointment,
  setNewDateTimeAppointment,
}) => {
  const user = useSelector((state) => state.appUserOma);
  const booking = useSelector((state) => state.booking);
  const [visible, setVisible] = useState(false);
  const role = user.information.roles[0].name;
  return (
    <Container>
      <HeaderWrapper>
        <Row align="middle">
          <BookOutlined />
          <Text bold style={{ paddingTop: 10 }}>
            {t("booking")}
          </Text>
        </Row>
        <SearchBox
          setSearchBy={setSearchBy}
          setSearchText={setSearchText}
          onSearch={onSearch}
          t={t}
        />
      </HeaderWrapper>
      <Tabs
        defaultActiveKey="1"
        onChange={(tabNumber) => {
          setTab(TABS[tabNumber - 1]);
        }}
      >
        {role === "deliveryTeam" ? (
          <Table
            t={t}
            loading={loading}
            history={history}
            setVisibleHistory={(condition) => setVisible(condition)}
            isActive={true}
            isHistoryBooking={false}
            title="นัดหมายทั้งหมด"
            handleOpenModalAppointment={(booking) => {
              setCurrentBooking(booking);
              setVisibleModalAppointment(true);
              setCurrentDateTimeAppointment(booking.admitTime);
              setSelectedDate(moment(booking.admitTime));
            }}
          />
        ) : (
          TABS.map((tab, index) => (
            <TabPane tab={t(`${tab.title}`)} key={index + 1}>
              <Table
                t={t}
                loading={loading}
                history={history}
                setVisibleHistory={(condition) => setVisible(condition)}
                isActive={true}
                isHistoryBooking={false}
                filterBy={tab.filter}
                title={tab.title}
                dataSource={booking.data}
                handleTableChange={handleTableChange}
                handleOpenModalAppointment={(booking) => {
                  setCurrentBooking(booking);
                  setVisibleModalAppointment(true);
                  setCurrentDateTimeAppointment(booking.admitTime);
                  setSelectedDate(moment(booking.admitTime));
                }}
              />
            </TabPane>
          ))
        )}
      </Tabs>
      <Modal
        width={1200}
        title="ประวัตินัดหมาย"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <Table
          t={t}
          loading={booking.history.loading}
          dataSource={booking.history.data}
          history={history}
          isHistoryBooking={true}
          isActive={false}
          handleOpenModalAppointment={(booking) => {
            setCurrentBooking(booking);
            setVisibleModalAppointment(true);
            setCurrentDateTimeAppointment(booking.admitTime);
            setSelectedDate(moment(booking.admitTime));
          }}
        />
      </Modal>
      <Modal
        width={1000}
        title="DATE_TIME"
        visible={visibleModalAppointment}
        footer={null}
        onOk={() => {
          setSelectedDate("");
          setVisibleModalAppointment(false);
        }}
        onCancel={() => {
          setSelectedDate("");
          setVisibleModalAppointment(false);
        }}
      >
        <Row>
          <FlexCalendar span={12} align="middle">
            <DivCalendar>
              <Calendar
                defaultValue={currentDateTimeAppointment}
                value={selectedDate}
                fullscreen={false}
                onSelect={setSelectedDate}
                onPanelChange={setSelectedDate}
                disabledDate={(date) => date < moment().startOf("day")}
              />
            </DivCalendar>
          </FlexCalendar>
          <Col span={12} align="middle">
            {isInitialAppointmentLoading ? (
              <Tabs defaultActiveKey="1">
                {periods?.length > 0 
                  ? periods.map((period, index) => (
                      <Tabs.TabPane tab={t(period)} key={index + 1} type="card">
                        <Row gutter={[24, 12]}>
                          {times.map((time) => {
                            if (time.period === period) {
                              return (
                                <Col>
                                  <Button
                                    onClick={() =>
                                      handleChangeNewAppointment(time)
                                    }
                                  >
                                    {time.hours}
                                  </Button>
                                </Col>
                              );
                            }
                            return null;
                          })}
                        </Row>
                      </Tabs.TabPane>
                    ))
                  : null}
              </Tabs>
            ) : (
              <Loading height="100%" />
            )}
          </Col>
        </Row>
      </Modal>
      <Modal
        width={550}
        title={t("confirm_new_appointment")}
        visible={visibleModalConfirmAppointment}
        onOk={() => {
          handleConfirmNewAppointment();
          setVisibleModalConfirmAppointment(false);
        }}
        onCancel={() => {
          setVisibleModalConfirmAppointment(false);
          setNewDateTimeAppointment("");
        }}
      >
        <TextCenter>
          {t(
            "are_you_sure_you_want_to_change_the_date_and_time_of_your_appointment_from"
          )}
        </TextCenter>
        <TextCenter color="red">
          {moment(currentDateTimeAppointment).format("dddd D MMMM YYYY HH:mm")}
        </TextCenter>
        <TextCenter>{t("to")}</TextCenter>
        <TextCenter color="green">{newDateTimeAppointment}</TextCenter>
      </Modal>
    </Container>
  );
};
export default BookingTable;
