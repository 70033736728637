import React from "react";
import moment from "moment";
import {
  ContainerOutlined,
  MessageOutlined,
  PhoneOutlined,
  AreaChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Input,
  Radio,
  Button,
  Row,
  Col,
  Popconfirm,
  Popover,
  Image,
} from "antd";
import { COLORS } from "./constant";
import {
  CircleButtonAlert,
  CircleIcon,
  ContentRelative,
  ConversationContainer,
  ConversationContent,
  FooterContent,
  LayoutContainer,
  LayoutContent,
  OwnParticipant,
  SiderLayoutLeft,
  SiderLayoutRight,
  SiderTelemonitoringLayout,
} from "./styles";
import GeneralInfo from "../PatientTeleDetail/GeneralInfo";
import MonitoringInfo from "../PatientTeleDetail/MonitoringInfo";
import HistoryBookingModal from "../Modal/HistoryBookingModal/HistoryBooking";
import CovidModal from "../Modal/CovidModal/Covid";
import PrescriptionListModal from "../Modal/PrescriptionListModal/PrescriptionList";
import PrescriptionTableModal from "../Modal/PrescriptionTableModal/PrescriptionTable";
import AudioButton from "../Button/AudioButton";
import VideoButton from "../Button/VideoButton";
import Chat from "../Chat";
import Participant from "../Participant";
const { TextArea } = Input;

const Room = (props) => {
  const {
    t,
    practitioner,
    handleCloseSider,
    note,
    setNote,
    organization,
    color,
    setColor,
    room,
    participants,
    booking,
    treatment,
    isChat,
    isMute,
    role,
    roomType,
    visiblePrescriptionList,
    setVisiblePrescriptionList,
    isDisableVideo,
    visibleCovidModal,
    visibleHistoryBookingModal,
    setVisibleCovidModal,
    setVisibleHistoryBookingModal,
    handleAudioButton,
    handleVideoButton,
    handleVisibleCovidModal,
    handleVisibleHistoryBookingModal,
    handleDisconnect,
    collapsedNote,
    collapsedChat,
    collapsedTelemonitoring,
    collapsedInformation,
    handleVisibleNote,
    handleVisibleChat,
    handleVisibleTelemonitoring,
    handleVisibleInformation,
    handleVisiblePrescription,
    visiblePrescriptionModal,
    getDrugTimeEventInfo,
    getDrugTimeFrequencyInfo,
    getDrugRouteInfo,
    getDrugUnitInfo,
    deleteDrugFromPrescription,
    handleSelectMedicine,
    setSearch,
    medication,
    updatePrescriptionItem,
    medicines,
    setMedicines,
    drugUnits,
    drugTimeEvent,
    drugTimeFrequency,
    prescription,
  } = props;
  return (
    <LayoutContainer>
      <LayoutContent className="site-layout">
        <SiderLayoutLeft
          collapsible
          collapsed={collapsedInformation}
          onCollapse={handleVisibleInformation}
        >
          <div>
            {t("full_name")}:{" "}
            {booking.current.patient.userInformation &&
              `${booking.current.patient.userInformation.firstname} ${booking.current.patient.userInformation.lastname}`}
          </div>
          {t("symptom")}
          {booking.current.symptom && (
            <>
              <div>{booking.current.symptom.note}</div>
              {booking.current.symptom.imageUrl && (
                <Image src={booking.current.symptom.imageUrl} height="200px" />
              )}
            </>
          )}
          {booking.current.bookingCategory !== "scheduling" &&
            organization.location !== "CCS" && (
              <div style={{ marginTop: 20 }}>
                <Button onClick={() => setVisiblePrescriptionList(true)}>
                  {t("prescription")}
                </Button>
              </div>
            )}
        </SiderLayoutLeft>
        <ContentRelative>
          <ConversationContainer>
            <ConversationContent>
              {participants.map((participant) => {
                const info = booking.current.patient.userInformation ?? {
                  firstname: "",
                  lastname: "",
                };
                const name = `${info.firstname} ${info.lastname}`;
                return (
                  <Participant
                    className="patient-call"
                    key={participant.sid}
                    participant={participant}
                    name={name}
                  />
                );
              })}
              <OwnParticipant>
                <Participant
                  className="self-call"
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                  name={t("you")}
                  videoWidth={234}
                  videoHeight={132}
                  isLocal
                  isObjectFit
                />
              </OwnParticipant>
            </ConversationContent>
          </ConversationContainer>
        </ContentRelative>
        <SiderLayoutRight
          collapsible
          collapsed={collapsedNote}
          onCollapse={handleCloseSider}
        >
          <>
            <h2>{t("note")}</h2>
            <TextArea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows={10}
            />
          </>
          {roomType === "COVID" && !collapsedNote && (
            <div style={{ marginTop: 20 }}>
              <Radio.Group
                options={COLORS}
                onChange={(e) => setColor(e.target.value)}
                value={color}
              />
              <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={handleVisibleCovidModal}>
                  {t("data_from_form_covid_19")}
                </Button>
              </div>
              <div style={{ marginTop: 20 }}>
                <Button
                  type="primary"
                  onClick={handleVisibleHistoryBookingModal}
                >
                  {t("history_booking")}
                </Button>
              </div>
            </div>
          )}
        </SiderLayoutRight>
        <SiderLayoutRight
          collapsible
          collapsed={collapsedChat}
          onCollapse={handleCloseSider}
        >
          <>
            <h2>{t("chat")}</h2>
            {isChat && <Chat userId={practitioner.id} />}
          </>
        </SiderLayoutRight>
        <SiderTelemonitoringLayout
          collapsible
          collapsed={collapsedTelemonitoring}
          onCollapse={handleCloseSider}
        >
          <>
            <h2 className="mb-2">{t("telemonitoring")}</h2>
            <GeneralInfo
              userInfo={booking.current.patient.userInformation}
              isVideoCall
            ></GeneralInfo>
            <MonitoringInfo
              appUserId={booking.current.patientId}
              isVideoCall
            ></MonitoringInfo>
          </>
        </SiderTelemonitoringLayout>
      </LayoutContent>
      <FooterContent>
        <Col style={{ marginLeft: "12px" }}>
          <h2 style={{ color: "white" }}>{moment().format("LT")}</h2>
        </Col>
        <Col>
          <Row type="flex" justify="center" gutter={24}>
            <Col>
              <AudioButton
                isMute={isMute}
                handleAudioButton={handleAudioButton}
              />
            </Col>
            <Col>
              <VideoButton
                isDisabled={isDisableVideo}
                handleVideoButton={handleVideoButton}
              />
            </Col>
            <Col>
              <Popconfirm
                title="Cotinue to the next step"
                onConfirm={handleDisconnect}
                okText="ใช่"
                cancelText="ไม่"
              >
                <CircleButtonAlert>
                  <PhoneOutlined />
                </CircleButtonAlert>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
        <Col style={{ marginRight: "18px" }}>
          <Row type="flex" justify="center" gutter={24}>
            <Col>
              <Popover content="Information">
                <CircleIcon onClick={handleVisibleInformation}>
                  <UserOutlined
                    style={{
                      color: collapsedInformation ? "#ffffff" : "#2ba275",
                    }}
                  />
                </CircleIcon>
              </Popover>
            </Col>
            <Col>
              <Popover content="Note">
                <CircleIcon onClick={handleVisibleNote}>
                  <ContainerOutlined
                    style={{ color: collapsedNote ? "#ffffff" : "#2ba275" }}
                  />
                </CircleIcon>
              </Popover>
            </Col>
            <Col>
              <Popover content="Chat">
                <CircleIcon onClick={handleVisibleChat}>
                  <MessageOutlined
                    style={{ color: collapsedChat ? "#ffffff" : "#2ba275" }}
                  />
                </CircleIcon>
              </Popover>
            </Col>
            <Col>
              <Popover content="Telemonitoring">
                <CircleIcon onClick={handleVisibleTelemonitoring}>
                  <AreaChartOutlined
                    style={{
                      color: collapsedTelemonitoring ? "#ffffff" : "#2ba275",
                    }}
                  />
                </CircleIcon>
              </Popover>
            </Col>
          </Row>
        </Col>
        <PrescriptionTableModal
          visible={visiblePrescriptionModal}
          setVisible={handleVisiblePrescription}
          booking={booking}
          t={t}
        />
        <CovidModal
          t={t}
          visible={visibleCovidModal}
          setVisible={setVisibleCovidModal}
          booking={booking}
        />
        <HistoryBookingModal
          visible={visibleHistoryBookingModal}
          setVisible={setVisibleHistoryBookingModal}
          treatment={treatment}
        />
        <PrescriptionListModal
          deleteDrugFromPrescription={deleteDrugFromPrescription}
          visible={visiblePrescriptionList}
          setVisible={setVisiblePrescriptionList}
          handleSelectMedicine={handleSelectMedicine}
          onSearch={setSearch}
          medication={medication}
          updatePrescriptionItem={updatePrescriptionItem}
          setMedicines={setMedicines}
          drugUnits={drugUnits}
          drugTimeEvent={drugTimeEvent}
          drugTimeFrequency={drugTimeFrequency}
          getDrugTimeEventInfo={getDrugTimeEventInfo}
          getDrugRouteInfo={getDrugRouteInfo}
          getDrugUnitInfo={getDrugUnitInfo}
          getDrugTimeFrequencyInfo={getDrugTimeFrequencyInfo}
          medicines={medicines}
          prescription={prescription}
          t={t}
        />
      </FooterContent>
    </LayoutContainer>
  );
};
export default Room;
